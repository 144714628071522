/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae landing page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import { Field, FieldGroup, Select, Text, TextArea, CrfCondition, ICrfConditionParameters, KeyboardDatePicker, Numeric, DatePicker } from '@ngt/opms';
import * as React from 'react';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import * as Dtos from '../../api/dtos';
import { MenuItem, makeStyles, Theme, Typography, List, Box } from '@material-ui/core';
import { alignRight } from '@servicestack/client';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IScreeningSummaryFieldGroupProps {
    screeningSummary?: Dtos.ScreeningSummary;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    disabled: {
        background: '#f2f2f2'
    },
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const ScreeningSummaryFieldGroup = ({
    screeningSummary
}: IScreeningSummaryFieldGroupProps) => {
    const classes = useStyles();

    return (
        <>

            <FieldGroup>
                <Field
                    name="summaryDate"
                    component={DatePicker}
                    sm={2}
                    md={4}
                    xl={4}
                    lg={4}
                    InputProps={{
                        inputProps: { maxLength: 4 }
                    }}
                    simpleLabel
                />
                <Field
                    name="endDate"
                    component={DatePicker}
                    sm={2}
                    md={2}
                    xl={4}
                    lg={4}
                    InputProps={{
                        inputProps: { maxLength: 4 }
                    }}
                    simpleLabel
                />
                <Field
                    name="preScreened"
                    component={Numeric}
                    sm={2}
                    md={2}
                    xl={2}
                    lg={2}
                    InputProps={{
                        inputProps: { maxLength: 4}
                    }}
                    simpleLabel
                />
                <br/>
                <Typography style={{ 'paddingLeft': '24px' }}><b>Reasons patients not consented (number per reason)</b></Typography>
                <Box style={{'paddingLeft': '35px'}}>
                <Field
                    name="performanceStatus"
                    component={Numeric}
                    sm={2}
                    md={2}
                    xl={2}
                    lg={2}
                    InputProps={{
                        inputProps: { maxLength: 4 }
                    }}
                    simpleLabel
                />
                <Field
                    name="contraindicationToTreatment"
                    component={Numeric}
                    sm={2}
                    md={2}
                    xl={2}
                    lg={2}
                    InputProps={{
                        inputProps: { maxLength: 4 }
                    }}
                    simpleLabel
                />
                <Field
                    name="otherTXStarted"
                    component={Numeric}
                    sm={2}
                    md={2}
                    xl={2}
                    lg={2}
                    InputProps={{
                        inputProps: { maxLength: 4 }
                    }}
                    simpleLabel
                />
                <Field
                    name="notExtensiveStageSCLC"
                    component={Numeric}
                    sm={2}
                    md={2}
                    xl={2}
                    lg={2}
                    InputProps={{
                        inputProps: { maxLength: 4 }
                    }}
                    simpleLabel
                />
                <Field
                    name="inadequateFunction"
                    component={Numeric}
                    sm={2}
                    md={2}
                    xl={2}
                    lg={2}
                    InputProps={{
                        inputProps: { maxLength: 4 }
                    }}
                    simpleLabel
                />
                <Field
                    name="otherEligibility"
                    component={Numeric}
                    sm={2}
                    md={2}
                    xl={2}
                    lg={2}
                    InputProps={{
                        inputProps: { maxLength: 4 }
                    }}
                    simpleLabel
                />
                <Field
                    name="incompatibleComorbidities"
                    component={Numeric}
                    sm={2}
                    md={2}
                    xl={2}
                    lg={2}
                    InputProps={{
                        inputProps: { maxLength: 4 }
                    }}
                    simpleLabel
                />
                <Field
                    name="unableToComply"
                    component={Numeric}
                    sm={2}
                    md={2}
                    xl={2}
                    lg={2}
                    InputProps={{
                        inputProps: { maxLength: 4 }
                    }}
                    simpleLabel
                />
                <Field
                    name="siteRestrictions"
                    component={Numeric}
                    sm={2}
                    md={2}
                    xl={2}
                    lg={2}
                    InputProps={{
                        inputProps: { maxLength: 4 }
                    }}
                    simpleLabel
                />
                <Field
                    name="declined"
                    component={Numeric}
                    sm={2}
                    md={2}
                    xl={2}
                    lg={2}
                    InputProps={{
                        inputProps: { maxLength: 4 }
                    }}
                    simpleLabel
                />
                </Box>
                <Field
                    name="numberConsented"
                    component={Numeric}
                    sm={2}
                    md={2}
                    xl={2}
                    lg={2}
                    InputProps={{
                        inputProps: { maxLength: 4 }
                    }}
                    simpleLabel
                />
            </FieldGroup>
        </>
    );
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default ScreeningSummaryFieldGroup;