/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password,
    KeyboardDatePicker,
    Checkbox,
    Numeric,
    Autocomplete
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-trogapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles, Box } from '@material-ui/core';

import { NumberFormatValues } from 'react-number-format';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import { countryCodes } from '../../utils/countryCodes';
import { FilterOptionsState } from '@material-ui/lab';
import { languageCodes } from '../../utils/languageCodes';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IDemographicDetailsProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 3, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */
export const FOUR_DIGIT_POSITIVE_INTEGER = {
    decimalScale: 0,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 10000) {
            return true;
        }

        return false;
    }
}

export const TWO_DIGIT_POSITIVE_INTEGER = {
    decimalScale: 0,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 100) {
            return true;
        }

        return false;
    }
}

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const DemographicDetails: React.FunctionComponent<IDemographicDetailsProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    const { form } = React.useContext(FormContext)

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.DemographicDetails}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <Field
                    name="indigenousStatus"
                    component={Select}
                />
                <Field
                    name="countryOfBirth"
                    component={Autocomplete}
                    options={countryCodes}
                    autoHighlight
                    getOptionLabel={(option: Dtos.ILookupItem) => `${option.id}`}
                    filterOptions={(options: Dtos.ILookupItem[], state: FilterOptionsState<Dtos.ILookupItem>) => options.filter(option => option.id?.toString().includes(state.inputValue) || option.value.toLowerCase().includes(state.inputValue.toLowerCase()))}
                    renderOption={(option: Dtos.ILookupItem) => (
                        <Box style={{ display: 'flex', gap: '1rem' }}>
                            <Box><strong>{option.id}</strong></Box>
                            <Box>{option.value}</Box>
                        </Box>
                    )}
                />
                <Field
                    name="primarySpokenLanguage"
                    component={Autocomplete}
                    options={languageCodes}
                    autoHighlight
                    getOptionLabel={(option: Dtos.ILookupItem) => `${option.id}`}
                    filterOptions={(options: Dtos.ILookupItem[], state: FilterOptionsState<Dtos.ILookupItem>) => options.filter(option => option.id?.toString().includes(state.inputValue) || option.value.toLowerCase().includes(state.inputValue.toLowerCase()))}
                    renderOption={(option: Dtos.ILookupItem) => (
                        <Box style={{ display: 'flex', gap: '1rem' }}>
                            <Box><strong>{option.id}</strong></Box>
                            <Box>{option.value}</Box>
                        </Box>
                    )}
                />
                <Field
                    name="languageSpokenAsAChild"
                    component={Autocomplete}
                    options={languageCodes}
                    autoHighlight
                    getOptionLabel={(option: Dtos.ILookupItem) => `${option.id}`}
                    filterOptions={(options: Dtos.ILookupItem[], state: FilterOptionsState<Dtos.ILookupItem>) => options.filter(option => option.id?.toString().includes(state.inputValue) || option.value.toLowerCase().includes(state.inputValue.toLowerCase()))}
                    renderOption={(option: Dtos.ILookupItem) => (
                        <Box style={{ display: 'flex', gap: '1rem' }}>
                            <Box><strong>{option.id}</strong></Box>
                            <Box>{option.value}</Box>
                        </Box>
                    )}
                />
                <Field
                    name="postcode"
                    component={Numeric}
                    numberFormat={FOUR_DIGIT_POSITIVE_INTEGER}
                />
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default DemographicDetails;
