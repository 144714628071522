/* Options:
Date: 2023-04-03 13:30:42
Version: 6.21
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44386

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
ExcludeTypes: GetUserEmailAddresses,GetUserEmailAddressesResponse,GetInstitutionPersonnelEmailAddresses,InstitutionPersonnelEmailAddressesResponse,SaeWebActionTypeEnum,GetPharmacyEmails,GetPharmacyEmailsResponse
DefaultImports: SaeWebActionTypeEnum:@ngt/opms-sae
*/

import { SaeWebActionTypeEnum } from "@ngt/opms-sae";

export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IGet
{
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export interface IDelete
{
}

export interface IReportRequest
{
    token: string;
}

export class Drug
{
    public id?: number;
    public drugName: string;
    public unitsPerShippingContainer: number;
    public containerName: string;
    public unitConcentration: number;
    public unitVolume: number;
    public enableDrugShipmentGeneration: boolean;

    public constructor(init?: Partial<Drug>) { (Object as any).assign(this, init); }
}

export class Batch
{
    public id?: number;
    public drugId?: number;
    public batchNo: string;
    public dateExpiration?: string;
    public allocationCutOffDate?: string;
    public available: boolean;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public drug: Drug;

    public constructor(init?: Partial<Batch>) { (Object as any).assign(this, init); }
}

export class DepotBatch
{
    public id?: number;
    public depotId: number;
    public batchId: number;
    public depotBatchNo?: number;
    public drugUnitsTotal: number;
    public drugUnitsAllocated: number;
    public drugUnitsDestroyed: number;
    public available: boolean;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public batch: Batch;

    public constructor(init?: Partial<DepotBatch>) { (Object as any).assign(this, init); }
}

export class OrderForm
{
    public id?: number;
    public orderFormName: string;

    public constructor(init?: Partial<OrderForm>) { (Object as any).assign(this, init); }
}

export class Depot
{
    public id?: number;
    public depotName: string;
    public address: string;
    public email: string;
    public contactName: string;
    public phoneNo: string;
    public orderFormId: number;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public orderForm: OrderForm;

    public constructor(init?: Partial<Depot>) { (Object as any).assign(this, init); }
}

export enum PharmacyStatus
{
    Inactive = 1,
    Open = 2,
    Closed = 3,
}

export class PharmacyDrug
{
    public id?: number;
    public pharmacyId: number;
    public drugId: number;
    public capacity: number;
    public minThreshold: number;
    public enabled: boolean;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public drug: Drug;

    public constructor(init?: Partial<PharmacyDrug>) { (Object as any).assign(this, init); }
}

export class Pharmacy
{
    public id?: number;
    public depotId: number;
    public institutionCode: string;
    public pharmacyName: string;
    public pharmacistPersonId?: number;
    public phoneNumber: string;
    public address: string;
    public email: string;
    public ccOnEmails: boolean;
    public pharmacyStatus?: PharmacyStatus;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public notes: string;
    public pharmacyDrugs: PharmacyDrug[];

    public constructor(init?: Partial<Pharmacy>) { (Object as any).assign(this, init); }
}

export class Timepoint
{
    public id?: number;
    public value: string;
    public order?: number;
    public type?: number;

    public constructor(init?: Partial<Timepoint>) { (Object as any).assign(this, init); }
}

export class DrugDispensation implements IDrugManagementForm
{
    public id?: number;
    public pharmacyId?: number;
    public patientId?: number;
    public depotBatchId?: number;
    public timepointId?: number;
    public dateDispensation?: string;
    public drugUnits?: number;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public pharmacy: Pharmacy;
    public depotBatch: DepotBatch;
    public timepoint: Timepoint;

    public constructor(init?: Partial<DrugDispensation>) { (Object as any).assign(this, init); }
}

export enum ShipmentStatus
{
    New = 1,
    Ordered = 2,
    InTransit = 3,
    Available = 4,
    Lost = 5,
    QuarantinedFromTransit = 6,
    Destroyed = 7,
    QuarantinedAtSite = 8,
}

export class DrugShipmentDepotBatch implements IDrugManagementForm
{
    public id?: number;
    public drugShipmentId?: number;
    public depotBatchId?: number;
    public drugUnits?: number;
    public drugUnitsUsed?: number;
    public drugUnitsDamaged?: number;
    public drugUnitsDestroyed?: number;
    public drugUnitsLost?: number;
    public drugUnitsQuarantined?: number;
    public notes: string;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public drugShipment: DrugShipment;
    public depotBatch: DepotBatch;
    public drugAllocated?: number;
    public drugRemaining?: number;

    public constructor(init?: Partial<DrugShipmentDepotBatch>) { (Object as any).assign(this, init); }
}

export class DrugShipmentStatusHistory implements IDrugManagementForm
{
    public id?: number;
    public drugShipmentId?: number;
    public shipmentStatus: ShipmentStatus;
    public statusDate: string;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<DrugShipmentStatusHistory>) { (Object as any).assign(this, init); }
}

export class DrugManagementFileUploadTemporary implements IFileUploadTemporary
{
    public guid: string;
    public name: string;
    public extension: string;
    public fileSize?: number;
    public contentType: string;
    public image: boolean;

    public constructor(init?: Partial<DrugManagementFileUploadTemporary>) { (Object as any).assign(this, init); }
}

export class DrugManagementFile
{
    // @Ignore()
    public upload: DrugManagementFileUploadTemporary;

    // @Ignore()
    public extension: string;

    // @Ignore()
    public fileSize?: number;

    // @Ignore()
    public contentType: string;

    // @Ignore()
    public exists: boolean;

    // @Ignore()
    public image: boolean;

    public constructor(init?: Partial<DrugManagementFile>) { (Object as any).assign(this, init); }
}

export class DrugShipmentDocument
{
    public id?: number;
    public drugShipmentId?: number;
    public name: string;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public file: DrugManagementFile;

    public constructor(init?: Partial<DrugShipmentDocument>) { (Object as any).assign(this, init); }
}

export class DrugShipment implements IDrugManagementForm
{
    public id?: number;
    public institutionCode: string;
    public pharmacyId?: number;
    public pharmacyShipmentNo?: number;
    public shipmentStatus?: ShipmentStatus;
    public consignmentNo: string;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public notes: string;
    public pharmacistPersonId?: number;
    public drugShipmentDepotBatches: DrugShipmentDepotBatch[];
    public drugShipmentStatusHistory: DrugShipmentStatusHistory[];
    public drugShipmentDocuments: DrugShipmentDocument[];

    public constructor(init?: Partial<DrugShipment>) { (Object as any).assign(this, init); }
}

export class DrugAmount
{
    public drugId?: number;
    public drugUnits?: number;

    public constructor(init?: Partial<DrugAmount>) { (Object as any).assign(this, init); }
}

export class DrugShipmentDepotBatchVerify
{
    public id?: number;
    public drugShipmentId?: number;
    public depotBatchId?: number;
    public drugUnits?: number;
    public drugUnitsReceived?: number;
    public drugUnitsDamaged?: number;
    public drugUnitsDestroyed?: number;
    public drugUnitsLost?: number;
    public drugUnitsQuarantined?: number;

    public constructor(init?: Partial<DrugShipmentDepotBatchVerify>) { (Object as any).assign(this, init); }
}

export class DrugShipmentVerify
{
    public id?: number;
    public consignmentNo: string;
    public received?: string;
    public notes: string;
    public drugShipmentDepotBatches: DrugShipmentDepotBatchVerify[];
    public drugShipmentDocuments: DrugShipmentDocument[];

    public constructor(init?: Partial<DrugShipmentVerify>) { (Object as any).assign(this, init); }
}

export enum PatientTreatmentStatus
{
    OnTreatment = 1,
    OffTreatment = 2,
}

export class PatientDrug
{
    public id?: number;
    public patientId?: number;
    public drugId?: number;
    public patientTreatmentStatus?: PatientTreatmentStatus;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public drug: Drug;

    public constructor(init?: Partial<PatientDrug>) { (Object as any).assign(this, init); }
}

export enum QuarantinedStockAction
{
    Quarantined = 1,
    ReturnStockToSiteSupply = 2,
    DestroyStock = 3,
}

export enum SaeStatus
{
    Added = 0,
    New = 1,
    AwaitingForReview = 2,
    Approved = 3,
    Rejected = 4,
    Incorrect = 5,
}

export enum SaeRelatedness
{
    Unrelated = 1,
    Unlikely = 2,
    Possible = 3,
    Probable = 4,
    Definite = 5,
}

export enum SaeExpectedness
{
    Expected = 1,
    Unexpected = 2,
}

export class MedicalReviewItem
{
    public id?: number;
    public medicalReviewId?: number;
    public saeTreatmentCategoryId?: number;
    public typeId?: number;
    public dataEntered?: boolean;
    public latestRelatednessId?: number;
    public relatednessId?: SaeRelatedness;
    public expected?: SaeExpectedness;
    public comments: string;
    public notApplicable?: boolean;

    public constructor(init?: Partial<MedicalReviewItem>) { (Object as any).assign(this, init); }
}

export class MedicalReview
{
    public id?: number;
    public patientStudyNumber: string;
    public saeNumber?: number;
    public saeFollowUpNumber?: number;
    public date?: string;
    public saeStatus?: SaeStatus;
    public narrativeSummary: string;
    public items: MedicalReviewItem[];

    public constructor(init?: Partial<MedicalReview>) { (Object as any).assign(this, init); }
}

export interface ISaeGetCollection extends IGet
{
}

export interface ISaeGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
}

export interface ISaeGetSingleByPatientStudyNumber extends IGet
{
    patientStudyNumber: string;
    saeNumber?: number;
}

export class SaeWebActionType
{
    public id?: number;
    public value: string;

    public constructor(init?: Partial<SaeWebActionType>) { (Object as any).assign(this, init); }
}

export class WebAction
{
    public id?: number;
    public patientStudyNumber: string;
    public saeNumber?: number;
    public saeFollowUpNumber?: number;
    public actionType?: SaeWebActionTypeEnum;
    public actionDate?: string;
    public userName: string;
    public notes: string;
    public recipients: string;
    public saeWebActionType: SaeWebActionType;

    public constructor(init?: Partial<WebAction>) { (Object as any).assign(this, init); }
}

export class SaeStudyDrugReviewForm
{
    public studyDrug: string;
    public investigatorAssessment: string;
    public assessment: string;
    public comments: string;

    public constructor(init?: Partial<SaeStudyDrugReviewForm>) { (Object as any).assign(this, init); }
}

export class SaeExpectednessForm
{
    public assessment: string;
    public comments: string;

    public constructor(init?: Partial<SaeExpectednessForm>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewForm
{
    public studyDrugReviews: SaeStudyDrugReviewForm[];
    public expectedness: SaeExpectednessForm[];
    public isEventStatusDeath: boolean;

    public constructor(init?: Partial<SaeMedicalReviewForm>) { (Object as any).assign(this, init); }
}

export class FileUploadTemporary implements IFileUploadTemporary
{
    public guid: string;
    public name: string;
    public extension: string;
    public fileSize?: number;
    public contentType: string;
    public image: boolean;

    public constructor(init?: Partial<FileUploadTemporary>) { (Object as any).assign(this, init); }
}

export class File
{
    public id?: number;
    public patientId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyName: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    // @Ignore()
    public upload: FileUploadTemporary;

    // @Ignore()
    public extension: string;

    // @Ignore()
    public fileSize?: number;

    // @Ignore()
    public contentType: string;

    // @Ignore()
    public exists: boolean;

    // @Ignore()
    public image: boolean;

    public constructor(init?: Partial<File>) { (Object as any).assign(this, init); }
}

export class SaeDocumentForm
{
    public name: string;
    public file: File;

    public constructor(init?: Partial<SaeDocumentForm>) { (Object as any).assign(this, init); }
}

export enum SaeNotificationType
{
    NotifyInvestigator = 1,
    NotifyMedicalReviewer = 2,
}

export class SaeNotificationForm
{
    public recipients: number[];
    public ccRecipients: number[];
    public notes: string;
    public notifyStudyChair?: boolean;
    public notifySponsor?: boolean;
    public attachments: SaeDocumentForm[];
    public notificationType: SaeNotificationType;

    public constructor(init?: Partial<SaeNotificationForm>) { (Object as any).assign(this, init); }
}

export interface IScreeningSummaryGetSingleById extends IGet
{
    id?: number;
}

export interface IScreeningSummaryGetCollection extends IGet
{
    institutionId?: number;
    year?: number;
}

export interface IScreeningSummaryGetCollectionByInstitutionId extends IGet
{
    institutionId?: number;
}

export class ScreeningSummary implements IScreeningSummary
{
    public id?: number;
    public institutionId?: number;
    public institutionName: string;
    public summaryDate?: string;
    public endDate?: string;
    public preScreened?: number;
    public performanceStatus?: number;
    public contraindicationToTreatment?: number;
    public otherTXStarted?: number;
    public notExtensiveStageSCLC?: number;
    public inadequateFunction?: number;
    public incompatibleComorbidities?: number;
    public unableToComply?: number;
    public siteRestrictions?: number;
    public declined?: number;
    public otherEligibility?: number;
    public numberConsented?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ScreeningSummary>) { (Object as any).assign(this, init); }
}

export interface ILookupGetCollectionByFormDefinitionId extends IGet
{
    formDefinitionId?: number;
}

export interface IEventDefinitionGetSingleById extends IGet
{
    id?: number;
}

export interface IEventDefinitionGetSingleByCode extends IGet
{
    code: string;
}

export interface IEventDefinitionGetCollection extends IGet
{
}

export interface IFormDefinitionGetSingleById extends IGet
{
    id?: number;
}

export interface IFormDefinitionGetSingleByCode extends IGet
{
    code: string;
}

export interface IFormDefinitionGetCollection extends IGet
{
}

export interface IPatientStateGetSingleById extends IGet
{
    id?: number;
}

export interface IPatientStateGetCollection extends IGet
{
}

export interface ITreatmentGetSingleById extends IGet
{
    id?: number;
}

export interface ITreatmentGetCollection extends IGet
{
}

export interface IMasterGroupGetSingleById extends IGet
{
    id?: number;
}

export interface IMasterGroupGetSingleByCode extends IGet
{
    code: string;
}

export interface IMasterGroupGetCollection extends IGet
{
}

export interface ICollaboratingGroupGetSingleById extends IGet
{
    id?: number;
}

export interface ICollaboratingGroupGetSingleByCode extends IGet
{
    code: string;
}

export interface ICollaboratingGroupGetCollection extends IGet
{
}

export interface ICollaboratingGroupGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
}

export interface ICollaboratingGroupGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
}

export interface ICountryGetSingleById extends IGet
{
    id?: number;
}

export interface ICountryGetSingleByCode extends IGet
{
    code: string;
}

export interface ICountryGetCollection extends IGet
{
}

export interface ICountryGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
}

export interface ICountryGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
}

export interface ICountryGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
}

export interface ICountryGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
}

export interface IInstitutionGetSingleById extends IGet
{
    id?: number;
}

export interface IInstitutionGetSingleByCode extends IGet
{
    code: string;
}

export interface IInstitutionGetCollection extends IGet
{
}

export interface IInstitutionGetCollectionByCountryId extends IGet
{
    countryId?: number;
}

export interface IInstitutionGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
    countryId?: number;
}

export interface IInstitutionGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
    countryId?: number;
}

export interface IInstitutionGetCollectionByCountryCode extends IGet
{
    countryCode: string;
}

export interface IInstitutionGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
    countryCode: string;
}

export interface IInstitutionGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
    countryCode: string;
}

export interface IPatientGetSingleById extends IGet
{
    id?: number;
}

export interface IPatientGetSingleByStudyNumber extends IGet
{
    studyNumber: string;
}

export interface IPatientGetValidationById extends IGet
{
    id?: number;
}

export interface IPatientGetValidationByStudyNumber extends IGet
{
    studyNumber: string;
}

export interface IPatientGetCollection extends IGet
{
    patientStateIds: number[];
}

export interface IPatientGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCountryId extends IGet
{
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByInstitutionId extends IGet
{
    institutionId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCountryCode extends IGet
{
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
    patientStateIds: number[];
}

export class Patient implements IOpenClinicaPatient, IRegistrationPatient
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Data.Institution)")
    public institutionId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.PatientState)")
    public patientStateId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.PatientState)")
    public previousPatientStateId?: number;

    public patientStateComment: string;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.Treatment)")
    public treatmentId?: number;

    public studyNumber: string;
    public institutionCode: string;
    public registrationNumber: string;
    public registrationDate?: string;
    public initials: string;
    public dateOfBirth?: string;
    public exportedToOpenClinica?: string;
    public waivered?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Patient>) { (Object as any).assign(this, init); }
}

export interface IEventGetSingleById extends IGet
{
    id?: number;
}

export interface IEventGetSingleByIds extends IGet
{
    institutionId?: number;
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
}

export interface IEventGetSingleByCodes extends IGet
{
    institutionCode: string;
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
}

export interface IEventGetCollection extends IGet
{
}

export interface IEventGetCollectionByPatientId extends IGet
{
    patientId?: number;
}

export interface IEventGetCollectionByPatientStudyNumber extends IGet
{
    patientStudyNumber: string;
}

export class Event implements IEvent
{
    public id?: number;
    public patientId?: number;
    public eventDefinitionId?: number;
    public repeat?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Event>) { (Object as any).assign(this, init); }
}

export interface IFormGetSingleById extends IGet
{
    id?: number;
}

export interface IFormGetSingleByIds extends IGet
{
    institutionId?: number;
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
    formRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
    createForm?: boolean;
}

export interface IFormGetSingleByCodes extends IGet
{
    institutionCode: string;
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
    formRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
    createForm?: boolean;
}

export interface IFormGetCollection extends IGet
{
}

export interface IFormGetCollectionByIds extends IGet
{
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
}

export interface IFormGetCollectionByCodes extends IGet
{
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
}

export class AdditionalEligibilityConfirmation implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public previouslyExposedToImmuneMediatedTherapy?: number;
    public treatedWithConcurrentChemo?: number;
    public treatedWithHighDoseRadiotherapy?: number;
    public treatedWithImmunosuppressiveMedication?: number;
    public treatedWithLiveAttenuatedVaccine?: number;
    public undergoneMajorSurgicalProcedure?: number;
    public haveParaNeoplasticSyndrome?: number;
    public haveAutoimmuneDisorders?: number;
    public haveInterstitialLungDisease?: number;
    public haveImmunodeficiencyHistory?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<AdditionalEligibilityConfirmation>) { (Object as any).assign(this, init); }
}

export class AdditionalImaging implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public startedSystemicTherapy?: number;

    // @Ignore()
    public dateOfSystemicTherapy?: string;

    public imagingType?: number;
    public other: string;
    public imagingDate?: string;
    public imagingResults?: number;
    public resultsClinicallySignificant: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AdditionalImaging>) { (Object as any).assign(this, init); }
}

export class AdverseEvent implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public ctcaeTermId?: number;
    public ctcaeGradeId?: number;
    public ctcaeSpecification: string;
    public startDate: string;
    public ongoing?: boolean;
    public stopDate: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<AdverseEvent>) { (Object as any).assign(this, init); }
}

export class AdverseEventForm implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public participantExperiencingAnyAe?: number;
    public adverseEvents: AdverseEvent[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<AdverseEventForm>) { (Object as any).assign(this, init); }
}

export class BiomarkerSample implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public bloodBiomarkersCollected?: number;
    public reasonForNotCollectingBlood: string;
    public dateOfBloodCollection?: string;
    public samplesAccordanceWithManual?: number;
    public outlineAnyDifferences: string;
    public tumourBiomarkersCollected?: number;
    public reasonForNotCollectingTissue: string;
    public dateOfTissueCollection?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<BiomarkerSample>) { (Object as any).assign(this, init); }
}

export class Bloods implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public startedSystemicTherapy?: number;

    // @Ignore()
    public dateOfSystemicTherapy?: string;

    public haematologyAssessmentDate?: string;
    public haemoglobin?: number;
    public neutrophilCount?: number;
    public plateletCount?: number;
    public chemistryAssessmentDate?: string;
    public totalBilirubin?: number;
    public totalBilirubinUln?: number;
    public participantHasGilbertDisease?: number;
    public alt?: number;
    public altUln?: number;
    public participantHasAnyHepaticMetastasis?: number;
    public ast?: number;
    public astUln?: number;
    public ldh?: number;
    public ggt?: number;
    public lipase?: number;
    public albumin?: number;
    public amylases?: number;
    public creatinine?: number;
    public urea?: number;
    public calcium?: number;
    public magnesium?: number;
    public bicarbonate?: number;
    public glucose?: number;
    public creatinineClearance?: number;
    public coagulationAssessmentDate?: string;
    public prothrombinTime?: number;
    public partialThromboplastinTime?: number;
    public internationalNormalisedRatio?: number;
    public hivAssessmentDate?: string;
    public hbsag?: number;
    public hbcab?: number;
    public hepCAntibody?: number;
    public hcvRna?: number;
    public hivStatus?: number;
    public tshAssessmentDate?: string;
    public tsh?: number;
    public normalRange?: number;
    public t3Free?: number;
    public t4Free?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<Bloods>) { (Object as any).assign(this, init); }
}

export class BmNonTargetLesion implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public nonTargetLesionNumber: string;
    public laterality?: number;
    public location?: number;
    public evaluation?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BmNonTargetLesion>) { (Object as any).assign(this, init); }
}

export class BmTargetLesion implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public targetLesionNumber: string;
    public laterality?: number;
    public location?: number;
    public longestDiameter?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BmTargetLesion>) { (Object as any).assign(this, init); }
}

export class CancerDetails implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public confirmedEsSclc?: number;
    public sclcStage?: number;
    public sclcT?: number;
    public sclcN?: number;
    public sclcM?: number;
    public multipleLungNodules?: number;
    public brainMetastases?: number;
    public brainMetastasesRequiredRt?: number;
    public brainMetastasesAsymptomatic?: number;
    public suitableToReceivePlatinumBasedChemo?: number;
    public plannedPlatinumBasedChemo?: number;
    public thoracicDiseaseSuitableForRt?: number;
    public plannedRadiotherapySchedule?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<CancerDetails>) { (Object as any).assign(this, init); }
}

export class MedicalHistory implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public system?: number;
    public condition: string;
    public startDate: string;
    public ongoing?: boolean;
    public stopDate: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalHistory>) { (Object as any).assign(this, init); }
}

export class ClinicalAssessment implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public sex?: number;

    public dateOfClinicalAssessment?: string;
    public height?: number;
    public weight?: number;
    public systolicBloodPressure?: number;
    public diastolicBloodPressure?: number;
    public pulseRate?: number;
    public respiratoryRate?: number;
    public temperature?: number;
    public ecogPerformanceStatus?: number;
    public physicalExam?: number;
    public anyClinicallyFindings?: number;
    public fertilityStatus?: number;
    public dateOfPregnancyTest?: string;
    public pregnancyTestResult?: number;
    public creatinineClearance?: number;
    public medicalHistory: MedicalHistory[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<ClinicalAssessment>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedication implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public medicationName: string;
    public indication?: number;
    public indicationOther: string;
    public startDate: string;
    public ongoing?: boolean;
    public stopDate: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConcomitantMedication>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedicationForm implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public participantTakingAnyMedications?: number;
    public concomitantMedication: ConcomitantMedication[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<ConcomitantMedicationForm>) { (Object as any).assign(this, init); }
}

export class CtNonTargetLesion implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public nonTargetLesionNumber: string;
    public location: string;
    public evaluation?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CtNonTargetLesion>) { (Object as any).assign(this, init); }
}

export class CtTargetLesion implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public targetLesionNumber: string;
    public locationName: string;
    public longestDiameter?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CtTargetLesion>) { (Object as any).assign(this, init); }
}

export class DemographicDetails implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public indigenousStatus?: number;
    public countryOfBirth: string;
    public primarySpokenLanguage: string;
    public languageSpokenAsAChild: string;
    public postcode: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<DemographicDetails>) { (Object as any).assign(this, init); }
}

export class DiseaseAssessment implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public startedSystemicTherapy?: number;

    // @Ignore()
    public dateOfSystemicTherapy?: string;

    // @Ignore()
    public brainMetastases?: number;

    public assessmentDate?: string;
    public additionalAnatomyClinicallyRequired?: number;
    public head?: boolean;
    public neck?: boolean;
    public legs?: boolean;
    public other?: boolean;
    public anatomicalAreaOther: string;
    public wasContrastUsed?: number;
    public anyTargetLesionsPresent?: number;
    public anyNonTargetLesionsPresent?: number;
    public ctSumOfDiameters?: number;
    public dateofBrainImaging?: string;
    public typeOfImaging?: number;
    public brainMetastasis?: number;
    public lesionType?: number;
    public bmSumOfDiameters?: number;
    public additionalImagingPerformed?: number;
    public ctTargetLesions: CtTargetLesion[];
    public ctNonTargetLesions: CtNonTargetLesion[];
    public bmTargetLesions: BmTargetLesion[];
    public bmNonTargetLesions: BmNonTargetLesion[];
    public additionalImaging: AdditionalImaging[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<DiseaseAssessment>) { (Object as any).assign(this, init); }
}

export class Ecg implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public dateOfEcg?: string;
    public abnormalitiesInLeadEcg?: number;
    public abnormality?: number;
    public abnormalityOther: string;
    public triplicateEcgsCollected?: number;
    public results?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<Ecg>) { (Object as any).assign(this, init); }
}

export class EligibilityConfirmation implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eligibilityConfirmedBy: string;
    public dateConfirmed?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<EligibilityConfirmation>) { (Object as any).assign(this, init); }
}

export class General implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public patientInitials: string;
    public dateOfBirth?: string;
    public ageAtConsent?: number;
    public sex?: number;
    public medicalOncologistName: string;
    public radiationOncologistName: string;
    public dateOfConsent?: string;
    public biomarkerCollectionConsent?: number;
    public startedSystemicTherapy?: number;
    public dateOfSystemicTherapy?: string;
    // @Ignore()
    public regNo: string;

    // @Ignore()
    public dateRegistered?: string;

    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<General>) { (Object as any).assign(this, init); }
}

export class SourceDocument implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public name: string;
    public fileTypeId?: number;
    public file: File;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SourceDocument>) { (Object as any).assign(this, init); }
}

export class SourceDocumentForm implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public sourceDocument: SourceDocument[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<SourceDocumentForm>) { (Object as any).assign(this, init); }
}

export class Urinalysis implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public assessmentDate?: string;
    public result?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<Urinalysis>) { (Object as any).assign(this, init); }
}

export enum Permission
{
    OpmsAccess = 1,
    OpmsInstitutionView = 2,
    OpmsAdminister = 3,
    OpmsPatientView = 4,
    SystemAdminister = 5,
    OpmsPatientUpdate = 6,
    OpmsMasterGroupAdminister = 7,
    OpmsCoordinatingGroupAdminister = 8,
    PathologyTestAdminister = 9,
    BlockShipmentDetailsView = 10,
    OpmsPatientRegister = 11,
    OpmsPatientRandomise = 12,
    SaeView = 13,
    SaeReview = 14,
    ScreeningLogView = 15,
    ScreeningLogUpdate = 16,
    TeleformView = 17,
    TeleformManage = 18,
    EcrfView = 19,
    SaeAdminister = 20,
    MedicalReviewAccess = 21,
    MedicalReviewPerform = 22,
    MedicalReviewAdminister = 23,
    HistopathologyAccess = 24,
    DrugManagementView = 25,
    DrugManagementAdminister = 26,
    DrugManagementDispenseDrug = 27,
    OpmsProView = 28,
    OpmsProUpdate = 29,
    OpmsProAdminister = 30,
    OpmsPatientImpersonate = 31,
    OpmsPatientAccountManagement = 32,
    OpmsCrfView = 33,
    OpmsCrfUpdate = 34,
    OpmsEventView = 35,
    OpmsEventUpdate = 36,
    OpmsConsentAdminister = 37,
    OpmsCoordinatingGroupContactView = 38,
    OpmsSaeInvestigatorNotify = 39,
    EcrfAdminister = 40,
    DrugManagementAdministerPharmacyStock = 41
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class EmailAddress
{
    public displayName: string;
    public address: string;

    public constructor(init?: Partial<EmailAddress>) { (Object as any).assign(this, init); }
}

export class OrderFormData
{
    public drugUnits: number;
    public pharmacyName: string;
    public dateOfRequest: string;
    public pharmacist: string;
    public pharmacyAddress: string;
    public pharmacyPhoneNumber: string;

    public constructor(init?: Partial<OrderFormData>) { (Object as any).assign(this, init); }
}

export class OrderFormDrugBatch
{
    public drug: string;
    public batchNumber: string;
    public expiryDate?: string;
    public drugUnit?: number;

    public constructor(init?: Partial<OrderFormDrugBatch>) { (Object as any).assign(this, init); }
}

export enum PatientStateType
{
    Preregistered = 1,
    UnderReview = 2,
    Registered = 3,
    Ineligible = 7,
    Waivered = 8,
    Removed = 9,
}

export enum EventDefinitionType
{
    Registration = 1,
}

export enum FormDefinitionType
{
    General = 1,
    CancerDetails = 2,
    ClinicalAssessments = 3,
    MedicalHistory = 4,
    AdditionalEligibilityConfirmation = 5,
    AdverseEventForm = 6,
    AdverseEvent = 7,
    ConcomitantMedicationForm = 8,
    ConcomitantMedication = 9,
    DiseaseAssessment = 10,
    CtTargetLesion = 11,
    CtNonTargetLesion = 12,
    BmTargetLesion = 13,
    BmNonTargetLesion = 14,
    AdditionalImaging = 15,
    DemographicDetails = 16,
    Bloods = 17,
    Urinalysis = 18,
    Ecg = 19,
    BiomarkerSamples = 20,
    EligibilityConfirmation = 21,
    SourceDocuments = 22,
    SourceDocument = 23,
}

export enum TreatmentType
{
    Default = 1,
}

export enum LookupEcgAbnormalityType
{
    Rhythm = 1,
    Conduction = 2,
    Morphology = 3,
    Other = 4,
}

export enum LookupEcogType
{
    Ecog0 = 1,
    Ecog1 = 2,
    Ecog2 = 3,
    Ecog3 = 4,
    Ecog4 = 5,
}

export enum LookupEvaluationType
{
    Present = 1,
    Absent = 2,
    UnequivocalProgression = 3,
}

export enum LookupFertilityStatusType
{
    PostMenopausal = 1,
    IrreversibleSurgicalSterilisation = 2,
    PeriOrPremenopausal = 3,
}

export enum LookupImagingType
{
    Mri = 1,
    Ct = 2,
    PetScan = 3,
    BoneScan = 4,
    Other = 5,
}

export enum LookupAdditionalImagingType
{
    PetScan = 1,
    BoneScan = 2,
    Other = 3,
}

export enum LookupIndicationType
{
    ConcomitantSystemicTherapy = 1,
    AdverseEventTreatment = 2,
    SclcTreatment = 3,
    Other = 4,
}

export enum LookupIndigenousStatusType
{
    Indigenous = 1,
    NotIndigenous = 2,
    Unknown = 3,
}

export enum LookupLateralityType
{
    Left = 1,
    Right = 2,
    Midline = 3,
}

export enum LookupLesionType
{
    Target = 1,
    NonTarget = 2,
    Both = 3,
}

export enum LookupLocationType
{
    Frontal = 1,
    Temporal = 2,
    Parietal = 3,
    Occipital = 4,
    BasalGanglia = 5,
    Cerebellum = 6,
    Brainstem = 7,
}

export enum LookupPlannedRadiotherapyScheduleType
{
    ConcurrentRadiation = 1,
    ConsolidationRadiation = 2,
    ToBeConfirmed = 3,
}

export enum LookupPlatinumBasedChemoType
{
    EtoposideCisplatin = 1,
    EtoposideCarboplatin = 2,
}

export enum LookupPositiveNegativeType
{
    Positive = 1,
    Negative = 2,
}

export enum LookupResultType
{
    Normal = 1,
    AbnormalNotClinicallySignificant = 2,
    AbnormalClinicallySignificant = 3,
}

export enum LookupSclcStageType
{
    IIIA = 1,
    IIIB = 2,
    IIIC = 3,
    IVA = 4,
    IVB = 5,
}

export enum LookupSclcMType
{
    M0 = 1,
    M1a = 2,
    M1b = 3,
    M1c = 4,
    Mx = 5,
}

export enum LookupSclcNType
{
    N0 = 1,
    N1 = 2,
    N2 = 3,
    N3 = 4,
    Nx = 5,
}

export enum LookupSclcTType
{
    T1 = 1,
    T2 = 2,
    T3 = 3,
    T4 = 4,
    Tx = 5,
    T0 = 6,
    T1A = 7,
    T1B = 8,
    T1C = 9,
    T2A = 10,
    T2B = 11,
}

export enum LookupSexType
{
    Male = 1,
    Female = 2,
    Other = 3,
}

export enum LookupSourceDocumentType
{
    ConsentForm = 1,
    ImagingCt = 2,
    ImagingMri = 3,
    ImagingOther = 4,
    BloodClinical = 5,
    Urinalysis = 6,
    Ecg = 7,
    EligibilityConfirmation = 8,
    Histology = 9,
    Other = 10,
}

export enum LookupSystemType
{
    Head = 1,
    Respiratory = 2,
    Cardiovascular = 3,
    Gastrointestinal = 4,
    Urogenital = 5,
    Musculoskeletal = 6,
    Neurological = 7,
    Dermatological = 8,
    Hematologic = 9,
    Endocrine = 10,
    Other = 11,
}

export enum LookupUntypedType
{
    NoType = 1,
}

export enum LookupYesNoType
{
    No = 0,
    Yes = 1,
}

export class IdentityServerExceptionInfo
{
    public message: string;
    public stackTrace: string;
    public innerException: IdentityServerExceptionInfo;

    public constructor(init?: Partial<IdentityServerExceptionInfo>) { (Object as any).assign(this, init); }
}

export class IdentityServerDiagnostic
{
    public test: string;
    public pass: boolean;
    public exception: IdentityServerExceptionInfo;
    public values: { [index: string]: Object; };

    public constructor(init?: Partial<IdentityServerDiagnostic>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export enum ValidationResultType
{
    Valid = 1,
    Ineligible = 2,
    Error = 3,
    StratificationFailure = 4,
    Critical = 5,
}

export enum ValidationErrorType
{
    Warning = 1,
    Ineligible = 2,
    Normal = 3,
    StratificationFailure = 4,
    Critical = 5,
}

export class DrugManagementValidationError implements IDrugManagementValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<DrugManagementValidationError>) { (Object as any).assign(this, init); }
}

export class DrugManagementValidationResult
{
    public result: ValidationResultType;
    public errors: DrugManagementValidationError[];

    public constructor(init?: Partial<DrugManagementValidationResult>) { (Object as any).assign(this, init); }
}

export class DrugManagementValidationResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: DrugManagementValidationResult;

    public constructor(init?: Partial<DrugManagementValidationResponse>) { (Object as any).assign(this, init); }
}

export interface IPharmacist
{
    id?: number;
    title: string;
    firstName: string;
    lastName: string;
    deliveryAddress: string;
    deliveryStreet2: string;
    deliverySuburb: string;
    deliveryState: string;
    deliveryPostCode: string;
    deliveryCountry: string;
    email: string;
    phone: string;
    fax: string;
}

export class PharmacyBatchDrugRemaining
{
    public pharmacy: Pharmacy;
    public batch: Batch;
    public drugRemaining?: number;

    public constructor(init?: Partial<PharmacyBatchDrugRemaining>) { (Object as any).assign(this, init); }
}

export class SaeFormValidationError implements ISaeFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<SaeFormValidationError>) { (Object as any).assign(this, init); }
}

export class SaeFormValidationResult
{
    public result: ValidationResultType;
    public errors: SaeFormValidationError[];

    public constructor(init?: Partial<SaeFormValidationResult>) { (Object as any).assign(this, init); }
}

export enum SaeMedicalReviewStatus
{
    NotNotified = 0,
    Pending = 1,
    Reviewed = 3,
}

export class LookupItem implements ILookupItem
{
    public id?: number;
    public value: string;
    public order?: number;
    public type?: number;

    public constructor(init?: Partial<LookupItem>) { (Object as any).assign(this, init); }
}

export class Lookup
{
    public propertyName: string;
    public items: LookupItem[];

    public constructor(init?: Partial<Lookup>) { (Object as any).assign(this, init); }
}

export class Sae<TSaeForm, TPatient>
{
    public patient: TPatient;
    public form: TSaeForm;
    public status: SaeStatus;
    public ecrfs: TSaeForm[];
    public saeFormItems: { [index: string]: string; };
    public medicalReview: MedicalReview;
    public medicalReviewStatus: SaeMedicalReviewStatus;
    public actionHistory: WebAction[];
    public lookups: Lookup[];

    public constructor(init?: Partial<Sae<TSaeForm, TPatient>>) { (Object as any).assign(this, init); }
}

export class ScreeningSummaryFormValidationError implements IScreeningSummaryFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<ScreeningSummaryFormValidationError>) { (Object as any).assign(this, init); }
}

export class ScreeningSummaryFormValidationResult
{
    public result: ValidationResultType;
    public errors: ScreeningSummaryFormValidationError[];

    public constructor(init?: Partial<ScreeningSummaryFormValidationResult>) { (Object as any).assign(this, init); }
}

export interface IFileUploadTemporary
{
    guid: string;
    name: string;
    extension: string;
    fileSize?: number;
    contentType: string;
    image: boolean;
}

export enum UserType
{
    TrialPersonnel = 1,
    Patient = 2,
}

export class FormMetadata
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public name: string;
    public propertyName: string;
    public title: string;
    public instructions: string;

    public constructor(init?: Partial<FormMetadata>) { (Object as any).assign(this, init); }
}

export class EventDefinitionFormDefinitionState implements IEventDefinitionFormDefinitionState
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinitionFormDefinition)")
    public eventDefinitionFormDefinitionId?: number;

    public patientStateId?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;

    public constructor(init?: Partial<EventDefinitionFormDefinitionState>) { (Object as any).assign(this, init); }
}

export class EventDefinitionFormDefinition
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public repeating?: boolean;
    public minimumRepeat?: number;
    public maximumRepeat?: number;
    public order?: number;
    public states: EventDefinitionFormDefinitionState[];

    public constructor(init?: Partial<EventDefinitionFormDefinition>) { (Object as any).assign(this, init); }
}

export class EventDefinitionState implements IEventDefinitionState
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    public patientStateId?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;

    public constructor(init?: Partial<EventDefinitionState>) { (Object as any).assign(this, init); }
}

export class EventDefinitionErrorMessage implements IEventDefinitionErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<EventDefinitionErrorMessage>) { (Object as any).assign(this, init); }
}

export class EventDefinition
{
    public id?: number;
    public name: string;
    public code: string;
    public repeating?: boolean;
    public minimumRepeat?: number;
    public maximumRepeat?: number;
    public order?: number;
    public formDefinitions: EventDefinitionFormDefinition[];
    public states: EventDefinitionState[];
    public errorMessages: EventDefinitionErrorMessage[];

    public constructor(init?: Partial<EventDefinition>) { (Object as any).assign(this, init); }
}

export enum FormType
{
    Crf = 1,
    Pro = 2,
    Staff = 3,
}

export class FormDefinitionPropertyErrorMessage implements IFormDefinitionPropertyErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinitionProperty)")
    public formDefinitionPropertyId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormDefinitionPropertyErrorMessage>) { (Object as any).assign(this, init); }
}

export class FormDefinitionProperty
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public propertyName: string;
    public label: string;
    public detailedLabel: string;
    public errorMessages: FormDefinitionPropertyErrorMessage[];

    public constructor(init?: Partial<FormDefinitionProperty>) { (Object as any).assign(this, init); }
}

export class FormDefinition
{
    public id?: number;
    public code: string;
    public type?: FormType;
    public name: string;
    public instructions: string;
    public patientInstructions: string;
    public properties: FormDefinitionProperty[];
    // @Ignore()
    public subformProperties: { [index: string]: FormDefinitionProperty[]; };

    public constructor(init?: Partial<FormDefinition>) { (Object as any).assign(this, init); }
}

export class PatientState implements IPatientState
{
    public id?: number;
    public name: string;
    public order?: number;

    public constructor(init?: Partial<PatientState>) { (Object as any).assign(this, init); }
}

export class Treatment implements ITreatment
{
    public id?: number;
    public name: string;

    public constructor(init?: Partial<Treatment>) { (Object as any).assign(this, init); }
}

export class MasterGroup implements IMasterGroup
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MasterGroup>) { (Object as any).assign(this, init); }
}

export class CollaboratingGroup implements ICollaboratingGroup
{
    public id?: number;
    public masterGroupId?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CollaboratingGroup>) { (Object as any).assign(this, init); }
}

export class Country implements ICountry
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class Institution implements IInstitution
{
    public id?: number;
    public countryId?: number;
    public masterGroupId?: number;
    public collaboratingGroupId?: number;
    public code: string;
    public name: string;
    public enabled?: boolean;
    public activatedDate?: string;
    public recruitmentDisabled?: boolean;
    public eConsentActivationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Institution>) { (Object as any).assign(this, init); }
}

export class PatientValidationError implements IPatientValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<PatientValidationError>) { (Object as any).assign(this, init); }
}

export class EventValidationError implements IEventValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<EventValidationError>) { (Object as any).assign(this, init); }
}

export class FormValidationError implements IFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormValidationError>) { (Object as any).assign(this, init); }
}

export class FormValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public formId?: number;
    public formDefinitionId?: number;
    public formRepeat?: number;
    public completed?: boolean;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public errors: FormValidationError[];

    public constructor(init?: Partial<FormValidationResult>) { (Object as any).assign(this, init); }
}

export class EventValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public eventId?: number;
    public eventDefinitionId?: number;
    public eventRepeat?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public errors: EventValidationError[];
    public formResults: FormValidationResult[];

    public constructor(init?: Partial<EventValidationResult>) { (Object as any).assign(this, init); }
}

export class PatientValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public patientId?: number;
    public patientDefinitionId?: number;
    public patientRepeat?: number;
    public errors: PatientValidationError[];
    public eventResults: EventValidationResult[];

    public constructor(init?: Partial<PatientValidationResult>) { (Object as any).assign(this, init); }
}

export interface IDrugManagementForm
{
}

export interface IScreeningSummary extends IDataModel
{
    institutionId?: number;
    institutionName: string;
    summaryDate?: string;
    endDate?: string;
}

export interface IDataModel extends IModel, IHasAudit
{
}

export interface IModel
{
    id?: number;
}

export interface IHasAudit
{
    enteredDate?: string;
    enteredBy: string;
    modifiedDate?: string;
    modifiedBy: string;
}

export interface IOpenClinicaPatient extends IPatient
{
    exportedToOpenClinica?: string;
}

export interface IPatient extends IDataModel, IHasInstitutionId, IHasPatientStateId, IHasTreatmentId
{
    studyNumber: string;
}

export interface IHasInstitutionId
{
    institutionId?: number;
}

export interface IHasPatientStateId
{
    patientStateId?: number;
}

export interface IHasTreatmentId
{
    treatmentId?: number;
}

export interface IRegistrationPatient
{
    registrationNumber: string;
    registrationDate?: string;
}

export interface IEvent extends IDataModel, IHasPatientId, IHasEventDefinitionId
{
    repeat?: number;
}

export interface IHasPatientId
{
    patientId?: number;
}

export interface IHasEventDefinitionId
{
    eventDefinitionId?: number;
}

export interface IForm extends IDataModel, IHasPatientId, IHasEventId, IHasParentFormDefinitionId, IHasParentFormId
{
    repeat?: number;
}

export interface IHasEventId
{
    eventId?: number;
}

export interface IHasParentFormDefinitionId
{
    parentFormDefinitionId?: number;
}

export interface IHasParentFormId
{
    parentFormId?: number;
}

export interface IOpenClinicaForm extends IForm
{
    exportedToOpenClinica?: string;
}

export interface IDrugManagementValidationError extends IValidationError
{
    property: string;
}

export interface IDrugManagementValidationResult extends IValidationResult
{
    errors: IDrugManagementValidationError[];
}

export interface IValidationError
{
    message: string;
    detailedMessage: string;
    code: string;
    type?: ValidationErrorType;
}

export interface IValidationResult
{
    result: ValidationResultType;
    errors: IValidationError[];
}

export interface ISaeFormValidationError extends IValidationError
{
    property: string;
}

export interface ISaeFormValidationResult extends IValidationResult
{
    errors: ISaeFormValidationError[];
}

export class RelevantMedicationHistory implements ISaeSubform
{
    public ordinal: number;
    public rmhDiseaseConditionProcedure: string;
    public rmhMedicationStartDate?: string;
    public rmhMedicationStopDate?: string;
    public rmhOngoing?: boolean;

    public constructor(init?: Partial<RelevantMedicationHistory>) { (Object as any).assign(this, init); }
}

export class EventTreatment implements ISaeSubform
{
    public ordinal: number;
    public etProcedure: string;
    public etDate?: string;
    public etResult: string;
    public etPending?: boolean;

    public constructor(init?: Partial<EventTreatment>) { (Object as any).assign(this, init); }
}

export class PrescribedMedications implements ISaeSubform
{
    public ordinal: number;
    public pmNameOfMedication: string;
    public pmIndicationOfMedication: string;
    public pmStartDate?: string;
    public pmStopDate?: string;
    public pmOngoing?: boolean;
    public pmDose?: number;
    public pmUnit: string;
    public pmUnitOther: string;
    public pmRoute: string;
    public pmFrequency: string;
    public pmFrequencyOther: string;

    public constructor(init?: Partial<PrescribedMedications>) { (Object as any).assign(this, init); }
}

export class SaeForm implements ISaeForm
{
    public id?: number;
    public patientId?: number;
    public saeNumber?: number;
    public followUpNumber?: number;
    public reportingInvestigator: string;
    public ctcaeTerm: string;
    public ctcaeGrade?: number;
    public onsetDate?: string;
    public resultsInDeath: string;
    public lifeThreatening: string;
    public inPatientHospitalisation: string;
    public admissionDate?: string;
    public dischargeDate?: string;
    public dischargeDateOngoing?: boolean;
    public disabilityIncapacity: string;
    public congenitalAnomalyBirthDefect: string;
    public importantMedicalEvent: string;
    public relationshipToDurvalumab: string;
    public relationshipToRadiotherapy: string;
    public relationshipToEtoposide: string;
    public relationshipToCisplatin: string;
    public relationshipToCarboplatin: string;
    public progressiveDisease: string;
    public trialProcedure: string;
    public nameOfProcedure: string;
    public indicationOfProcedure: string;
    public procedureDate?: string;
    public concurrentDisorder: string;
    public concurrentDisorderSpecify: string;
    public concomitantMedication: string;
    public conComNameOfMedication: string;
    public conComIndication: string;
    public conComStartDate?: string;
    public conComStopDate?: string;
    public conComOnGoing?: boolean;
    public conComDose?: number;
    public conComUnit: string;
    public conComUnitOther: string;
    public conComRoute: string;
    public conComFrequency: string;
    public conComFrequencyOther: string;
    public other: string;
    public otherSpecify: string;
    public reactionAbateAfterStopping: string;
    public reactionReappeared: string;
    public eventDescription: string;
    public durvalumabDateOfLastAdministration?: string;
    public durvalumabDose: string;
    public durvalumabBatchNumber: string;
    public durvalumabExpiryDate?: string;
    public durvalumabModification: string;
    public chemotherapyDateOfLastAdministration?: string;
    public chemotherapyEtoposideDose: string;
    public chemotherapyEtoposideModification: string;
    public chemotherapyCarboplatinDose: string;
    public chemotherapyCarboplatinNA?: boolean;
    public chemotherapyCarboplatinModification: string;
    public chemotherapyCisplatinDose: string;
    public chemotherapyCisplatinNA?: boolean;
    public chemotherapyCisplatinModification: string;
    public radiotherapyStartDate?: string;
    public radiotherapyNA?: boolean;
    public radiotherapyLastFractionBeforeEvent?: string;
    public radiotherapyDose?: number;
    public radiotherapyModification: string;
    public eventStatus: string;
    public describeSequelae: string;
    public causeOfDeath: string;
    public dateOfAutopsy?: string;
    public reasonUnknown: string;
    public dateEventResolved?: string;
    public relevantMedicationHistory: RelevantMedicationHistory[];
    public eventTreatment: EventTreatment[];
    public prescribedMedications: PrescribedMedications[];
    public dateCreated?: string;
    public dateUpdated?: string;
    public unassignedProperties: { [index: string]: Object; };
    public inconvertableProperties: { [index: string]: string; };

    public constructor(init?: Partial<SaeForm>) { (Object as any).assign(this, init); }
}

export interface ISaeForm extends IModel
{
    patientId?: number;
    saeNumber?: number;
    followUpNumber?: number;
    eventStatus: string;
    dateCreated?: string;
    dateUpdated?: string;
    unassignedProperties: { [index: string]: Object; };
    inconvertableProperties: { [index: string]: string; };
}

export interface IScreeningSummaryFormValidationError extends IValidationError
{
    property: string;
}

export interface IScreeningSummaryFormValidationResult extends IValidationResult
{
    errors: IScreeningSummaryFormValidationError[];
}

export interface ILookupItem
{
    id?: number;
    value: string;
    order?: number;
    type?: number;
}

export interface ILookup
{
    propertyName: string;
    items: ILookupItem[];
}

export interface IEventDefinitionFormDefinitionState extends IConfigurationModel, IHasEventDefinitionFormDefinitionId, IHasPatientStateId
{
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
}

export interface IEventDefinitionFormDefinition extends IConfigurationModel, IHasEventDefinitionId, IHasFormDefinitionId
{
    repeating?: boolean;
    minimumRepeat?: number;
    maximumRepeat?: number;
    order?: number;
    states: IEventDefinitionFormDefinitionState[];
}

export interface IEventDefinitionState extends IConfigurationModel, IHasEventDefinitionId, IHasPatientStateId
{
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
}

export interface IEventDefinitionErrorMessage extends IConfigurationModel, IHasEventDefinitionId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IEventDefinition extends IConfigurationModel
{
    name: string;
    code: string;
    repeating?: boolean;
    minimumRepeat?: number;
    maximumRepeat?: number;
    order?: number;
    formDefinitions: IEventDefinitionFormDefinition[];
    states: IEventDefinitionState[];
    errorMessages: IEventDefinitionErrorMessage[];
}

export interface IConfigurationModel extends IModel
{
}

export interface IFormDefinitionPropertyErrorMessage extends IConfigurationModel, IHasFormDefinitionPropertyId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IFormDefinitionProperty extends IConfigurationModel, IHasFormDefinitionId
{
    propertyName: string;
    label: string;
    detailedLabel: string;
    errorMessages: IFormDefinitionPropertyErrorMessage[];
}

export interface IFormDefinition extends IConfigurationModel
{
    type?: FormType;
    name: string;
    code: string;
    instructions: string;
    patientInstructions: string;
    properties: IFormDefinitionProperty[];
    subformProperties: { [index: string]: IFormDefinitionProperty[]; };
}

export interface IPatientState extends IConfigurationModel
{
    name: string;
    order?: number;
}

export interface ITreatment extends IConfigurationModel
{
    name: string;
}

export interface IMasterGroup extends IDataModel
{
    name: string;
    code: string;
}

export interface ICollaboratingGroup extends IDataModel, IHasMasterGroupId
{
    name: string;
    code: string;
}

export interface IHasMasterGroupId
{
    masterGroupId?: number;
}

export interface ICountry extends IDataModel
{
    name: string;
    code: string;
}

export interface IInstitution extends IDataModel, IHasMasterGroupId, IHasCollaboratingGroupId, IHasCountryId
{
    name: string;
    code: string;
    enabled?: boolean;
    activatedDate?: string;
    recruitmentDisabled?: boolean;
    eConsentActivationDate?: string;
}

export interface IHasCollaboratingGroupId
{
    collaboratingGroupId?: number;
}

export interface IHasCountryId
{
    countryId?: number;
}

export interface IPatientValidationError extends IValidationError
{
}

export interface IEventValidationError extends IValidationError
{
}

export interface IFormValidationError extends IValidationError
{
    property: string;
}

export interface IFormValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    formId?: number;
    formDefinitionId?: number;
    formRepeat?: number;
    completed?: boolean;
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    errors: IFormValidationError[];
}

export interface IEventValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    eventDefinitionId?: number;
    eventId?: number;
    eventRepeat?: number;
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    errors: IEventValidationError[];
    formResults: IFormValidationResult[];
}

export interface IPatientValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    patientId?: number;
    errors: IPatientValidationError[];
    eventResults: IEventValidationResult[];
}

export interface IFile extends IDataModel, IHasPatientId, IHasFormDefinitionId, IHasFormId, IFileUpload
{
    propertyName: string;
}

export interface IHasFormDefinitionId
{
    formDefinitionId?: number;
}

export interface IHasFormId
{
    formId?: number;
}

export interface IFileUpload
{
    extension: string;
    fileSize?: number;
    contentType: string;
    exists: boolean;
    image: boolean;
    upload: IFileUploadTemporary;
}

export interface ISaeSubform
{
    ordinal: number;
}

export interface IHasEventDefinitionFormDefinitionId
{
    eventDefinitionFormDefinitionId?: number;
}

export interface IHasFormDefinitionPropertyId
{
    formDefinitionPropertyId?: number;
}

export class SendPatientIneligibleEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPatientIneligibleEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendPatientSubmittedForReviewEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPatientSubmittedForReviewEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendPatientReassignedToSiteEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPatientReassignedToSiteEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendRegistrationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendRegistrationEmailResponse>) { (Object as any).assign(this, init); }
}

export class QueuePatientOcTransferResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<QueuePatientOcTransferResponse>) { (Object as any).assign(this, init); }
}

export class QueuePatientsOcTransferResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<QueuePatientsOcTransferResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeNotifyInvestigatorEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeNotifyInvestigatorEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeInvestigatorReviewEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeInvestigatorReviewEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendNewSaeEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendNewSaeEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendUnapprovedSaeEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendUnapprovedSaeEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendUnresolvedSaeEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendUnresolvedSaeEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendDrugShipmentOrderedEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendDrugShipmentOrderedEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendDrugShipmentGenerationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendDrugShipmentGenerationEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendStagnantDrugOrdersEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendStagnantDrugOrdersEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendStagnantDrugShipmentsEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendStagnantDrugShipmentsEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendExpiringStockEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendExpiringStockEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendLowStockEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendLowStockEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendLateDispensationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendLateDispensationEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendDrugDispensationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendDrugDispensationEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendDrugShipmentQuarantinedEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendDrugShipmentQuarantinedEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendDrugShipmentReceivedEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendDrugShipmentReceivedEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendDrugShipmentExpiringEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendDrugShipmentExpiringEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendDrugShipmentDispatchedEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendDrugShipmentDispatchedEmailResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentDepotBatchesByIdsResponse
{
    public responseStatus: ResponseStatus;
    public drugShipmentDepotBatches: DrugShipmentDepotBatch[];

    public constructor(init?: Partial<GetDrugShipmentDepotBatchesByIdsResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentOrderFormDataResponse
{
    public responseStatus: ResponseStatus;
    public orderFormData: OrderFormData;

    public constructor(init?: Partial<GetDrugShipmentOrderFormDataResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentOrderFormDrugBatchesResponse
{
    public responseStatus: ResponseStatus;
    public orderFormDrugBatches: OrderFormDrugBatch[];

    public constructor(init?: Partial<GetDrugShipmentOrderFormDrugBatchesResponse>) { (Object as any).assign(this, init); }
}

export class GetIneligibleReportDataResponse
{
    public responseStatus: ResponseStatus;
    public totals: number[];
    public finalTotal: number;

    public constructor(init?: Partial<GetIneligibleReportDataResponse>) { (Object as any).assign(this, init); }
}

export class GetEnumerationTypesResponse
{
    public patientStateType?: PatientStateType;
    public eventDefinitionType?: EventDefinitionType;
    public formDefinitionType?: FormDefinitionType;
    public treatmentType?: TreatmentType;
    public lookupEcgAbnormalityType?: LookupEcgAbnormalityType;
    public lookupEcogType?: LookupEcogType;
    public lookupEvaluationType?: LookupEvaluationType;
    public lookupFertilityStatusType?: LookupFertilityStatusType;
    public lookupImagingType?: LookupImagingType;
    public lookupAdditionalImagingType?: LookupAdditionalImagingType;
    public lookupIndicationType?: LookupIndicationType;
    public lookupIndigenousStatusType?: LookupIndigenousStatusType;
    public lookupLateralityType?: LookupLateralityType;
    public lookupLesionType?: LookupLesionType;
    public lookupLocationType?: LookupLocationType;
    public lookupPlannedRadiotherapyScheduleType?: LookupPlannedRadiotherapyScheduleType;
    public lookupPlatinumBasedChemoType?: LookupPlatinumBasedChemoType;
    public lookupPositiveNegativeType?: LookupPositiveNegativeType;
    public lookupResultType?: LookupResultType;
    public lookupSclcStageType?: LookupSclcStageType;
    public lookupSclcMType?: LookupSclcMType;
    public lookupSclcNType?: LookupSclcNType;
    public lookupSclcTType?: LookupSclcTType;
    public lookupSexType?: LookupSexType;
    public lookupSourceDocumentType?: LookupSourceDocumentType;
    public lookupSystemType?: LookupSystemType;
    public lookupUntypedType?: LookupUntypedType;
    public lookupYesNoType?: LookupYesNoType;

    public constructor(init?: Partial<GetEnumerationTypesResponse>) { (Object as any).assign(this, init); }
}

export class IdentityServerDiagnosticsResponse
{
    public responseStatus: ResponseStatus;
    public diagnostics: IdentityServerDiagnostic[];

    public constructor(init?: Partial<IdentityServerDiagnosticsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

export class GetBatchesResponse
{
    public responseStatus: ResponseStatus;
    public batches: Batch[];

    public constructor(init?: Partial<GetBatchesResponse>) { (Object as any).assign(this, init); }
}

export class GetBatchByIdResponse
{
    public responseStatus: ResponseStatus;
    public batch: Batch;

    public constructor(init?: Partial<GetBatchByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetBatchesByIdsResponse
{
    public responseStatus: ResponseStatus;
    public batches: Batch[];

    public constructor(init?: Partial<GetBatchesByIdsResponse>) { (Object as any).assign(this, init); }
}

export class GetBatchesExpiringAtSpecifiedDateResponse
{
    public responseStatus: ResponseStatus;
    public batches: Batch[];

    public constructor(init?: Partial<GetBatchesExpiringAtSpecifiedDateResponse>) { (Object as any).assign(this, init); }
}

export class SetBatchResponse
{
    public responseStatus: ResponseStatus;
    public batch: Batch;

    public constructor(init?: Partial<SetBatchResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchesResponse
{
    public responseStatus: ResponseStatus;
    public depotBatches: DepotBatch[];

    public constructor(init?: Partial<GetDepotBatchesResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchByIdResponse
{
    public responseStatus: ResponseStatus;
    public depotBatch: DepotBatch;

    public constructor(init?: Partial<GetDepotBatchByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchesByDepotIdResponse
{
    public responseStatus: ResponseStatus;
    public depotBatches: DepotBatch[];

    public constructor(init?: Partial<GetDepotBatchesByDepotIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchesByPharmacyIdResponse
{
    public responseStatus: ResponseStatus;
    public depotBatches: DepotBatch[];

    public constructor(init?: Partial<GetDepotBatchesByPharmacyIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchesByDrugShipmentIdResponse
{
    public responseStatus: ResponseStatus;
    public depotBatches: DepotBatch[];

    public constructor(init?: Partial<GetDepotBatchesByDrugShipmentIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchesByBatchIdsResponse
{
    public responseStatus: ResponseStatus;
    public depotBatches: DepotBatch[];

    public constructor(init?: Partial<GetDepotBatchesByBatchIdsResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchesWithLowStockByDepotIdsResponse
{
    public responseStatus: ResponseStatus;
    public depotBatches: DepotBatch[];

    public constructor(init?: Partial<GetDepotBatchesWithLowStockByDepotIdsResponse>) { (Object as any).assign(this, init); }
}

export class SetDepotBatchResponse
{
    public responseStatus: ResponseStatus;
    public depotBatch: DepotBatch;

    public constructor(init?: Partial<SetDepotBatchResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotsResponse
{
    public responseStatus: ResponseStatus;
    public depots: Depot[];

    public constructor(init?: Partial<GetDepotsResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotByIdResponse
{
    public responseStatus: ResponseStatus;
    public depot: Depot;

    public constructor(init?: Partial<GetDepotByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotsByDrugUnderThresholdResponse
{
    public responseStatus: ResponseStatus;
    public depotIds: number[];

    public constructor(init?: Partial<GetDepotsByDrugUnderThresholdResponse>) { (Object as any).assign(this, init); }
}

export class SetDepotResponse
{
    public responseStatus: ResponseStatus;
    public depot: Depot;

    public constructor(init?: Partial<SetDepotResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugDispensationsResponse
{
    public responseStatus: ResponseStatus;
    public drugDispensations: DrugDispensation[];

    public constructor(init?: Partial<GetDrugDispensationsResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugDispensationByIdResponse
{
    public responseStatus: ResponseStatus;
    public drugDispensation: DrugDispensation;

    public constructor(init?: Partial<GetDrugDispensationByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugDispensationByPatientIdResponse
{
    public responseStatus: ResponseStatus;
    public drugDispensations: DrugDispensation[];

    public constructor(init?: Partial<GetDrugDispensationByPatientIdResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientsWithLateDispensationsResponse
{
    public responseStatus: ResponseStatus;
    public patientIds: number[];

    public constructor(init?: Partial<GetPatientsWithLateDispensationsResponse>) { (Object as any).assign(this, init); }
}

export class GetLastDispensationForPatientIdResponse
{
    public responseStatus: ResponseStatus;
    public drugDispensation: DrugDispensation;

    public constructor(init?: Partial<GetLastDispensationForPatientIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugDispensationsByIdsResponse
{
    public responseStatus: ResponseStatus;
    public drugDispensations: DrugDispensation[];

    public constructor(init?: Partial<GetDrugDispensationsByIdsResponse>) { (Object as any).assign(this, init); }
}

export class SetDrugDispensationResponse
{
    public responseStatus: ResponseStatus;
    public drugDispensation: DrugDispensation;

    public constructor(init?: Partial<SetDrugDispensationResponse>) { (Object as any).assign(this, init); }
}

export class DeleteDrugDispensationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteDrugDispensationResponse>) { (Object as any).assign(this, init); }
}

export class ValidateDrugDispensationResponse extends DrugManagementValidationResponse
{

    public constructor(init?: Partial<ValidateDrugDispensationResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetDrugsResponse
{
    public responseStatus: ResponseStatus;
    public drugs: Drug[];

    public constructor(init?: Partial<GetDrugsResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentDepotBatchesResponse
{
    public responseStatus: ResponseStatus;
    public drugShipmentDepotBatches: DrugShipmentDepotBatch[];

    public constructor(init?: Partial<GetDrugShipmentDepotBatchesResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentDepotBatchByIdResponse
{
    public responseStatus: ResponseStatus;
    public drugShipmentDepotBatch: DrugShipmentDepotBatch;

    public constructor(init?: Partial<GetDrugShipmentDepotBatchByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentDepotBatchesByPharmacyIdResponse
{
    public responseStatus: ResponseStatus;
    public drugShipmentDepotBatches: DrugShipmentDepotBatch[];

    public constructor(init?: Partial<GetDrugShipmentDepotBatchesByPharmacyIdResponse>) { (Object as any).assign(this, init); }
}

export class SetDrugShipmentDepotBatchResponse
{
    public responseStatus: ResponseStatus;
    public drugShipmentDepotBatch: DrugShipmentDepotBatch;

    public constructor(init?: Partial<SetDrugShipmentDepotBatchResponse>) { (Object as any).assign(this, init); }
}

export class StartDrugShipmentGenerationResponse
{
    public responseStatus: ResponseStatus;
    public messages: string[];
    public createdDrugShipments: DrugShipment[];

    public constructor(init?: Partial<StartDrugShipmentGenerationResponse>) { (Object as any).assign(this, init); }
}

export class CreateDrugShipmentFromPatientResponse
{
    public responseStatus: ResponseStatus;
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<CreateDrugShipmentFromPatientResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentsResponse
{
    public responseStatus: ResponseStatus;
    public drugShipments: DrugShipment[];

    public constructor(init?: Partial<GetDrugShipmentsResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentsByInstitutionResponse
{
    public responseStatus: ResponseStatus;
    public drugShipments: DrugShipment[];

    public constructor(init?: Partial<GetDrugShipmentsByInstitutionResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentByIdResponse
{
    public responseStatus: ResponseStatus;
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<GetDrugShipmentByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentsByIdsResponse
{
    public responseStatus: ResponseStatus;
    public drugShipments: DrugShipment[];

    public constructor(init?: Partial<GetDrugShipmentsByIdsResponse>) { (Object as any).assign(this, init); }
}

export class SetDrugShipmentResponse
{
    public responseStatus: ResponseStatus;
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<SetDrugShipmentResponse>) { (Object as any).assign(this, init); }
}

export class ValidateDrugShipmentResponse extends DrugManagementValidationResponse
{

    public constructor(init?: Partial<ValidateDrugShipmentResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SetDrugShipmentStatusResponse
{
    public responseStatus: ResponseStatus;
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<SetDrugShipmentStatusResponse>) { (Object as any).assign(this, init); }
}

export class DeleteDrugShipmentResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteDrugShipmentResponse>) { (Object as any).assign(this, init); }
}

export class SetDrugShipmentVerifyResponse
{
    public responseStatus: ResponseStatus;
    public drugShipmentVerify: DrugShipmentVerify;

    public constructor(init?: Partial<SetDrugShipmentVerifyResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentsNotShippedByExpectedDateResponse
{
    public responseStatus: ResponseStatus;
    public drugShipments: DrugShipment[];

    public constructor(init?: Partial<GetDrugShipmentsNotShippedByExpectedDateResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentsNotReceivedByExpectedDateResponse
{
    public responseStatus: ResponseStatus;
    public drugShipments: DrugShipment[];

    public constructor(init?: Partial<GetDrugShipmentsNotReceivedByExpectedDateResponse>) { (Object as any).assign(this, init); }
}

export class GetOrderFormsResponse
{
    public responseStatus: ResponseStatus;
    public orderForms: OrderForm[];

    public constructor(init?: Partial<GetOrderFormsResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientDrugsResponse
{
    public responseStatus: ResponseStatus;
    public patientDrugs: PatientDrug[];

    public constructor(init?: Partial<GetPatientDrugsResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientDrugsByPatientIdResponse
{
    public responseStatus: ResponseStatus;
    public patientDrugs: PatientDrug[];

    public constructor(init?: Partial<GetPatientDrugsByPatientIdResponse>) { (Object as any).assign(this, init); }
}

export class SetPatientDrugsResponse
{
    public responseStatus: ResponseStatus;
    public patientDrugs: PatientDrug[];

    public constructor(init?: Partial<SetPatientDrugsResponse>) { (Object as any).assign(this, init); }
}

export class GetPharmacistsByInstCodeResponse
{
    public responseStatus: ResponseStatus;
    public pharmacists: IPharmacist[];

    public constructor(init?: Partial<GetPharmacistsByInstCodeResponse>) { (Object as any).assign(this, init); }
}

export class GetPharmacistByIdResponse
{
    public responseStatus: ResponseStatus;
    public pharmacist: IPharmacist;

    public constructor(init?: Partial<GetPharmacistByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetPharmaciesResponse
{
    public responseStatus: ResponseStatus;
    public pharmacies: Pharmacy[];

    public constructor(init?: Partial<GetPharmaciesResponse>) { (Object as any).assign(this, init); }
}

export class GetPharmacyByIdResponse
{
    public responseStatus: ResponseStatus;
    public pharmacy: Pharmacy;

    public constructor(init?: Partial<GetPharmacyByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetPharmacyByInstitutionCodeResponse
{
    public responseStatus: ResponseStatus;
    public pharmacy: Pharmacy;

    public constructor(init?: Partial<GetPharmacyByInstitutionCodeResponse>) { (Object as any).assign(this, init); }
}

export class GetPharmacyBatchesDrugRemainingByBatchIdsResponse
{
    public responseStatus: ResponseStatus;
    public pharmacyBatchesDrugRemaining: PharmacyBatchDrugRemaining[];

    public constructor(init?: Partial<GetPharmacyBatchesDrugRemainingByBatchIdsResponse>) { (Object as any).assign(this, init); }
}

export class SetPharmacyResponse
{
    public responseStatus: ResponseStatus;
    public pharmacy: Pharmacy;

    public constructor(init?: Partial<SetPharmacyResponse>) { (Object as any).assign(this, init); }
}

export class QuarantinePharmacyStockResponse
{
    public responseStatus: ResponseStatus;
    public success: boolean;

    public constructor(init?: Partial<QuarantinePharmacyStockResponse>) { (Object as any).assign(this, init); }
}

export class QuarantinePharmacyDepotBatchResponse
{
    public responseStatus: ResponseStatus;
    public success: boolean;

    public constructor(init?: Partial<QuarantinePharmacyDepotBatchResponse>) { (Object as any).assign(this, init); }
}

export class ActionQuarantinedStockResponse
{
    public responseStatus: ResponseStatus;
    public success: boolean;

    public constructor(init?: Partial<ActionQuarantinedStockResponse>) { (Object as any).assign(this, init); }
}

export class QuarantineRemovePharmacyDepotBatchResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<QuarantineRemovePharmacyDepotBatchResponse>) { (Object as any).assign(this, init); }
}

export class GetTimepointsResponse
{
    public responseStatus: ResponseStatus;
    public timepoints: Timepoint[];

    public constructor(init?: Partial<GetTimepointsResponse>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewSingleByPatientStudyNumberResponse
{
    public responseStatus: ResponseStatus;
    public medicalReview: MedicalReview;

    public constructor(init?: Partial<SaeMedicalReviewSingleByPatientStudyNumberResponse>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewNarrativeSummaryPostSaveResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaeMedicalReviewNarrativeSummaryPostSaveResponse>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewPostSaveResponse
{
    public responseStatus: ResponseStatus;
    public medicalReview: MedicalReview;

    public constructor(init?: Partial<SaeMedicalReviewPostSaveResponse>) { (Object as any).assign(this, init); }
}

export class SaeValidateMedicalReviewResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: SaeFormValidationResult;

    public constructor(init?: Partial<SaeValidateMedicalReviewResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SaeCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved saes.
    */
    // @DataMember(Order=2)
    public saes: Sae<SaeForm,Patient>[];

    public constructor(init?: Partial<SaeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SaeSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved sae.
    */
    // @DataMember(Order=2)
    public sae: Sae<SaeForm, Patient>;

    public constructor(init?: Partial<SaeSingleResponse>) { (Object as any).assign(this, init); }
}

export class SaeGetCollectionByStatusResponse
{
    public responseStatus: ResponseStatus;
    public saes: Sae<ISaeForm,IPatient>[];

    public constructor(init?: Partial<SaeGetCollectionByStatusResponse>) { (Object as any).assign(this, init); }
}

export class SaeWebActionSaveResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaeWebActionSaveResponse>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewWebActionSaveResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaeMedicalReviewWebActionSaveResponse>) { (Object as any).assign(this, init); }
}

export class SaeSendNotificationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaeSendNotificationResponse>) { (Object as any).assign(this, init); }
}

export class SaeValidateNotificationFormResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: SaeFormValidationResult;

    public constructor(init?: Partial<SaeValidateNotificationFormResponse>) { (Object as any).assign(this, init); }
}

export class SaeValidateRejectionFormResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: SaeFormValidationResult;

    public constructor(init?: Partial<SaeValidateRejectionFormResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningSummarySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved screeningsummary.
    */
    // @DataMember(Order=2)
    public screeningSummary: ScreeningSummary;

    public constructor(init?: Partial<ScreeningSummarySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningSummaryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved screeningsummary.
    */
    // @DataMember(Order=2)
    public screeningSummary: ScreeningSummary[];

    public constructor(init?: Partial<ScreeningSummaryCollectionResponse>) { (Object as any).assign(this, init); }
}

export class ScreeningSummaryFormValidationResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: ScreeningSummaryFormValidationResult;

    public constructor(init?: Partial<ScreeningSummaryFormValidationResponse>) { (Object as any).assign(this, init); }
}

export class UploadResponse
{
    public responseStatus: ResponseStatus;
    public upload: IFileUploadTemporary;

    public constructor(init?: Partial<UploadResponse>) { (Object as any).assign(this, init); }
}

export class GetUserResponse
{
    public responseStatus: ResponseStatus;
    public userId: string;
    public sessionId: string;
    public userName: string;
    public displayName: string;
    public type?: UserType;

    public constructor(init?: Partial<GetUserResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved lookups.
    */
    // @DataMember(Order=2)
    public lookups: Lookup[];

    public constructor(init?: Partial<LookupCollectionResponse>) { (Object as any).assign(this, init); }
}

export class GetFormMetadataResponse
{
    public responseStatus: ResponseStatus;
    public formMetadata: FormMetadata[];

    public constructor(init?: Partial<GetFormMetadataResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved event definition.
    */
    // @DataMember(Order=2)
    public eventDefinition: EventDefinition;

    public constructor(init?: Partial<EventDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved event definitions.
    */
    // @DataMember(Order=2)
    public eventDefinitions: EventDefinition[];

    public constructor(init?: Partial<EventDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definition.
    */
    // @DataMember(Order=2)
    public formDefinition: FormDefinition;

    public constructor(init?: Partial<FormDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definitions.
    */
    // @DataMember(Order=2)
    public formDefinitions: FormDefinition[];

    public constructor(init?: Partial<FormDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStateSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient state.
    */
    // @DataMember(Order=2)
    public patientState: PatientState;

    public constructor(init?: Partial<PatientStateSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStateCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient states.
    */
    // @DataMember(Order=2)
    public patientStates: PatientState[];

    public constructor(init?: Partial<PatientStateCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved treatment.
    */
    // @DataMember(Order=2)
    public treatment: Treatment;

    public constructor(init?: Partial<TreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved treatments.
    */
    // @DataMember(Order=2)
    public treatments: Treatment[];

    public constructor(init?: Partial<TreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master group.
    */
    // @DataMember(Order=2)
    public masterGroup: MasterGroup;

    public constructor(init?: Partial<MasterGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master groups.
    */
    // @DataMember(Order=2)
    public masterGroups: MasterGroup[];

    public constructor(init?: Partial<MasterGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CollaboratingGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved collaborating group.
    */
    // @DataMember(Order=2)
    public collaboratingGroup: CollaboratingGroup;

    public constructor(init?: Partial<CollaboratingGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CollaboratingGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved collaborating groups.
    */
    // @DataMember(Order=2)
    public collaboratingGroups: CollaboratingGroup[];

    public constructor(init?: Partial<CollaboratingGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountrySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved country.
    */
    // @DataMember(Order=2)
    public country: Country;

    public constructor(init?: Partial<CountrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved countrys.
    */
    // @DataMember(Order=2)
    public countries: Country[];

    public constructor(init?: Partial<CountryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institution.
    */
    // @DataMember(Order=2)
    public institution: Institution;

    public constructor(init?: Partial<InstitutionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institutions.
    */
    // @DataMember(Order=2)
    public institutions: Institution[];

    public constructor(init?: Partial<InstitutionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient.
    */
    // @DataMember(Order=2)
    public patient: Patient;

    public constructor(init?: Partial<PatientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: PatientValidationResult;

    public constructor(init?: Partial<PatientValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patients.
    */
    // @DataMember(Order=2)
    public patients: Patient[];

    public constructor(init?: Partial<PatientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved event.
    */
    // @DataMember(Order=2)
    public event: Event;

    public constructor(init?: Partial<EventSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved events.
    */
    // @DataMember(Order=2)
    public events: Event[];

    public constructor(init?: Partial<EventCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdditionalEligibilityConfirmationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved additional eligibility confirmation.
    */
    // @DataMember(Order=2)
    public form: AdditionalEligibilityConfirmation;

    public constructor(init?: Partial<AdditionalEligibilityConfirmationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdditionalEligibilityConfirmationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved additional eligibility confirmations.
    */
    // @DataMember(Order=2)
    public forms: AdditionalEligibilityConfirmation[];

    public constructor(init?: Partial<AdditionalEligibilityConfirmationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdditionalEligibilityConfirmationDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AdditionalEligibilityConfirmationDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdditionalEligibilityConfirmationValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<AdditionalEligibilityConfirmationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdditionalImagingSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved additional imaging.
    */
    // @DataMember(Order=2)
    public form: AdditionalImaging;

    public constructor(init?: Partial<AdditionalImagingSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdditionalImagingCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved additional imagings.
    */
    // @DataMember(Order=2)
    public forms: AdditionalImaging[];

    public constructor(init?: Partial<AdditionalImagingCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdditionalImagingDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AdditionalImagingDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdditionalImagingValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<AdditionalImagingValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse event.
    */
    // @DataMember(Order=2)
    public form: AdverseEvent;

    public constructor(init?: Partial<AdverseEventSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse events.
    */
    // @DataMember(Order=2)
    public forms: AdverseEvent[];

    public constructor(init?: Partial<AdverseEventCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AdverseEventDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<AdverseEventValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventFormSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse event form.
    */
    // @DataMember(Order=2)
    public form: AdverseEventForm;

    public constructor(init?: Partial<AdverseEventFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventFormCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved adverse event forms.
    */
    // @DataMember(Order=2)
    public forms: AdverseEventForm[];

    public constructor(init?: Partial<AdverseEventFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventFormDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AdverseEventFormDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdverseEventFormValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<AdverseEventFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BiomarkerSampleSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved biomarker sample.
    */
    // @DataMember(Order=2)
    public form: BiomarkerSample;

    public constructor(init?: Partial<BiomarkerSampleSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BiomarkerSampleCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved biomarker samples.
    */
    // @DataMember(Order=2)
    public forms: BiomarkerSample[];

    public constructor(init?: Partial<BiomarkerSampleCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BiomarkerSampleDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<BiomarkerSampleDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BiomarkerSampleValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<BiomarkerSampleValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BloodsSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved bloods.
    */
    // @DataMember(Order=2)
    public form: Bloods;

    public constructor(init?: Partial<BloodsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BloodsCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved bloodss.
    */
    // @DataMember(Order=2)
    public forms: Bloods[];

    public constructor(init?: Partial<BloodsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BloodsDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<BloodsDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BloodsValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<BloodsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BmNonTargetLesionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved bm non target lesion.
    */
    // @DataMember(Order=2)
    public form: BmNonTargetLesion;

    public constructor(init?: Partial<BmNonTargetLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BmNonTargetLesionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved bm non target lesions.
    */
    // @DataMember(Order=2)
    public forms: BmNonTargetLesion[];

    public constructor(init?: Partial<BmNonTargetLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BmNonTargetLesionDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<BmNonTargetLesionDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BmNonTargetLesionValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<BmNonTargetLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BmTargetLesionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved bm target lesion.
    */
    // @DataMember(Order=2)
    public form: BmTargetLesion;

    public constructor(init?: Partial<BmTargetLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BmTargetLesionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved bm target lesions.
    */
    // @DataMember(Order=2)
    public forms: BmTargetLesion[];

    public constructor(init?: Partial<BmTargetLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BmTargetLesionDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<BmTargetLesionDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BmTargetLesionValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<BmTargetLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CancerDetailsSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved cancer details.
    */
    // @DataMember(Order=2)
    public form: CancerDetails;

    public constructor(init?: Partial<CancerDetailsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CancerDetailsCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved cancer detailss.
    */
    // @DataMember(Order=2)
    public forms: CancerDetails[];

    public constructor(init?: Partial<CancerDetailsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CancerDetailsDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CancerDetailsDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CancerDetailsValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<CancerDetailsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalAssessmentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved clinical assessment.
    */
    // @DataMember(Order=2)
    public form: ClinicalAssessment;

    public constructor(init?: Partial<ClinicalAssessmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalAssessmentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved clinical assessments.
    */
    // @DataMember(Order=2)
    public forms: ClinicalAssessment[];

    public constructor(init?: Partial<ClinicalAssessmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalAssessmentDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ClinicalAssessmentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalAssessmentValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ClinicalAssessmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medication.
    */
    // @DataMember(Order=2)
    public form: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medications.
    */
    // @DataMember(Order=2)
    public forms: ConcomitantMedication[];

    public constructor(init?: Partial<ConcomitantMedicationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ConcomitantMedicationDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationFormSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medication form.
    */
    // @DataMember(Order=2)
    public form: ConcomitantMedicationForm;

    public constructor(init?: Partial<ConcomitantMedicationFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationFormCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medication forms.
    */
    // @DataMember(Order=2)
    public forms: ConcomitantMedicationForm[];

    public constructor(init?: Partial<ConcomitantMedicationFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationFormDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ConcomitantMedicationFormDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationFormValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CtNonTargetLesionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved ct non target lesion.
    */
    // @DataMember(Order=2)
    public form: CtNonTargetLesion;

    public constructor(init?: Partial<CtNonTargetLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CtNonTargetLesionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved ct non target lesions.
    */
    // @DataMember(Order=2)
    public forms: CtNonTargetLesion[];

    public constructor(init?: Partial<CtNonTargetLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CtNonTargetLesionDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CtNonTargetLesionDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CtNonTargetLesionValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<CtNonTargetLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CtTargetLesionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved ct target lesion.
    */
    // @DataMember(Order=2)
    public form: CtTargetLesion;

    public constructor(init?: Partial<CtTargetLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CtTargetLesionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved ct target lesions.
    */
    // @DataMember(Order=2)
    public forms: CtTargetLesion[];

    public constructor(init?: Partial<CtTargetLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CtTargetLesionDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CtTargetLesionDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CtTargetLesionValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<CtTargetLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DemographicDetailsSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved demographic details.
    */
    // @DataMember(Order=2)
    public form: DemographicDetails;

    public constructor(init?: Partial<DemographicDetailsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DemographicDetailsCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved demographic detailss.
    */
    // @DataMember(Order=2)
    public forms: DemographicDetails[];

    public constructor(init?: Partial<DemographicDetailsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DemographicDetailsDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DemographicDetailsDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DemographicDetailsValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<DemographicDetailsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiseaseAssessmentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved disease assessment.
    */
    // @DataMember(Order=2)
    public form: DiseaseAssessment;

    public constructor(init?: Partial<DiseaseAssessmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiseaseAssessmentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved disease assessments.
    */
    // @DataMember(Order=2)
    public forms: DiseaseAssessment[];

    public constructor(init?: Partial<DiseaseAssessmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiseaseAssessmentDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DiseaseAssessmentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiseaseAssessmentValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<DiseaseAssessmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EcgSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved ecg.
    */
    // @DataMember(Order=2)
    public form: Ecg;

    public constructor(init?: Partial<EcgSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EcgCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved ecgs.
    */
    // @DataMember(Order=2)
    public forms: Ecg[];

    public constructor(init?: Partial<EcgCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EcgDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<EcgDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EcgValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<EcgValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityConfirmationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved eligibility confirmation.
    */
    // @DataMember(Order=2)
    public form: EligibilityConfirmation;

    public constructor(init?: Partial<EligibilityConfirmationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityConfirmationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved eligibility confirmations.
    */
    // @DataMember(Order=2)
    public forms: EligibilityConfirmation[];

    public constructor(init?: Partial<EligibilityConfirmationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityConfirmationDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<EligibilityConfirmationDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityConfirmationValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<EligibilityConfirmationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved general.
    */
    // @DataMember(Order=2)
    public form: General;

    public constructor(init?: Partial<GeneralSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved generals.
    */
    // @DataMember(Order=2)
    public forms: General[];

    public constructor(init?: Partial<GeneralCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GeneralDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<GeneralValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistorySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical history.
    */
    // @DataMember(Order=2)
    public form: MedicalHistory;

    public constructor(init?: Partial<MedicalHistorySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical historys.
    */
    // @DataMember(Order=2)
    public forms: MedicalHistory[];

    public constructor(init?: Partial<MedicalHistoryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<MedicalHistoryDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<MedicalHistoryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved source document.
    */
    // @DataMember(Order=2)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved source documents.
    */
    // @DataMember(Order=2)
    public forms: SourceDocument[];

    public constructor(init?: Partial<SourceDocumentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SourceDocumentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<SourceDocumentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentFormSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved source document form.
    */
    // @DataMember(Order=2)
    public form: SourceDocumentForm;

    public constructor(init?: Partial<SourceDocumentFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentFormCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved source document forms.
    */
    // @DataMember(Order=2)
    public forms: SourceDocumentForm[];

    public constructor(init?: Partial<SourceDocumentFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentFormDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SourceDocumentFormDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentFormValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<SourceDocumentFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UrinalysisSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved urinalysis.
    */
    // @DataMember(Order=2)
    public form: Urinalysis;

    public constructor(init?: Partial<UrinalysisSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UrinalysisCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved urinalysiss.
    */
    // @DataMember(Order=2)
    public forms: Urinalysis[];

    public constructor(init?: Partial<UrinalysisCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UrinalysisDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UrinalysisDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UrinalysisValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<UrinalysisValidationResponse>) { (Object as any).assign(this, init); }
}

export class InstitutionPersonnelNamesResponse
{
    public responseStatus: ResponseStatus;
    public personnelNames: { [index: number]: string; };

    public constructor(init?: Partial<InstitutionPersonnelNamesResponse>) { (Object as any).assign(this, init); }
}

export class GetUserNamesResponse
{
    public responseStatus: ResponseStatus;
    public personnelNames: { [index: number]: string; };

    public constructor(init?: Partial<GetUserNamesResponse>) { (Object as any).assign(this, init); }
}

export class HasOpmsPermissionCollectionResponse
{
    public responseStatus: ResponseStatus;
    public hasPermission: { [index: number]: boolean; };

    public constructor(init?: Partial<HasOpmsPermissionCollectionResponse>) { (Object as any).assign(this, init); }
}

export class SendPatientIneligibleEmail implements IReturn<SendPatientIneligibleEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendPatientIneligibleEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPatientIneligibleEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPatientIneligibleEmailResponse(); }
}

export class SendPatientSubmittedForReviewEmail implements IReturn<SendPatientSubmittedForReviewEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendPatientSubmittedForReviewEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPatientSubmittedForReviewEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPatientSubmittedForReviewEmailResponse(); }
}

export class SendPatientReassignedToSiteEmail implements IReturn<SendPatientReassignedToSiteEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendPatientReassignedToSiteEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPatientReassignedToSiteEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPatientReassignedToSiteEmailResponse(); }
}

export class SendRegistrationEmail implements IReturn<SendRegistrationEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendRegistrationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendRegistrationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendRegistrationEmailResponse(); }
}

export class QueuePatientOcTransfer implements IReturn<QueuePatientOcTransferResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<QueuePatientOcTransfer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueuePatientOcTransfer'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueuePatientOcTransferResponse(); }
}

export class QueuePatientsOcTransfer implements IReturn<QueuePatientsOcTransferResponse>
{

    public constructor(init?: Partial<QueuePatientsOcTransfer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueuePatientsOcTransfer'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueuePatientsOcTransferResponse(); }
}

export class SendSaeNotifyInvestigatorEmail implements IReturn<SendSaeNotifyInvestigatorEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;
    public initialEmail: boolean;
    public recipientIds: number[];
    public ccRecipientIds: number[];
    public attachmentNames: string[];
    public attachmentGuids: string[];
    public includedNotes: string;

    public constructor(init?: Partial<SendSaeNotifyInvestigatorEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeNotifyInvestigatorEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeNotifyInvestigatorEmailResponse(); }
}

export class SendSaeInvestigatorReviewEmail implements IReturn<SendSaeInvestigatorReviewEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;
    public approved: boolean;
    public reasonForRejection: string;
    public reviewer: string;

    public constructor(init?: Partial<SendSaeInvestigatorReviewEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeInvestigatorReviewEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeInvestigatorReviewEmailResponse(); }
}

export class SendNewSaeEmail implements IReturn<SendNewSaeEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;
    public initialEmail: boolean;

    public constructor(init?: Partial<SendNewSaeEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendNewSaeEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendNewSaeEmailResponse(); }
}

export class SendUnapprovedSaeEmail implements IReturn<SendUnapprovedSaeEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;

    public constructor(init?: Partial<SendUnapprovedSaeEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendUnapprovedSaeEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendUnapprovedSaeEmailResponse(); }
}

export class SendUnresolvedSaeEmail implements IReturn<SendUnresolvedSaeEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;

    public constructor(init?: Partial<SendUnresolvedSaeEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendUnresolvedSaeEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendUnresolvedSaeEmailResponse(); }
}

export class SendDrugShipmentOrderedEmail implements IReturn<SendDrugShipmentOrderedEmailResponse>
{
    public drugShipmentId: number;

    public constructor(init?: Partial<SendDrugShipmentOrderedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDrugShipmentOrderedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendDrugShipmentOrderedEmailResponse(); }
}

export class SendDrugShipmentGenerationEmail implements IReturn<SendDrugShipmentGenerationEmailResponse>
{
    public drugShipmentIds: number[];

    public constructor(init?: Partial<SendDrugShipmentGenerationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDrugShipmentGenerationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendDrugShipmentGenerationEmailResponse(); }
}

export class SendStagnantDrugOrdersEmail implements IReturn<SendStagnantDrugOrdersEmailResponse>
{
    public drugShipmentIds: number[];

    public constructor(init?: Partial<SendStagnantDrugOrdersEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendStagnantDrugOrdersEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendStagnantDrugOrdersEmailResponse(); }
}

export class SendStagnantDrugShipmentsEmail implements IReturn<SendStagnantDrugShipmentsEmailResponse>
{
    public drugShipmentIds: number[];

    public constructor(init?: Partial<SendStagnantDrugShipmentsEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendStagnantDrugShipmentsEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendStagnantDrugShipmentsEmailResponse(); }
}

export class SendExpiringStockEmail implements IReturn<SendExpiringStockEmailResponse>
{
    public batchIds: number[];

    public constructor(init?: Partial<SendExpiringStockEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendExpiringStockEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendExpiringStockEmailResponse(); }
}

export class SendLowStockEmail implements IReturn<SendLowStockEmailResponse>
{
    public depotIds: number[];
    public drugThreshold: number;

    public constructor(init?: Partial<SendLowStockEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendLowStockEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendLowStockEmailResponse(); }
}

export class SendLateDispensationEmail implements IReturn<SendLateDispensationEmailResponse>
{
    public patientId: number;

    public constructor(init?: Partial<SendLateDispensationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendLateDispensationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendLateDispensationEmailResponse(); }
}

export class SendDrugDispensationEmail implements IReturn<SendDrugDispensationEmailResponse>
{
    public drugDispensationIds: number[];

    public constructor(init?: Partial<SendDrugDispensationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDrugDispensationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendDrugDispensationEmailResponse(); }
}

export class SendDrugShipmentQuarantinedEmail implements IReturn<SendDrugShipmentQuarantinedEmailResponse>
{
    public drugShipmentId: number;

    public constructor(init?: Partial<SendDrugShipmentQuarantinedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDrugShipmentQuarantinedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendDrugShipmentQuarantinedEmailResponse(); }
}

export class SendDrugShipmentReceivedEmail implements IReturn<SendDrugShipmentReceivedEmailResponse>
{
    public drugShipmentId: number;

    public constructor(init?: Partial<SendDrugShipmentReceivedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDrugShipmentReceivedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendDrugShipmentReceivedEmailResponse(); }
}

export class SendDrugShipmentExpiringEmail implements IReturn<SendDrugShipmentExpiringEmailResponse>
{

    public constructor(init?: Partial<SendDrugShipmentExpiringEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDrugShipmentExpiringEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendDrugShipmentExpiringEmailResponse(); }
}

export class SendDrugShipmentDispatchedEmail implements IReturn<SendDrugShipmentDispatchedEmailResponse>
{
    public drugShipmentId: number;

    public constructor(init?: Partial<SendDrugShipmentDispatchedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDrugShipmentDispatchedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendDrugShipmentDispatchedEmailResponse(); }
}

// @Route("/print/screeningsummary/{Ineligible}/{Unsuitable}/{Declined}/{InstitutionCode}/{DownloadFormat}/", "GET")
export class GetScreeningSummaryReport
{
    public ineligible: boolean;
    public unsuitable: boolean;
    public declined: boolean;
    public institutionCode: string;
    public downloadFormat: string;

    public constructor(init?: Partial<GetScreeningSummaryReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetScreeningSummaryReport'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

export class GetDrugShipmentDepotBatchesByIds implements IReturn<GetDrugShipmentDepotBatchesByIdsResponse>
{
    public ids: number[];

    public constructor(init?: Partial<GetDrugShipmentDepotBatchesByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentDepotBatchesByIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDrugShipmentDepotBatchesByIdsResponse(); }
}

// @Route("/api/reports/drug-shipment/order-form", "GET,POST")
export class GetDrugShipmentOrderFormData implements IReturn<GetDrugShipmentOrderFormDataResponse>, IReportRequest
{
    public token: string;
    public drugShipmentId: string;

    public constructor(init?: Partial<GetDrugShipmentOrderFormData>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentOrderFormData'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDrugShipmentOrderFormDataResponse(); }
}

// @Route("/api/reports/drug-shipment/order-form-drug-batches", "GET,POST")
export class GetDrugShipmentOrderFormDrugBatches implements IReturn<GetDrugShipmentOrderFormDrugBatchesResponse>, IReportRequest
{
    public token: string;
    public drugShipmentId: string;

    public constructor(init?: Partial<GetDrugShipmentOrderFormDrugBatches>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentOrderFormDrugBatches'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDrugShipmentOrderFormDrugBatchesResponse(); }
}

// @Route("/api/reports/ineligible", "GET,POST")
export class GetIneligibleReportData implements IReturn<GetIneligibleReportDataResponse>
{
    public institutionId: number;

    public constructor(init?: Partial<GetIneligibleReportData>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIneligibleReportData'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetIneligibleReportDataResponse(); }
}

export class GetEnumerationTypes implements IReturn<GetEnumerationTypesResponse>
{

    public constructor(init?: Partial<GetEnumerationTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEnumerationTypes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEnumerationTypesResponse(); }
}

// @Route("/openiddict/authorized")
export class IdentityServerAuthorized implements IReturn<IdentityServerDiagnosticsResponse>
{

    public constructor(init?: Partial<IdentityServerAuthorized>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'IdentityServerAuthorized'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IdentityServerDiagnosticsResponse(); }
}

// @Route("/openiddict/test")
export class IdentityServerDiagnostics implements IReturn<IdentityServerDiagnosticsResponse>
{
    public code: string;
    public state: string;
    public error: string;

    public constructor(init?: Partial<IdentityServerDiagnostics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'IdentityServerDiagnostics'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IdentityServerDiagnosticsResponse(); }
}

/**
* Sign In
*/
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/assignroles", "POST")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AssignRolesResponse(); }
}

// @Route("/unassignroles", "POST")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnAssignRolesResponse(); }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApiKeys'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetApiKeysResponse(); }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegenerateApiKeys'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegenerateApiKeysResponse(); }
}

// @Route("/drug-management-api/batches", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetBatches implements IReturn<GetBatchesResponse>
{

    public constructor(init?: Partial<GetBatches>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBatches'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetBatchesResponse(); }
}

// @Route("/drug-management-api/batch/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetBatchById implements IReturn<GetBatchByIdResponse>
{
    /**
    * The id of the drug batch
    */
    // @ApiMember(DataType="int", Description="The id of the drug batch", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetBatchById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBatchById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetBatchByIdResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetBatchesByIds implements IReturn<GetBatchesByIdsResponse>, IGet
{
    public ids: number[];

    public constructor(init?: Partial<GetBatchesByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBatchesByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetBatchesByIdsResponse(); }
}

// @Route("/drug-management-api/batches/expiring-at-specified-date", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetBatchesExpiringAtSpecifiedDate implements IReturn<GetBatchesExpiringAtSpecifiedDateResponse>, IGet
{
    /**
    * The date of expiry
    */
    // @ApiMember(DataType="DateTime", Description="The date of expiry", IsRequired=true, Name="ExpiryDate", ParameterType="query")
    public expiryDate: string;

    public constructor(init?: Partial<GetBatchesExpiringAtSpecifiedDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBatchesExpiringAtSpecifiedDate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetBatchesExpiringAtSpecifiedDateResponse(); }
}

// @Route("/drug-management-api/batch/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetBatch implements IReturn<SetBatchResponse>
{
    /**
    * The drug batch form data to save
    */
    // @ApiMember(DataType="BatchForm", Description="The drug batch form data to save", IsRequired=true, Name="BatchForm", ParameterType="body")
    public batch: Batch;

    public constructor(init?: Partial<SetBatch>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetBatch'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetBatchResponse(); }
}

// @Route("/drug-management-api/depot-batches", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatches implements IReturn<GetDepotBatchesResponse>
{

    public constructor(init?: Partial<GetDepotBatches>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatches'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesResponse(); }
}

// @Route("/drug-management-api/depot-batch/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchById implements IReturn<GetDepotBatchByIdResponse>
{
    /**
    * The id of the depot batch
    */
    // @ApiMember(DataType="int", Description="The id of the depot batch", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDepotBatchById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchByIdResponse(); }
}

// @Route("/drug-management-api/depot-batches/depotId/{DepotId}", "GET")
// @ApiResponse(Description="Depot Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchesByDepotId implements IReturn<GetDepotBatchesByDepotIdResponse>
{
    /**
    * The id of the depot
    */
    // @ApiMember(DataType="int", Description="The id of the depot", IsRequired=true, Name="DepotId", ParameterType="path")
    public depotId: number;

    public constructor(init?: Partial<GetDepotBatchesByDepotId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchesByDepotId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesByDepotIdResponse(); }
}

// @Route("/drug-management-api/depot-batches/pharmacyId/{PharmacyId}", "GET")
// @ApiResponse(Description="Pharmacy Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchesByPharmacyId implements IReturn<GetDepotBatchesByPharmacyIdResponse>
{
    /**
    * The id of the pharmacy
    */
    // @ApiMember(DataType="int", Description="The id of the pharmacy", IsRequired=true, Name="PharmacyId", ParameterType="path")
    public pharmacyId: number;

    public constructor(init?: Partial<GetDepotBatchesByPharmacyId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchesByPharmacyId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesByPharmacyIdResponse(); }
}

// @Route("/drug-management-api/depot-batches/drugShipmentId/{DrugShipmentId}", "GET")
// @ApiResponse(Description="Drug Shipment Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchesByDrugShipmentId implements IReturn<GetDepotBatchesByDrugShipmentIdResponse>
{
    /**
    * The id of the drug shipment
    */
    // @ApiMember(DataType="int", Description="The id of the drug shipment", IsRequired=true, Name="DrugShipmentId", ParameterType="path")
    public drugShipmentId: number;

    public constructor(init?: Partial<GetDepotBatchesByDrugShipmentId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchesByDrugShipmentId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesByDrugShipmentIdResponse(); }
}

// @Route("/drug-management-api/depot-batches/institution/{InstCode}", "GET")
// @ApiResponse(Description="Institution code invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchesByInstitutionCode implements IReturn<GetDepotBatchesByPharmacyIdResponse>
{
    /**
    * The institution code
    */
    // @ApiMember(DataType="string", Description="The institution code", IsRequired=true, Name="InstCode", ParameterType="path")
    public instCode: string;

    public constructor(init?: Partial<GetDepotBatchesByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchesByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesByPharmacyIdResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchesByBatchIds implements IReturn<GetDepotBatchesByBatchIdsResponse>, IGet
{
    public batchIds: number[];

    public constructor(init?: Partial<GetDepotBatchesByBatchIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchesByBatchIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesByBatchIdsResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchesWithLowStockByDepotIds implements IReturn<GetDepotBatchesWithLowStockByDepotIdsResponse>, IGet
{
    public depotIds: number[];
    public drugThreshold?: number;

    public constructor(init?: Partial<GetDepotBatchesWithLowStockByDepotIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchesWithLowStockByDepotIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesWithLowStockByDepotIdsResponse(); }
}

// @Route("/drug-management-api/depot-batch/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDepotBatch implements IReturn<SetDepotBatchResponse>
{
    /**
    * The depot batch form data to save
    */
    // @ApiMember(DataType="DepotBatch", Description="The depot batch form data to save", IsRequired=true, Name="DepotBatch", ParameterType="body")
    public depotBatch: DepotBatch;

    public constructor(init?: Partial<SetDepotBatch>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDepotBatch'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDepotBatchResponse(); }
}

// @Route("/drug-management-api/depots", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepots implements IReturn<GetDepotsResponse>, IGet
{

    public constructor(init?: Partial<GetDepots>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepots'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotsResponse(); }
}

// @Route("/drug-management-api/depot/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotById implements IReturn<GetDepotByIdResponse>, IGet
{
    /**
    * The id of the drug depot
    */
    // @ApiMember(DataType="int", Description="The id of the drug depot", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDepotById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotByIdResponse(); }
}

// @Route("/drug-management-api/depots/by-drug-under-threshold", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotsByDrugUnderThreshold implements IReturn<GetDepotsByDrugUnderThresholdResponse>, IGet
{
    /**
    * The threshold to check
    */
    // @ApiMember(DataType="int", Description="The threshold to check", IsRequired=true, Name="DrugThreshold", ParameterType="query")
    public drugThreshold: number;

    public constructor(init?: Partial<GetDepotsByDrugUnderThreshold>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotsByDrugUnderThreshold'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotsByDrugUnderThresholdResponse(); }
}

// @Route("/drug-management-api/depot/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDepot implements IReturn<SetDepotResponse>
{
    /**
    * The drug depot form data to save
    */
    // @ApiMember(DataType="DepotForm", Description="The drug depot form data to save", IsRequired=true, Name="DepotForm", ParameterType="body")
    public depot: Depot;

    public constructor(init?: Partial<SetDepot>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDepot'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDepotResponse(); }
}

// @Route("/drug-management-api/drug-dispensations", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugDispensations implements IReturn<GetDrugDispensationsResponse>, IGet
{

    public constructor(init?: Partial<GetDrugDispensations>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugDispensations'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugDispensationsResponse(); }
}

// @Route("/drug-management-api/drug-dispensation/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugDispensationById implements IReturn<GetDrugDispensationByIdResponse>, IGet
{
    /**
    * The id of the drug dispensation
    */
    // @ApiMember(DataType="int", Description="The id of the drug dispensation", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDrugDispensationById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugDispensationById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugDispensationByIdResponse(); }
}

// @Route("/drug-management-api/drug-dispensations/patient-id/{PatientId}", "GET")
// @ApiResponse(Description="Patient id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugDispensationByPatientId implements IReturn<GetDrugDispensationByPatientIdResponse>, IGet
{
    /**
    * The id of the patient to retrieve drug dispensations for
    */
    // @ApiMember(DataType="int", Description="The id of the patient to retrieve drug dispensations for", IsRequired=true, Name="PatientId", ParameterType="path")
    public patientId: number;

    public constructor(init?: Partial<GetDrugDispensationByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugDispensationByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugDispensationByPatientIdResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatientsWithLateDispensations implements IReturn<GetPatientsWithLateDispensationsResponse>, IGet
{
    public expectedDate?: string;

    public constructor(init?: Partial<GetPatientsWithLateDispensations>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatientsWithLateDispensations'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPatientsWithLateDispensationsResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetLastDispensationForPatientId implements IReturn<GetLastDispensationForPatientIdResponse>, IGet
{
    public patientId?: number;

    public constructor(init?: Partial<GetLastDispensationForPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLastDispensationForPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetLastDispensationForPatientIdResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugDispensationsByIds implements IReturn<GetDrugDispensationsByIdsResponse>, IGet
{
    public ids: number[];

    public constructor(init?: Partial<GetDrugDispensationsByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugDispensationsByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugDispensationsByIdsResponse(); }
}

// @Route("/drug-management-api/drug-dispensation/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDrugDispensation implements IReturn<SetDrugDispensationResponse>, IPost
{
    /**
    * The patient dispensation form data to save
    */
    // @ApiMember(DataType="DrugDispensation", Description="The patient dispensation form data to save", IsRequired=true, Name="DrugDispensation", ParameterType="body")
    public drugDispensation: DrugDispensation;

    public constructor(init?: Partial<SetDrugDispensation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDrugDispensation'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDrugDispensationResponse(); }
}

export class DeleteDrugDispensation implements IReturn<DeleteDrugDispensationResponse>
{
    /**
    * The drug dispensation we want to delete
    */
    // @ApiMember(DataType="DrugDispensation", Description="The drug dispensation we want to delete", IsRequired=true, Name="DrugDispensation", ParameterType="body")
    public drugDispensation: DrugDispensation;

    public constructor(init?: Partial<DeleteDrugDispensation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteDrugDispensation'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeleteDrugDispensationResponse(); }
}

// @Route("/drug-management-api/drug-dispensation/validate", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class ValidateDrugDispensation implements IReturn<ValidateDrugDispensationResponse>, IPost
{
    /**
    * The drug dispensation form data to validate
    */
    // @ApiMember(DataType="model", Description="The drug dispensation form data to validate", IsRequired=true, Name="DrugDispensation", ParameterType="body")
    public drugDispensation: DrugDispensation;

    public constructor(init?: Partial<ValidateDrugDispensation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ValidateDrugDispensation'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ValidateDrugDispensationResponse(); }
}

// @Route("/drug-management-api/drugs", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugs implements IReturn<GetDrugsResponse>
{

    public constructor(init?: Partial<GetDrugs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugsResponse(); }
}

// @Route("/drug-management-api/drug-shipment-depot-batches", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentDepotBatches implements IReturn<GetDrugShipmentDepotBatchesResponse>
{

    public constructor(init?: Partial<GetDrugShipmentDepotBatches>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentDepotBatches'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentDepotBatchesResponse(); }
}

// @Route("/drug-management-api/drug-shipment-depot-batch/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentDepotBatchById implements IReturn<GetDrugShipmentDepotBatchByIdResponse>
{
    /**
    * The id of the drug shipment depot batch
    */
    // @ApiMember(DataType="int", Description="The id of the drug shipment depot batch", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDrugShipmentDepotBatchById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentDepotBatchById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentDepotBatchByIdResponse(); }
}

// @Route("/drug-management-api/drug-shipment-depot-batches/pharmacyId/{PharmacyId}", "GET")
// @ApiResponse(Description="Pharmacy Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentDepotBatchesByPharmacyId implements IReturn<GetDrugShipmentDepotBatchesByPharmacyIdResponse>
{
    /**
    * The id of the pharmacy
    */
    // @ApiMember(DataType="int", Description="The id of the pharmacy", IsRequired=true, Name="PharmacyId", ParameterType="path")
    public pharmacyId: number;

    public constructor(init?: Partial<GetDrugShipmentDepotBatchesByPharmacyId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentDepotBatchesByPharmacyId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentDepotBatchesByPharmacyIdResponse(); }
}

// @Route("/drug-management-api/drug-shipment-depot-batches/institution/{InstCode}", "GET")
// @ApiResponse(Description="Institution code invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentDepotBatchesByInstitutionCode implements IReturn<GetDrugShipmentDepotBatchesByPharmacyIdResponse>
{
    /**
    * The institution code
    */
    // @ApiMember(DataType="string", Description="The institution code", IsRequired=true, Name="InstCode", ParameterType="path")
    public instCode: string;

    public constructor(init?: Partial<GetDrugShipmentDepotBatchesByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentDepotBatchesByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentDepotBatchesByPharmacyIdResponse(); }
}

// @Route("/drug-management-api/drug-shipment-depot-batch/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDrugShipmentDepotBatch implements IReturn<SetDrugShipmentDepotBatchResponse>
{
    /**
    * The drug shipment depot batch form data to save
    */
    // @ApiMember(DataType="DrugShipmentDepotBatch", Description="The drug shipment depot batch form data to save", IsRequired=true, Name="DrugShipmentDepotBatch", ParameterType="body")
    public drugShipmentDepotBatch: DrugShipmentDepotBatch;

    public constructor(init?: Partial<SetDrugShipmentDepotBatch>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDrugShipmentDepotBatch'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDrugShipmentDepotBatchResponse(); }
}

// @Route("/drug-management-api/drug-shipment-generation/queue", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class StartDrugShipmentGeneration implements IReturn<StartDrugShipmentGenerationResponse>
{

    public constructor(init?: Partial<StartDrugShipmentGeneration>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StartDrugShipmentGeneration'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new StartDrugShipmentGenerationResponse(); }
}

// @Route("/drug-management-api/drug-shipment-generation/create-from-patient", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateDrugShipmentFromPatient implements IReturn<CreateDrugShipmentFromPatientResponse>, IPost
{
    /**
    * The id of the patient
    */
    // @ApiMember(DataType="int", Description="The id of the patient", IsRequired=true, Name="PatientId", ParameterType="body")
    public patientId: number;

    /**
    * The drugs to order for the patient
    */
    // @ApiMember(DataType="model", Description="The drugs to order for the patient", IsRequired=true, Name="Drugs", ParameterType="body")
    public drugs: DrugAmount[];

    public constructor(init?: Partial<CreateDrugShipmentFromPatient>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateDrugShipmentFromPatient'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreateDrugShipmentFromPatientResponse(); }
}

// @Route("/drug-management-api/drug-shipments", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipments implements IReturn<GetDrugShipmentsResponse>, IGet
{

    public constructor(init?: Partial<GetDrugShipments>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipments'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentsResponse(); }
}

// @Route("/drug-management-api/drug-shipments/institution/{InstitutionCode}", "GET")
// @ApiResponse(Description="Institution code invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentsByInstitution implements IReturn<GetDrugShipmentsByInstitutionResponse>, IGet
{
    /**
    * The institution of the pharmacies
    */
    // @ApiMember(DataType="string", Description="The institution of the pharmacies", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    public constructor(init?: Partial<GetDrugShipmentsByInstitution>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentsByInstitution'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentsByInstitutionResponse(); }
}

// @Route("/drug-management-api/drug-shipment/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentById implements IReturn<GetDrugShipmentByIdResponse>, IGet
{
    /**
    * The id of the drug shipment
    */
    // @ApiMember(DataType="int", Description="The id of the drug shipment", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDrugShipmentById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentByIdResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentsByIds implements IReturn<GetDrugShipmentsByIdsResponse>, IGet
{
    public ids: number[];

    public constructor(init?: Partial<GetDrugShipmentsByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentsByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentsByIdsResponse(); }
}

// @Route("/drug-management-api/drug-shipment/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDrugShipment implements IReturn<SetDrugShipmentResponse>, IPost
{
    /**
    * The drug shipment form data to save
    */
    // @ApiMember(DataType="DrugShipmentForm", Description="The drug shipment form data to save", IsRequired=true, Name="DrugShipmentForm", ParameterType="body")
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<SetDrugShipment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDrugShipment'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDrugShipmentResponse(); }
}

// @Route("/drug-management-api/drug-shipment/validate", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class ValidateDrugShipment implements IReturn<ValidateDrugShipmentResponse>, IPost
{
    /**
    * The drug shipment form data to validate
    */
    // @ApiMember(DataType="DrugShipmentForm", Description="The drug shipment form data to validate", IsRequired=true, Name="DrugShipmentForm", ParameterType="body")
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<ValidateDrugShipment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ValidateDrugShipment'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ValidateDrugShipmentResponse(); }
}

// @Route("/drug-management-api/drug-shipment/set-status", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDrugShipmentStatus implements IReturn<SetDrugShipmentStatusResponse>, IPost
{
    /**
    * The drug shipment form data to save
    */
    // @ApiMember(DataType="DrugShipmentForm", Description="The drug shipment form data to save", IsRequired=true, Name="DrugShipmentForm", ParameterType="body")
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<SetDrugShipmentStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDrugShipmentStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDrugShipmentStatusResponse(); }
}

// @Route("/drug-management-api/drug-shipment/delete", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class DeleteDrugShipment implements IReturn<DeleteDrugShipmentResponse>, IPost
{
    /**
    * The drug shipment id to delete
    */
    // @ApiMember(DataType="int", Description="The drug shipment id to delete", IsRequired=true, Name="DrugShipmentId", ParameterType="body")
    public drugShipmentId: number;

    public constructor(init?: Partial<DeleteDrugShipment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteDrugShipment'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeleteDrugShipmentResponse(); }
}

// @Route("/drug-management-api/drug-shipment/verify", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDrugShipmentVerify implements IReturn<SetDrugShipmentVerifyResponse>, IPost
{
    /**
    * The drug shipment form data to verify
    */
    // @ApiMember(DataType="model", Description="The drug shipment form data to verify", IsRequired=true, Name="DrugShipmentVerify", ParameterType="body")
    public drugShipmentVerify: DrugShipmentVerify;

    public constructor(init?: Partial<SetDrugShipmentVerify>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDrugShipmentVerify'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDrugShipmentVerifyResponse(); }
}

// @Route("/drug-management-api/drug-shipments/not-shipped-by-expected-date", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentsNotShippedByExpectedDate implements IReturn<GetDrugShipmentsNotShippedByExpectedDateResponse>, IGet
{
    /**
    * The date to check from
    */
    // @ApiMember(DataType="DateTime", Description="The date to check from", IsRequired=true, Name="ExpectedShippingDate", ParameterType="query")
    public expectedShippingDate: string;

    public constructor(init?: Partial<GetDrugShipmentsNotShippedByExpectedDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentsNotShippedByExpectedDate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentsNotShippedByExpectedDateResponse(); }
}

// @Route("/drug-management-api/drug-shipments/not-received-by-expected-date", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentsNotReceivedByExpectedDate implements IReturn<GetDrugShipmentsNotReceivedByExpectedDateResponse>, IGet
{
    /**
    * The date to check from
    */
    // @ApiMember(DataType="DateTime", Description="The date to check from", IsRequired=true, Name="ExpectedReceptionDate", ParameterType="query")
    public expectedReceptionDate: string;

    public constructor(init?: Partial<GetDrugShipmentsNotReceivedByExpectedDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentsNotReceivedByExpectedDate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentsNotReceivedByExpectedDateResponse(); }
}

// @Route("/drug-management-api/drug-shipment-order-form/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentOrderFormById implements IReturn<Blob>, IGet
{
    /**
    * The id of the drug shipment
    */
    // @ApiMember(DataType="int", Description="The id of the drug shipment", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDrugShipmentOrderFormById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentOrderFormById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/drug-management-api/order-forms", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetOrderForms implements IReturn<GetOrderFormsResponse>
{

    public constructor(init?: Partial<GetOrderForms>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOrderForms'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetOrderFormsResponse(); }
}

// @Route("/drug-management-api/patient-drugs", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatientDrugs implements IReturn<GetPatientDrugsResponse>, IGet
{

    public constructor(init?: Partial<GetPatientDrugs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatientDrugs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPatientDrugsResponse(); }
}

// @Route("/drug-management-api/patient-drugs/patient-id/{PatientId}", "GET")
// @ApiResponse(Description="Patient id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatientDrugsByPatientId implements IReturn<GetPatientDrugsByPatientIdResponse>, IGet
{
    /**
    * The id of the patient to retrieve patient drugs for
    */
    // @ApiMember(DataType="int", Description="The id of the patient to retrieve patient drugs for", IsRequired=true, Name="PatientId", ParameterType="path")
    public patientId: number;

    public constructor(init?: Partial<GetPatientDrugsByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatientDrugsByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPatientDrugsByPatientIdResponse(); }
}

// @Route("/drug-management-api/patient-drugs/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetPatientDrugs implements IReturn<SetPatientDrugsResponse>, IPost
{
    /**
    * The patient drug form data to save
    */
    // @ApiMember(DataType="model", Description="The patient drug form data to save", IsRequired=true, Name="PatientDrugs", ParameterType="body")
    public patientDrugs: PatientDrug[];

    public constructor(init?: Partial<SetPatientDrugs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetPatientDrugs'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetPatientDrugsResponse(); }
}

export class GetPharmacistsByInstCode implements IReturn<GetPharmacistsByInstCodeResponse>, IGet
{
    public instCode: string;

    public constructor(init?: Partial<GetPharmacistsByInstCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPharmacistsByInstCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPharmacistsByInstCodeResponse(); }
}

export class GetPharmacistById implements IReturn<GetPharmacistByIdResponse>, IGet
{
    public id?: number;

    public constructor(init?: Partial<GetPharmacistById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPharmacistById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPharmacistByIdResponse(); }
}

// @Route("/drug-management-api/pharmacies", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPharmacies implements IReturn<GetPharmaciesResponse>, IGet
{

    public constructor(init?: Partial<GetPharmacies>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPharmacies'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPharmaciesResponse(); }
}

// @Route("/drug-management-api/pharmacy/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPharmacyById implements IReturn<GetPharmacyByIdResponse>, IGet
{
    /**
    * The id of the pharmacy
    */
    // @ApiMember(DataType="int", Description="The id of the pharmacy", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetPharmacyById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPharmacyById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPharmacyByIdResponse(); }
}

// @Route("/drug-management-api/pharmacy/institution/{InstitutionCode}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPharmacyByInstitutionCode implements IReturn<GetPharmacyByInstitutionCodeResponse>, IGet
{
    /**
    * The institution code of the pharmacy
    */
    // @ApiMember(DataType="string", Description="The institution code of the pharmacy", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    public constructor(init?: Partial<GetPharmacyByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPharmacyByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPharmacyByInstitutionCodeResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPharmacyBatchesDrugRemainingByBatchIds implements IReturn<GetPharmacyBatchesDrugRemainingByBatchIdsResponse>, IGet
{
    public batchIds: number[];

    public constructor(init?: Partial<GetPharmacyBatchesDrugRemainingByBatchIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPharmacyBatchesDrugRemainingByBatchIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPharmacyBatchesDrugRemainingByBatchIdsResponse(); }
}

// @Route("/drug-management-api/pharmacy/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetPharmacy implements IReturn<SetPharmacyResponse>, IPost
{
    /**
    * The pharmacy form data to save
    */
    // @ApiMember(DataType="model", Description="The pharmacy form data to save", IsRequired=true, Name="PharmacyForm", ParameterType="body")
    public pharmacy: Pharmacy;

    public constructor(init?: Partial<SetPharmacy>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetPharmacy'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetPharmacyResponse(); }
}

// @Route("/drug-management-api/pharmacy/quarantine-stock", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class QuarantinePharmacyStock implements IReturn<QuarantinePharmacyStockResponse>, IPost
{
    /**
    * The pharmacy id to quarantine
    */
    // @ApiMember(DataType="integer", Description="The pharmacy id to quarantine", IsRequired=true, Name="Id", ParameterType="body")
    public id: number;

    public constructor(init?: Partial<QuarantinePharmacyStock>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QuarantinePharmacyStock'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QuarantinePharmacyStockResponse(); }
}

// @Route("/drug-management-api/pharmacy/quarantine-depot-batches", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class QuarantinePharmacyDepotBatch implements IReturn<QuarantinePharmacyDepotBatchResponse>, IPost
{
    /**
    * The pharmacy depot batch ids to quarantine
    */
    // @ApiMember(DataType="integer[]", Description="The pharmacy depot batch ids to quarantine", IsRequired=true, Name="Ids", ParameterType="body")
    public ids: number[];

    public constructor(init?: Partial<QuarantinePharmacyDepotBatch>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QuarantinePharmacyDepotBatch'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QuarantinePharmacyDepotBatchResponse(); }
}

// @Route("/drug-management-api/pharmacy/action-quarantined-stock", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class ActionQuarantinedStock implements IReturn<ActionQuarantinedStockResponse>, IPost
{
    /**
    * The pharmacy id to quarantine
    */
    // @ApiMember(DataType="integer", Description="The pharmacy id to quarantine", IsRequired=true, Name="Id", ParameterType="body")
    public id: number;

    /**
    * The action to take on the pharmacy's stock
    */
    // @ApiMember(DataType="integer", Description="The action to take on the pharmacy's stock", IsRequired=true, Name="QuarantinedStockAction", ParameterType="body")
    public quarantinedStockAction: QuarantinedStockAction;

    public constructor(init?: Partial<ActionQuarantinedStock>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ActionQuarantinedStock'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ActionQuarantinedStockResponse(); }
}

// @Route("/drug-management-api/pharmacy/action-depot-batches", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class QuarantineRemovePharmacyDepotBatch implements IReturn<QuarantineRemovePharmacyDepotBatchResponse>, IPost
{
    /**
    * The pharmacy id related to these depot batches
    */
    // @ApiMember(DataType="integer", Description="The pharmacy id related to these depot batches", IsRequired=true, Name="PharmacyId", ParameterType="body")
    public pharmacyId: number;

    /**
    * A dictionary of the depot batch ids correlating to the new state of the unit count
    */
    // @ApiMember(DataType="dictionary", Description="A dictionary of the depot batch ids correlating to the new state of the unit count", IsRequired=true, Name="DepotBatchStates", ParameterType="body")
    public depotBatchStates: { [index: number]: QuarantinedStockAction; };

    public constructor(init?: Partial<QuarantineRemovePharmacyDepotBatch>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QuarantineRemovePharmacyDepotBatch'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QuarantineRemovePharmacyDepotBatchResponse(); }
}

// @Route("/drug-management-api/timepoints", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTimepoints implements IReturn<GetTimepointsResponse>
{

    public constructor(init?: Partial<GetTimepoints>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTimepoints'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetTimepointsResponse(); }
}

// @Route("/drug-management-api/timepoints/treatment/{TreatmentId}", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTimepointsByTreatmentId implements IReturn<GetTimepointsResponse>
{
    /**
    * The treatment id to get timepoints for
    */
    // @ApiMember(DataType="int", Description="The treatment id to get timepoints for", IsRequired=true, Name="TreatmentId", ParameterType="path")
    public treatmentId: number;

    public constructor(init?: Partial<GetTimepointsByTreatmentId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTimepointsByTreatmentId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetTimepointsResponse(); }
}

// @Route("/opms/sae/medicalreview/{PatientStudyNumber}/{SaeNumber}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeMedicalReviewSingleByPatientStudyNumber implements IReturn<SaeMedicalReviewSingleByPatientStudyNumberResponse>
{
    /**
    * The patient study number of the sae
    */
    // @DataMember(Order=1)
    // @ApiMember(Description="The patient study number of the sae", IsRequired=true, Name="PatientStudyNumber", ParameterType="string")
    public patientStudyNumber: string;

    /**
    * The sae number
    */
    // @DataMember(Order=2)
    // @ApiMember(Description="The sae number", IsRequired=true, Name="SaeNumber", ParameterType="int")
    public saeNumber: number;

    public constructor(init?: Partial<SaeMedicalReviewSingleByPatientStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeMedicalReviewSingleByPatientStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeMedicalReviewSingleByPatientStudyNumberResponse(); }
}

// @Route("/opms/sae/medicalreview/save-narrative-summary", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeMedicalReviewNarrativeSummaryPostSave implements IReturn<SaeMedicalReviewNarrativeSummaryPostSaveResponse>
{
    /**
    * The medical review id of the narrative to save
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="integer", Description="The medical review id of the narrative to save", IsRequired=true, Name="MedicalReviewId", ParameterType="body")
    public medicalReviewId: number;

    /**
    * The narrative to save
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="string", Description="The narrative to save", IsRequired=true, Name="NarrativeSummary", ParameterType="body")
    public narrativeSummary: string;

    public constructor(init?: Partial<SaeMedicalReviewNarrativeSummaryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeMedicalReviewNarrativeSummaryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeMedicalReviewNarrativeSummaryPostSaveResponse(); }
}

// @Route("/opms/sae/medicalreview/save", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeMedicalReviewPostSave implements IReturn<SaeMedicalReviewPostSaveResponse>
{
    /**
    * The medical review to save
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="model", Description="The medical review to save", IsRequired=true, Name="PatientStudyNumber", ParameterType="body")
    public medicalReview: MedicalReview;

    public constructor(init?: Partial<SaeMedicalReviewPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeMedicalReviewPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeMedicalReviewPostSaveResponse(); }
}

// @Route("/opms/sae/medicalreview/validate", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeValidateMedicalReview implements IReturn<SaeValidateMedicalReviewResponse>
{
    /**
    * The medical review
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="MedicalReview", Description="The medical review", IsRequired=true, Name="MedicalReview", ParameterType="body")
    public medicalReview: MedicalReview;

    public constructor(init?: Partial<SaeValidateMedicalReview>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeValidateMedicalReview'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeValidateMedicalReviewResponse(); }
}

/**
* Sae
*/
// @Route("/api/sae/collection", "GET")
// @Api(Description="Sae")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=500)
// @DataContract
export class SaeGetCollection implements IReturn<SaeCollectionResponse>, ISaeGetCollection
{

    public constructor(init?: Partial<SaeGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeCollectionResponse(); }
}

/**
* Sae
*/
// @Route("/api/sae/collection/institution/{InstitutionCode}", "GET")
// @Api(Description="Sae")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=500)
// @DataContract
export class SaeGetCollectionByInstitutionCode implements IReturn<SaeCollectionResponse>, ISaeGetCollectionByInstitutionCode
{
    /**
    * The code of the Institution associated with the sae to retrieve.
    */
    // @DataMember(Order=1)
    public institutionCode: string;

    public constructor(init?: Partial<SaeGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeCollectionResponse(); }
}

/**
* Sae
*/
// @Route("/api/sae/single/patient-study-number/{PatientStudyNumber}/sae-number/{SaeNumber}", "GET")
// @Api(Description="Sae")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=500)
// @DataContract
export class SaeGetSingleByPatientStudyNumber implements IReturn<SaeSingleResponse>, ISaeGetSingleByPatientStudyNumber
{
    /**
    * The study number of the Patient associated with the sae to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The sae number of the Sae associated with the sae to retrieve.
    */
    // @DataMember(Order=2)
    public saeNumber: number;

    public constructor(init?: Partial<SaeGetSingleByPatientStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetSingleByPatientStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeSingleResponse(); }
}

// @Route("/opms/sae/collection/status", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeGetCollectionByStatus implements IReturn<SaeGetCollectionByStatusResponse>
{
    /**
    * The status of the saes
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="integer", Description="The status of the saes", IsRequired=true, Name="Status", ParameterType="body")
    public status: SaeStatus;

    public constructor(init?: Partial<SaeGetCollectionByStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetCollectionByStatus'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeGetCollectionByStatusResponse(); }
}

// @Route("/opms/sae/webaction", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeWebActionSave implements IReturn<SaeWebActionSaveResponse>
{
    /**
    * The sae web action
    */
    // @DataMember(Order=1)
    // @ApiMember(Description="The sae web action", IsRequired=true, Name="WebAction", ParameterType="model")
    public webAction: WebAction;

    public constructor(init?: Partial<SaeWebActionSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeWebActionSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeWebActionSaveResponse(); }
}

// @Route("/opms/sae/webaction/medicalreview", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeMedicalReviewWebActionSave implements IReturn<SaeMedicalReviewWebActionSaveResponse>
{
    /**
    * The patient study number of the sae
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="string", Description="The patient study number of the sae", IsRequired=true, Name="PatientStudyNumber", ParameterType="body")
    public patientStudyNumber: string;

    /**
    * The sae number of the sae
    */
    // @DataMember(Order=2)
    // @ApiMember(DataType="integer", Description="The sae number of the sae", IsRequired=true, Name="SaeNumber", ParameterType="body")
    public saeNumber: number;

    /**
    * The medical review form from which the sae web action will be based off
    */
    // @DataMember(Order=3)
    // @ApiMember(Description="The medical review form from which the sae web action will be based off", IsRequired=true, Name="SaeMedicalReviewForm", ParameterType="model")
    public medicalReview: SaeMedicalReviewForm;

    public constructor(init?: Partial<SaeMedicalReviewWebActionSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeMedicalReviewWebActionSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeMedicalReviewWebActionSaveResponse(); }
}

// @Route("/opms/sae/send-notification", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeSendNotification implements IReturn<SaeSendNotificationResponse>
{
    /**
    * The patient study number of the sae
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="string", Description="The patient study number of the sae", IsRequired=true, Name="PatientStudyNumber", ParameterType="body")
    public patientStudyNumber: string;

    /**
    * The sae number of the sae
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="integer", Description="The sae number of the sae", IsRequired=true, Name="SaeNumber", ParameterType="body")
    public saeNumber: number;

    /**
    * The notification form
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="SaeNotificationForm", Description="The notification form", IsRequired=true, Name="NotificationForm", ParameterType="body")
    public notificationForm: SaeNotificationForm;

    public constructor(init?: Partial<SaeSendNotification>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeSendNotification'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeSendNotificationResponse(); }
}

// @Route("/opms/sae/validate-notification", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeValidateNotificationForm implements IReturn<SaeValidateNotificationFormResponse>
{
    /**
    * The notification form
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="SaeNotificationForm", Description="The notification form", IsRequired=true, Name="NotificationForm", ParameterType="body")
    public form: SaeNotificationForm;

    public constructor(init?: Partial<SaeValidateNotificationForm>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeValidateNotificationForm'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeValidateNotificationFormResponse(); }
}

// @Route("/opms/sae/validate-rejection", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeValidateRejectionForm implements IReturn<SaeValidateRejectionFormResponse>
{
    /**
    * The rejection form
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="WebAction", Description="The rejection form", IsRequired=true, Name="RejectionForm", ParameterType="body")
    public form: WebAction;

    public constructor(init?: Partial<SaeValidateRejectionForm>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeValidateRejectionForm'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeValidateRejectionFormResponse(); }
}

// @Route("/opms/sae/open-clinica/document", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeGetOpenClinicaDocument implements IReturn<Blob>, IGet
{
    /**
    * The file name of the document in openclinica
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="string", Description="The file name of the document in openclinica", IsRequired=true, Name="FileName", ParameterType="body")
    public fileName: string;

    public constructor(init?: Partial<SaeGetOpenClinicaDocument>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetOpenClinicaDocument'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/single/id/{Id}", "GET")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryGetSingleById implements IReturn<ScreeningSummarySingleResponse>, IScreeningSummaryGetSingleById
{
    /**
    * The ID of the screeningsummary to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ScreeningSummaryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningSummaryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningSummarySingleResponse(); }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/collection", "GET")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryGetCollection implements IReturn<ScreeningSummaryCollectionResponse>, IScreeningSummaryGetCollection
{
    /**
    * The ID of the Institution associated with the screeningsummary to retrieve.
    */
    // @DataMember(Order=1)
    public institutionId?: number;

    /**
    * The Year associated with the screeningsummary to retrieve.
    */
    // @DataMember(Order=2)
    public year?: number;

    public constructor(init?: Partial<ScreeningSummaryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningSummaryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningSummaryCollectionResponse(); }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/collection/institution/id/{InstitutionId}", "GET")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryGetCollectionByInstitutionId implements IReturn<ScreeningSummaryCollectionResponse>, IScreeningSummaryGetCollectionByInstitutionId
{
    /**
    * The ID of the Institution associated with the screeningsummary to retrieve.
    */
    // @DataMember(Order=1)
    public institutionId?: number;

    public constructor(init?: Partial<ScreeningSummaryGetCollectionByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningSummaryGetCollectionByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningSummaryCollectionResponse(); }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/save", "POST")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryPostSave implements IReturn<ScreeningSummarySingleResponse>
{
    /**
    * The screeningsummary to save.
    */
    // @DataMember(Order=1)
    public screeningSummary: ScreeningSummary;

    public constructor(init?: Partial<ScreeningSummaryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningSummaryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ScreeningSummarySingleResponse(); }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/validate", "POST")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningSummaryFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningSummaryFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningSummaryFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningSummaryFormValidationResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryPostValidate implements IReturn<ScreeningSummaryFormValidationResponse>
{
    /**
    * The screeningsummary to validate.
    */
    // @DataMember(Order=1)
    public screeningSummary: ScreeningSummary;

    public constructor(init?: Partial<ScreeningSummaryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningSummaryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ScreeningSummaryFormValidationResponse(); }
}

// @Route("/opms/file-upload", "POST")
export class PostUploadFile implements IReturn<UploadResponse>, IPost
{

    public constructor(init?: Partial<PostUploadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostUploadFile'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/file-upload/info/{Guid}", "GET")
export class GetUploadInfo implements IReturn<UploadResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUploadInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUploadInfo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/file-upload/{Guid}", "GET")
export class GetUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file-upload/download/{Guid}", "GET")
export class DownloadUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<DownloadUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file-upload/{Guid}", "DELETE")
export class DeleteUpload implements IReturn<UploadResponse>, IDelete
{
    public guid: string;

    public constructor(init?: Partial<DeleteUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteUpload'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/user")
export class GetUser implements IReturn<GetUserResponse>, IGet
{

    public constructor(init?: Partial<GetUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUser'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetUserResponse(); }
}

/**
* Lookup
*/
// @Route("/opms/lookup/collection/form-definition/{FormDefinitionId}", "GET")
// @Api(Description="Lookup")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupGetCollectionByFormDefinitionId implements IReturn<LookupCollectionResponse>, ILookupGetCollectionByFormDefinitionId
{
    /**
    * The ID of the Form Definition associated with the lookup to retrieve.
    */
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    public constructor(init?: Partial<LookupGetCollectionByFormDefinitionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LookupGetCollectionByFormDefinitionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LookupCollectionResponse(); }
}

// @Route("/opms/form-names")
export class GetFormMetadata implements IReturn<GetFormMetadataResponse>
{

    public constructor(init?: Partial<GetFormMetadata>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFormMetadata'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetFormMetadataResponse(); }
}

/**
* Event Definition
*/
// @Route("/opms/event-definition/single/id/{Id}", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetSingleById implements IReturn<EventDefinitionSingleResponse>, IEventDefinitionGetSingleById
{
    /**
    * The ID of the event definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EventDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionSingleResponse(); }
}

/**
* Event Definition
*/
// @Route("/opms/event-definition/single/code/{Code}", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetSingleByCode implements IReturn<EventDefinitionSingleResponse>, IEventDefinitionGetSingleByCode
{
    /**
    * The ID of the event definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<EventDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionSingleResponse(); }
}

/**
* Event Definition
*/
// @Route("/opms/event-definition/collection", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetCollection implements IReturn<EventDefinitionCollectionResponse>, IEventDefinitionGetCollection
{

    public constructor(init?: Partial<EventDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionCollectionResponse(); }
}

/**
* Form Definition
*/
// @Route("/opms/form-definition/single/id/{Id}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleById implements IReturn<FormDefinitionSingleResponse>, IFormDefinitionGetSingleById
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

/**
* Form Definition
*/
// @Route("/opms/form-definition/single/code/{Code}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleByCode implements IReturn<FormDefinitionSingleResponse>, IFormDefinitionGetSingleByCode
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

/**
* Form Definition
*/
// @Route("/opms/form-definition/collection", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetCollection implements IReturn<FormDefinitionCollectionResponse>, IFormDefinitionGetCollection
{

    public constructor(init?: Partial<FormDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionCollectionResponse(); }
}

/**
* Patient State
*/
// @Route("/opms/patient-state/single/id/{Id}", "GET")
// @Api(Description="Patient State")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=500)
// @DataContract
export class PatientStateGetSingleById implements IReturn<PatientStateSingleResponse>, IPatientStateGetSingleById
{
    /**
    * The ID of the patient state to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientStateGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStateGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStateSingleResponse(); }
}

/**
* Patient State
*/
// @Route("/opms/patient-state/collection", "GET")
// @Api(Description="Patient State")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientStateGetCollection implements IReturn<PatientStateCollectionResponse>, IPatientStateGetCollection
{

    public constructor(init?: Partial<PatientStateGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStateGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStateCollectionResponse(); }
}

/**
* Treatment
*/
// @Route("/opms/treatment/single/id/{Id}", "GET")
// @Api(Description="Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetSingleById implements IReturn<TreatmentSingleResponse>, ITreatmentGetSingleById
{
    /**
    * The ID of the treatment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentSingleResponse(); }
}

/**
* Treatment
*/
// @Route("/opms/treatment/collection", "GET")
// @Api(Description="Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetCollection implements IReturn<TreatmentCollectionResponse>, ITreatmentGetCollection
{

    public constructor(init?: Partial<TreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentCollectionResponse(); }
}

/**
* Master Group
*/
// @Route("/opms/master-group/single/id/{Id}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleById implements IReturn<MasterGroupSingleResponse>, IMasterGroupGetSingleById
{
    /**
    * The ID of the master group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MasterGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

/**
* Master Group
*/
// @Route("/opms/master-group/single/code/{Code}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleByCode implements IReturn<MasterGroupSingleResponse>, IMasterGroupGetSingleByCode
{
    /**
    * The ID of the master group to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<MasterGroupGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

/**
* Master Group
*/
// @Route("/opms/master-group/collection", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetCollection implements IReturn<MasterGroupCollectionResponse>, IMasterGroupGetCollection
{

    public constructor(init?: Partial<MasterGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupCollectionResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/single/id/{Id}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetSingleById implements IReturn<CollaboratingGroupSingleResponse>, ICollaboratingGroupGetSingleById
{
    /**
    * The ID of the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CollaboratingGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupSingleResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/single/code/{Code}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetSingleByCode implements IReturn<CollaboratingGroupSingleResponse>, ICollaboratingGroupGetSingleByCode
{
    /**
    * The ID of the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<CollaboratingGroupGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupSingleResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/collection", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollection implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollection
{

    public constructor(init?: Partial<CollaboratingGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollectionByMasterGroupId implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId?: number;

    public constructor(init?: Partial<CollaboratingGroupGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollectionByMasterGroupCode implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    public constructor(init?: Partial<CollaboratingGroupGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/single/id/{Id}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleById implements IReturn<CountrySingleResponse>, ICountryGetSingleById
{
    /**
    * The ID of the country to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CountryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/single/code/{Code}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleByCode implements IReturn<CountrySingleResponse>, ICountryGetSingleByCode
{
    /**
    * The ID of the country to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<CountryGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollection implements IReturn<CountryCollectionResponse>, ICountryGetCollection
{

    public constructor(init?: Partial<CountryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByMasterGroupId implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId?: number;

    public constructor(init?: Partial<CountryGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByMasterGroupCode implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    public constructor(init?: Partial<CountryGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByCollaboratingGroupId implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByCollaboratingGroupId
{
    /**
    * The ID of the Collaborating Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupId?: number;

    public constructor(init?: Partial<CountryGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByCollaboratingGroupCode implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByCollaboratingGroupCode
{
    /**
    * The Code of the Collaborating Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    public constructor(init?: Partial<CountryGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/single/id/{Id}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleById implements IReturn<InstitutionSingleResponse>, IInstitutionGetSingleById
{
    /**
    * The ID of the institution to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InstitutionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/single/code/{Code}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleByCode implements IReturn<InstitutionSingleResponse>, IInstitutionGetSingleByCode
{
    /**
    * The ID of the institution to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<InstitutionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollection implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollection
{

    public constructor(init?: Partial<InstitutionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/country/id/{CountryId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCountryId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCountryId
{
    /**
    * The ID of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByCountryId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCountryId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByMasterGroupId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId: number;

    /**
    * The ID of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCollaboratingGroupId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCollaboratingGroupId
{
    /**
    * The ID of the Collaborating Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupId: number;

    /**
    * The ID of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/country/code/{CountryCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCountryCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCountryCode
{
    /**
    * The Code of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByCountryCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCountryCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByMasterGroupCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    /**
    * The Code of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCollaboratingGroupCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCollaboratingGroupCode
{
    /**
    * The Code of the Collaborating Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    /**
    * The Code of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/single/id/{Id}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleById implements IReturn<PatientSingleResponse>, IPatientGetSingleById
{
    /**
    * The ID of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/single/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleByStudyNumber implements IReturn<PatientSingleResponse>, IPatientGetSingleByStudyNumber
{
    /**
    * The Study Number of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetSingleByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/validation/id/{Id}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientGetValidationById implements IReturn<PatientValidationResponse>, IPatientGetValidationById
{
    /**
    * The ID of the patient to validate.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetValidationById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetValidationById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientValidationResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/validation/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientGetValidationByStudyNumber implements IReturn<PatientValidationResponse>, IPatientGetValidationByStudyNumber
{
    /**
    * The Study Number of the patient to validate.
    */
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetValidationByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetValidationByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientValidationResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollection implements IReturn<PatientCollectionResponse>, IPatientGetCollection
{
    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByMasterGroupId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId: number;

    /**
    * The ID of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCollaboratingGroupId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCollaboratingGroupId
{
    /**
    * The ID of the Collaborating Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupId: number;

    /**
    * The ID of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/country/id/{CountryId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCountryId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCountryId
{
    /**
    * The ID of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public countryId: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCountryId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCountryId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/institution/id/{InstitutionId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByInstitutionId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByInstitutionId
{
    /**
    * The ID of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public institutionId: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByMasterGroupCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    /**
    * The Code of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCollaboratingGroupCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCollaboratingGroupCode
{
    /**
    * The Code of the Collaborating Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    /**
    * The Code of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/country/code/{CountryCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCountryCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCountryCode
{
    /**
    * The Code of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public countryCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCountryCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCountryCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/institution/code/{InstitutionCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByInstitutionCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByInstitutionCode
{
    /**
    * The Code of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public institutionCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/Save", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSave implements IReturn<PatientSingleResponse>
{
    /**
    * The patient to save.
    */
    // @DataMember(Order=1)
    public patient: Patient;

    public constructor(init?: Partial<PatientPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/save/ids", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSaveWithIds implements IReturn<PatientSingleResponse>
{
    /**
    * The patient to save.
    */
    // @DataMember(Order=1)
    public patient: Patient;

    /**
    * The ID of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    public constructor(init?: Partial<PatientPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/save/codes", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSaveWithCodes implements IReturn<PatientSingleResponse>
{
    /**
    * The patient to save.
    */
    // @DataMember(Order=1)
    public patient: Patient;

    /**
    * The ID of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    public constructor(init?: Partial<PatientPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/single/id/{Id}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleById implements IReturn<EventSingleResponse>, IEventGetSingleById
{
    /**
    * The ID of the event to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EventGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/single/ids", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleByIds implements IReturn<EventSingleResponse>, IEventGetSingleByIds
{
    /**
    * The Patient ID of the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Id of the Institution associated to the event to retrieve.
    */
    // @DataMember(Order=4)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=5)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=6)
    public createEvent?: boolean;

    public constructor(init?: Partial<EventGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/single/codes", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleByCodes implements IReturn<EventSingleResponse>, IEventGetSingleByCodes
{
    /**
    * The Patient Study Number of the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Code of the Institution associated to the event to retrieve.
    */
    // @DataMember(Order=4)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=5)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=6)
    public createEvent?: boolean;

    public constructor(init?: Partial<EventGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/collection", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollection implements IReturn<EventCollectionResponse>, IEventGetCollection
{

    public constructor(init?: Partial<EventGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/collection/patient/id/{PatientId}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollectionByPatientId implements IReturn<EventCollectionResponse>, IEventGetCollectionByPatientId
{
    /**
    * The ID of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientId: number;

    public constructor(init?: Partial<EventGetCollectionByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollectionByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/collection/patient/study-number/{PatientStudyNumber}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollectionByPatientStudyNumber implements IReturn<EventCollectionResponse>, IEventGetCollectionByPatientStudyNumber
{
    /**
    * The Study Number of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    public constructor(init?: Partial<EventGetCollectionByPatientStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollectionByPatientStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/Save", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSave implements IReturn<EventSingleResponse>
{
    /**
    * The event to save.
    */
    // @DataMember(Order=1)
    public event: Event;

    public constructor(init?: Partial<EventPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/save/ids", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSaveWithIds implements IReturn<EventSingleResponse>
{
    /**
    * The event to save.
    */
    // @DataMember(Order=1)
    public event: Event;

    /**
    * The ID of the Institution associated with the event to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition associated with the event to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Repeat number of the event to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    public constructor(init?: Partial<EventPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/save/codes", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSaveWithCodes implements IReturn<EventSingleResponse>
{
    /**
    * The event to save.
    */
    // @DataMember(Order=1)
    public event: Event;

    /**
    * The Code of the Institution associated with the event to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the event to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Repeat number of the event to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    public constructor(init?: Partial<EventPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Form - Additional Eligibility Confirmation
*/
// @Route("/opms/form/additional-eligibility-confirmation/single/id/{Id}", "GET")
// @Api(Description="Form - Additional Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalEligibilityConfirmationGetSingleById implements IReturn<AdditionalEligibilityConfirmationSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdditionalEligibilityConfirmationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalEligibilityConfirmationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalEligibilityConfirmationSingleResponse(); }
}

/**
* Form - Additional Eligibility Confirmation
*/
// @Route("/opms/form/additional-eligibility-confirmation/single/ids", "GET")
// @Api(Description="Form - Additional Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalEligibilityConfirmationGetSingleByIds implements IReturn<AdditionalEligibilityConfirmationSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new additional eligibility confirmation if no additional eligibility confirmation is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<AdditionalEligibilityConfirmationGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalEligibilityConfirmationGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalEligibilityConfirmationSingleResponse(); }
}

/**
* Form - Additional Eligibility Confirmation
*/
// @Route("/opms/form/additional-eligibility-confirmation/single/codes", "GET")
// @Api(Description="Form - Additional Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalEligibilityConfirmationGetSingleByCodes implements IReturn<AdditionalEligibilityConfirmationSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new additional eligibility confirmation if no additional eligibility confirmation is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<AdditionalEligibilityConfirmationGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalEligibilityConfirmationGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalEligibilityConfirmationSingleResponse(); }
}

/**
* Form - Additional Eligibility Confirmation
*/
// @Route("/opms/form/additional-eligibility-confirmation/collection", "GET")
// @Api(Description="Form - Additional Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationCollectionResponse)", StatusCode=500)
// @DataContract
export class AdditionalEligibilityConfirmationGetCollection implements IReturn<AdditionalEligibilityConfirmationCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<AdditionalEligibilityConfirmationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalEligibilityConfirmationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalEligibilityConfirmationCollectionResponse(); }
}

/**
* Form - Additional Eligibility Confirmation
*/
// @Route("/opms/form/additional-eligibility-confirmation/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Additional Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationCollectionResponse)", StatusCode=500)
// @DataContract
export class AdditionalEligibilityConfirmationGetCollectionByIds implements IReturn<AdditionalEligibilityConfirmationCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdditionalEligibilityConfirmationGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalEligibilityConfirmationGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalEligibilityConfirmationCollectionResponse(); }
}

/**
* Form - Additional Eligibility Confirmation
*/
// @Route("/opms/form/additional-eligibility-confirmation/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Additional Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationCollectionResponse)", StatusCode=500)
// @DataContract
export class AdditionalEligibilityConfirmationGetCollectionByCodes implements IReturn<AdditionalEligibilityConfirmationCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdditionalEligibilityConfirmationGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalEligibilityConfirmationGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalEligibilityConfirmationCollectionResponse(); }
}

/**
* Form - Additional Eligibility Confirmation
*/
// @Route("/opms/form/additional-eligibility-confirmation/save", "POST")
// @Api(Description="Form - Additional Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalEligibilityConfirmationPostSave implements IReturn<AdditionalEligibilityConfirmationSingleResponse>
{
    /**
    * The additional eligibility confirmation to save.
    */
    // @DataMember(Order=1)
    public form: AdditionalEligibilityConfirmation;

    public constructor(init?: Partial<AdditionalEligibilityConfirmationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalEligibilityConfirmationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalEligibilityConfirmationSingleResponse(); }
}

/**
* Form - Additional Eligibility Confirmation
*/
// @Route("/opms/form/additional-eligibility-confirmation/save/ids", "POST")
// @Api(Description="Form - Additional Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalEligibilityConfirmationPostSaveWithIds implements IReturn<AdditionalEligibilityConfirmationSingleResponse>
{
    /**
    * The additional eligibility confirmation to save.
    */
    // @DataMember(Order=1)
    public form: AdditionalEligibilityConfirmation;

    /**
    * The ID of the Institution associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<AdditionalEligibilityConfirmationPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalEligibilityConfirmationPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalEligibilityConfirmationSingleResponse(); }
}

/**
* Form - Additional Eligibility Confirmation
*/
// @Route("/opms/form/additional-eligibility-confirmation/save/codes", "POST")
// @Api(Description="Form - Additional Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalEligibilityConfirmationPostSaveWithCodes implements IReturn<AdditionalEligibilityConfirmationSingleResponse>
{
    /**
    * The additional eligibility confirmation to save.
    */
    // @DataMember(Order=1)
    public form: AdditionalEligibilityConfirmation;

    /**
    * The Code of the Institution associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<AdditionalEligibilityConfirmationPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalEligibilityConfirmationPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalEligibilityConfirmationSingleResponse(); }
}

/**
* Form - Additional Eligibility Confirmation
*/
// @Route("/opms/form/additional-eligibility-confirmation/delete", "DELETE")
// @Api(Description="Form - Additional Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=500)
// @DataContract
export class AdditionalEligibilityConfirmationDelete implements IReturn<AdditionalEligibilityConfirmationDeleteResponse>
{
    /**
    * The additional eligibility confirmation to delete.
    */
    // @DataMember(Order=1)
    public form: AdditionalEligibilityConfirmation;

    public constructor(init?: Partial<AdditionalEligibilityConfirmationDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalEligibilityConfirmationDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdditionalEligibilityConfirmationDeleteResponse(); }
}

/**
* Form - Additional Eligibility Confirmation
*/
// @Route("/opms/form/additional-eligibility-confirmation/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Additional Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=500)
// @DataContract
export class AdditionalEligibilityConfirmationDeleteWithId implements IReturn<AdditionalEligibilityConfirmationDeleteResponse>
{
    /**
    * The ID of the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdditionalEligibilityConfirmationDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalEligibilityConfirmationDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdditionalEligibilityConfirmationDeleteResponse(); }
}

/**
* Form - Additional Eligibility Confirmation
*/
// @Route("/opms/form/additional-eligibility-confirmation/delete/ids", "DELETE")
// @Api(Description="Form - Additional Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=500)
// @DataContract
export class AdditionalEligibilityConfirmationDeleteWithIds implements IReturn<AdditionalEligibilityConfirmationDeleteResponse>
{
    /**
    * The Patient ID of the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<AdditionalEligibilityConfirmationDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalEligibilityConfirmationDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdditionalEligibilityConfirmationDeleteResponse(); }
}

/**
* Form - Additional Eligibility Confirmation
*/
// @Route("/opms/form/additional-eligibility-confirmation/delete/codes", "DELETE")
// @Api(Description="Form - Additional Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationDeleteResponse)", StatusCode=500)
// @DataContract
export class AdditionalEligibilityConfirmationDeleteByCodes implements IReturn<AdditionalEligibilityConfirmationDeleteResponse>
{
    /**
    * The Patient Study Number of the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<AdditionalEligibilityConfirmationDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalEligibilityConfirmationDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdditionalEligibilityConfirmationDeleteResponse(); }
}

/**
* Form - Additional Eligibility Confirmation
*/
// @Route("/opms/form/additional-eligibility-confirmation/validate", "POST")
// @Api(Description="Form - Additional Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationValidationResponse)", StatusCode=500)
// @DataContract
export class AdditionalEligibilityConfirmationPostValidate implements IReturn<AdditionalEligibilityConfirmationValidationResponse>
{
    /**
    * The additional eligibility confirmation to validate.
    */
    // @DataMember(Order=1)
    public form: AdditionalEligibilityConfirmation;

    public constructor(init?: Partial<AdditionalEligibilityConfirmationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalEligibilityConfirmationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalEligibilityConfirmationValidationResponse(); }
}

/**
* Form - Additional Eligibility Confirmation
*/
// @Route("/opms/form/additional-eligibility-confirmation/validate/ids", "POST")
// @Api(Description="Form - Additional Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationValidationResponse)", StatusCode=500)
// @DataContract
export class AdditionalEligibilityConfirmationPostValidateWithIds implements IReturn<AdditionalEligibilityConfirmationValidationResponse>
{
    /**
    * The additional eligibility confirmation to validate.
    */
    // @DataMember(Order=1)
    public form: AdditionalEligibilityConfirmation;

    /**
    * The ID of the Institution associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdditionalEligibilityConfirmationPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalEligibilityConfirmationPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalEligibilityConfirmationValidationResponse(); }
}

/**
* Form - Additional Eligibility Confirmation
*/
// @Route("/opms/form/additional-eligibility-confirmation/validate/codes", "POST")
// @Api(Description="Form - Additional Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalEligibilityConfirmationValidationResponse)", StatusCode=500)
// @DataContract
export class AdditionalEligibilityConfirmationPostValidateWithCodes implements IReturn<AdditionalEligibilityConfirmationValidationResponse>
{
    /**
    * The additional eligibility confirmation to validate.
    */
    // @DataMember(Order=1)
    public form: AdditionalEligibilityConfirmation;

    /**
    * The Code of the Institution associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the additional eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdditionalEligibilityConfirmationPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalEligibilityConfirmationPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalEligibilityConfirmationValidationResponse(); }
}

/**
* Form - Additional Imaging
*/
// @Route("/opms/form/additional-imaging/single/id/{Id}", "GET")
// @Api(Description="Form - Additional Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalImagingGetSingleById implements IReturn<AdditionalImagingSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the additional imaging to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdditionalImagingGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalImagingGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalImagingSingleResponse(); }
}

/**
* Form - Additional Imaging
*/
// @Route("/opms/form/additional-imaging/single/ids", "GET")
// @Api(Description="Form - Additional Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalImagingGetSingleByIds implements IReturn<AdditionalImagingSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the additional imaging to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the additional imaging to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the additional imaging to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new additional imaging if no additional imaging is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<AdditionalImagingGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalImagingGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalImagingSingleResponse(); }
}

/**
* Form - Additional Imaging
*/
// @Route("/opms/form/additional-imaging/single/codes", "GET")
// @Api(Description="Form - Additional Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalImagingGetSingleByCodes implements IReturn<AdditionalImagingSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the additional imaging to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the additional imaging to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the additional imaging to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new additional imaging if no additional imaging is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<AdditionalImagingGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalImagingGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalImagingSingleResponse(); }
}

/**
* Form - Additional Imaging
*/
// @Route("/opms/form/additional-imaging/collection", "GET")
// @Api(Description="Form - Additional Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalImagingCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalImagingCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalImagingCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalImagingCollectionResponse)", StatusCode=500)
// @DataContract
export class AdditionalImagingGetCollection implements IReturn<AdditionalImagingCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<AdditionalImagingGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalImagingGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalImagingCollectionResponse(); }
}

/**
* Form - Additional Imaging
*/
// @Route("/opms/form/additional-imaging/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Additional Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalImagingCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalImagingCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalImagingCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalImagingCollectionResponse)", StatusCode=500)
// @DataContract
export class AdditionalImagingGetCollectionByIds implements IReturn<AdditionalImagingCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the additional imaging to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdditionalImagingGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalImagingGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalImagingCollectionResponse(); }
}

/**
* Form - Additional Imaging
*/
// @Route("/opms/form/additional-imaging/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Additional Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalImagingCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalImagingCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalImagingCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalImagingCollectionResponse)", StatusCode=500)
// @DataContract
export class AdditionalImagingGetCollectionByCodes implements IReturn<AdditionalImagingCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdditionalImagingGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalImagingGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalImagingCollectionResponse(); }
}

/**
* Form - Additional Imaging
*/
// @Route("/opms/form/additional-imaging/save", "POST")
// @Api(Description="Form - Additional Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalImagingPostSave implements IReturn<AdditionalImagingSingleResponse>
{
    /**
    * The additional imaging to save.
    */
    // @DataMember(Order=1)
    public form: AdditionalImaging;

    public constructor(init?: Partial<AdditionalImagingPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalImagingPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalImagingSingleResponse(); }
}

/**
* Form - Additional Imaging
*/
// @Route("/opms/form/additional-imaging/save/ids", "POST")
// @Api(Description="Form - Additional Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalImagingPostSaveWithIds implements IReturn<AdditionalImagingSingleResponse>
{
    /**
    * The additional imaging to save.
    */
    // @DataMember(Order=1)
    public form: AdditionalImaging;

    /**
    * The ID of the Institution associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the additional imaging to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<AdditionalImagingPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalImagingPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalImagingSingleResponse(); }
}

/**
* Form - Additional Imaging
*/
// @Route("/opms/form/additional-imaging/save/codes", "POST")
// @Api(Description="Form - Additional Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalImagingSingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalImagingPostSaveWithCodes implements IReturn<AdditionalImagingSingleResponse>
{
    /**
    * The additional imaging to save.
    */
    // @DataMember(Order=1)
    public form: AdditionalImaging;

    /**
    * The Code of the Institution associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the additional imaging to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<AdditionalImagingPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalImagingPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalImagingSingleResponse(); }
}

/**
* Form - Additional Imaging
*/
// @Route("/opms/form/additional-imaging/delete", "DELETE")
// @Api(Description="Form - Additional Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=500)
// @DataContract
export class AdditionalImagingDelete implements IReturn<AdditionalImagingDeleteResponse>
{
    /**
    * The additional imaging to delete.
    */
    // @DataMember(Order=1)
    public form: AdditionalImaging;

    public constructor(init?: Partial<AdditionalImagingDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalImagingDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdditionalImagingDeleteResponse(); }
}

/**
* Form - Additional Imaging
*/
// @Route("/opms/form/additional-imaging/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Additional Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=500)
// @DataContract
export class AdditionalImagingDeleteWithId implements IReturn<AdditionalImagingDeleteResponse>
{
    /**
    * The ID of the additional imaging to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdditionalImagingDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalImagingDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdditionalImagingDeleteResponse(); }
}

/**
* Form - Additional Imaging
*/
// @Route("/opms/form/additional-imaging/delete/ids", "DELETE")
// @Api(Description="Form - Additional Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=500)
// @DataContract
export class AdditionalImagingDeleteWithIds implements IReturn<AdditionalImagingDeleteResponse>
{
    /**
    * The Patient ID of the additional imaging to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the additional imaging to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<AdditionalImagingDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalImagingDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdditionalImagingDeleteResponse(); }
}

/**
* Form - Additional Imaging
*/
// @Route("/opms/form/additional-imaging/delete/codes", "DELETE")
// @Api(Description="Form - Additional Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalImagingDeleteResponse)", StatusCode=500)
// @DataContract
export class AdditionalImagingDeleteByCodes implements IReturn<AdditionalImagingDeleteResponse>
{
    /**
    * The Patient Study Number of the additional imaging to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the additional imaging to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<AdditionalImagingDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalImagingDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdditionalImagingDeleteResponse(); }
}

/**
* Form - Additional Imaging
*/
// @Route("/opms/form/additional-imaging/validate", "POST")
// @Api(Description="Form - Additional Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalImagingValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalImagingValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalImagingValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalImagingValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalImagingValidationResponse)", StatusCode=500)
// @DataContract
export class AdditionalImagingPostValidate implements IReturn<AdditionalImagingValidationResponse>
{
    /**
    * The additional imaging to validate.
    */
    // @DataMember(Order=1)
    public form: AdditionalImaging;

    public constructor(init?: Partial<AdditionalImagingPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalImagingPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalImagingValidationResponse(); }
}

/**
* Form - Additional Imaging
*/
// @Route("/opms/form/additional-imaging/validate/ids", "POST")
// @Api(Description="Form - Additional Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalImagingValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalImagingValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalImagingValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalImagingValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalImagingValidationResponse)", StatusCode=500)
// @DataContract
export class AdditionalImagingPostValidateWithIds implements IReturn<AdditionalImagingValidationResponse>
{
    /**
    * The additional imaging to validate.
    */
    // @DataMember(Order=1)
    public form: AdditionalImaging;

    /**
    * The ID of the Institution associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdditionalImagingPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalImagingPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalImagingValidationResponse(); }
}

/**
* Form - Additional Imaging
*/
// @Route("/opms/form/additional-imaging/validate/codes", "POST")
// @Api(Description="Form - Additional Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalImagingValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalImagingValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalImagingValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalImagingValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalImagingValidationResponse)", StatusCode=500)
// @DataContract
export class AdditionalImagingPostValidateWithCodes implements IReturn<AdditionalImagingValidationResponse>
{
    /**
    * The additional imaging to validate.
    */
    // @DataMember(Order=1)
    public form: AdditionalImaging;

    /**
    * The Code of the Institution associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the additional imaging to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdditionalImagingPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalImagingPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalImagingValidationResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/single/id/{Id}", "GET")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetSingleById implements IReturn<AdverseEventSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the adverse event to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdverseEventGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/single/ids", "GET")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetSingleByIds implements IReturn<AdverseEventSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the adverse event to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the adverse event to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the adverse event to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new adverse event if no adverse event is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<AdverseEventGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/single/codes", "GET")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetSingleByCodes implements IReturn<AdverseEventSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the adverse event to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the adverse event to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the adverse event to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new adverse event if no adverse event is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<AdverseEventGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/collection", "GET")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetCollection implements IReturn<AdverseEventCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<AdverseEventGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventCollectionResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetCollectionByIds implements IReturn<AdverseEventCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the adverse event to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdverseEventGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventCollectionResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventGetCollectionByCodes implements IReturn<AdverseEventCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the adverse event to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdverseEventGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventCollectionResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/save", "POST")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostSave implements IReturn<AdverseEventSingleResponse>
{
    /**
    * The adverse event to save.
    */
    // @DataMember(Order=1)
    public form: AdverseEvent;

    public constructor(init?: Partial<AdverseEventPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/save/ids", "POST")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostSaveWithIds implements IReturn<AdverseEventSingleResponse>
{
    /**
    * The adverse event to save.
    */
    // @DataMember(Order=1)
    public form: AdverseEvent;

    /**
    * The ID of the Institution associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the adverse event to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<AdverseEventPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/save/codes", "POST")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostSaveWithCodes implements IReturn<AdverseEventSingleResponse>
{
    /**
    * The adverse event to save.
    */
    // @DataMember(Order=1)
    public form: AdverseEvent;

    /**
    * The Code of the Institution associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the adverse event to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the adverse event to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<AdverseEventPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventSingleResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/delete", "DELETE")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=500)
// @DataContract
export class AdverseEventDelete implements IReturn<AdverseEventDeleteResponse>
{
    /**
    * The adverse event to delete.
    */
    // @DataMember(Order=1)
    public form: AdverseEvent;

    public constructor(init?: Partial<AdverseEventDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdverseEventDeleteResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=500)
// @DataContract
export class AdverseEventDeleteWithId implements IReturn<AdverseEventDeleteResponse>
{
    /**
    * The ID of the adverse event to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdverseEventDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdverseEventDeleteResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/delete/ids", "DELETE")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=500)
// @DataContract
export class AdverseEventDeleteWithIds implements IReturn<AdverseEventDeleteResponse>
{
    /**
    * The Patient ID of the adverse event to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the adverse event to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<AdverseEventDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdverseEventDeleteResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/delete/codes", "DELETE")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventDeleteResponse)", StatusCode=500)
// @DataContract
export class AdverseEventDeleteByCodes implements IReturn<AdverseEventDeleteResponse>
{
    /**
    * The Patient Study Number of the adverse event to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the adverse event to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<AdverseEventDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdverseEventDeleteResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/validate", "POST")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostValidate implements IReturn<AdverseEventValidationResponse>
{
    /**
    * The adverse event to validate.
    */
    // @DataMember(Order=1)
    public form: AdverseEvent;

    public constructor(init?: Partial<AdverseEventPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventValidationResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/validate/ids", "POST")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostValidateWithIds implements IReturn<AdverseEventValidationResponse>
{
    /**
    * The adverse event to validate.
    */
    // @DataMember(Order=1)
    public form: AdverseEvent;

    /**
    * The ID of the Institution associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdverseEventPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventValidationResponse(); }
}

/**
* Form - Adverse Event
*/
// @Route("/opms/form/adverse-event/validate/codes", "POST")
// @Api(Description="Form - Adverse Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventPostValidateWithCodes implements IReturn<AdverseEventValidationResponse>
{
    /**
    * The adverse event to validate.
    */
    // @DataMember(Order=1)
    public form: AdverseEvent;

    /**
    * The Code of the Institution associated with the adverse event to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the adverse event to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the adverse event to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the adverse event to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdverseEventPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventValidationResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/single/id/{Id}", "GET")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormGetSingleById implements IReturn<AdverseEventFormSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdverseEventFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventFormSingleResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/single/ids", "GET")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormGetSingleByIds implements IReturn<AdverseEventFormSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the adverse event form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new adverse event form if no adverse event form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<AdverseEventFormGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventFormSingleResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/single/codes", "GET")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormGetSingleByCodes implements IReturn<AdverseEventFormSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the adverse event form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new adverse event form if no adverse event form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<AdverseEventFormGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventFormSingleResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/collection", "GET")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormGetCollection implements IReturn<AdverseEventFormCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<AdverseEventFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventFormCollectionResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormGetCollectionByIds implements IReturn<AdverseEventFormCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdverseEventFormGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventFormCollectionResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormCollectionResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormGetCollectionByCodes implements IReturn<AdverseEventFormCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdverseEventFormGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdverseEventFormCollectionResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/save", "POST")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormPostSave implements IReturn<AdverseEventFormSingleResponse>
{
    /**
    * The adverse event form to save.
    */
    // @DataMember(Order=1)
    public form: AdverseEventForm;

    public constructor(init?: Partial<AdverseEventFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventFormSingleResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/save/ids", "POST")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormPostSaveWithIds implements IReturn<AdverseEventFormSingleResponse>
{
    /**
    * The adverse event form to save.
    */
    // @DataMember(Order=1)
    public form: AdverseEventForm;

    /**
    * The ID of the Institution associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the adverse event form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<AdverseEventFormPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventFormSingleResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/save/codes", "POST")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormSingleResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormPostSaveWithCodes implements IReturn<AdverseEventFormSingleResponse>
{
    /**
    * The adverse event form to save.
    */
    // @DataMember(Order=1)
    public form: AdverseEventForm;

    /**
    * The Code of the Institution associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the adverse event form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<AdverseEventFormPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventFormSingleResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/delete", "DELETE")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormDelete implements IReturn<AdverseEventFormDeleteResponse>
{
    /**
    * The adverse event form to delete.
    */
    // @DataMember(Order=1)
    public form: AdverseEventForm;

    public constructor(init?: Partial<AdverseEventFormDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdverseEventFormDeleteResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormDeleteWithId implements IReturn<AdverseEventFormDeleteResponse>
{
    /**
    * The ID of the adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdverseEventFormDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdverseEventFormDeleteResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/delete/ids", "DELETE")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormDeleteWithIds implements IReturn<AdverseEventFormDeleteResponse>
{
    /**
    * The Patient ID of the adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<AdverseEventFormDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdverseEventFormDeleteResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/delete/codes", "DELETE")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormDeleteResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormDeleteByCodes implements IReturn<AdverseEventFormDeleteResponse>
{
    /**
    * The Patient Study Number of the adverse event form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<AdverseEventFormDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdverseEventFormDeleteResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/validate", "POST")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormPostValidate implements IReturn<AdverseEventFormValidationResponse>
{
    /**
    * The adverse event form to validate.
    */
    // @DataMember(Order=1)
    public form: AdverseEventForm;

    public constructor(init?: Partial<AdverseEventFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventFormValidationResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/validate/ids", "POST")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormPostValidateWithIds implements IReturn<AdverseEventFormValidationResponse>
{
    /**
    * The adverse event form to validate.
    */
    // @DataMember(Order=1)
    public form: AdverseEventForm;

    /**
    * The ID of the Institution associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdverseEventFormPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventFormValidationResponse(); }
}

/**
* Form - Adverse Event Form
*/
// @Route("/opms/form/adverse-event-form/validate/codes", "POST")
// @Api(Description="Form - Adverse Event Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdverseEventFormValidationResponse)", StatusCode=500)
// @DataContract
export class AdverseEventFormPostValidateWithCodes implements IReturn<AdverseEventFormValidationResponse>
{
    /**
    * The adverse event form to validate.
    */
    // @DataMember(Order=1)
    public form: AdverseEventForm;

    /**
    * The Code of the Institution associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the adverse event form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdverseEventFormPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdverseEventFormPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdverseEventFormValidationResponse(); }
}

/**
* Form - Biomarker Sample
*/
// @Route("/opms/form/biomarker-sample/single/id/{Id}", "GET")
// @Api(Description="Form - Biomarker Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=500)
// @DataContract
export class BiomarkerSampleGetSingleById implements IReturn<BiomarkerSampleSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the biomarker sample to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BiomarkerSampleGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiomarkerSampleGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BiomarkerSampleSingleResponse(); }
}

/**
* Form - Biomarker Sample
*/
// @Route("/opms/form/biomarker-sample/single/ids", "GET")
// @Api(Description="Form - Biomarker Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=500)
// @DataContract
export class BiomarkerSampleGetSingleByIds implements IReturn<BiomarkerSampleSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the biomarker sample to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the biomarker sample to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the biomarker sample to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new biomarker sample if no biomarker sample is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<BiomarkerSampleGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiomarkerSampleGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BiomarkerSampleSingleResponse(); }
}

/**
* Form - Biomarker Sample
*/
// @Route("/opms/form/biomarker-sample/single/codes", "GET")
// @Api(Description="Form - Biomarker Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=500)
// @DataContract
export class BiomarkerSampleGetSingleByCodes implements IReturn<BiomarkerSampleSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the biomarker sample to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the biomarker sample to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the biomarker sample to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new biomarker sample if no biomarker sample is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<BiomarkerSampleGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiomarkerSampleGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BiomarkerSampleSingleResponse(); }
}

/**
* Form - Biomarker Sample
*/
// @Route("/opms/form/biomarker-sample/collection", "GET")
// @Api(Description="Form - Biomarker Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BiomarkerSampleCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BiomarkerSampleCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BiomarkerSampleCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BiomarkerSampleCollectionResponse)", StatusCode=500)
// @DataContract
export class BiomarkerSampleGetCollection implements IReturn<BiomarkerSampleCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<BiomarkerSampleGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiomarkerSampleGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BiomarkerSampleCollectionResponse(); }
}

/**
* Form - Biomarker Sample
*/
// @Route("/opms/form/biomarker-sample/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Biomarker Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BiomarkerSampleCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BiomarkerSampleCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BiomarkerSampleCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BiomarkerSampleCollectionResponse)", StatusCode=500)
// @DataContract
export class BiomarkerSampleGetCollectionByIds implements IReturn<BiomarkerSampleCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the biomarker sample to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<BiomarkerSampleGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiomarkerSampleGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BiomarkerSampleCollectionResponse(); }
}

/**
* Form - Biomarker Sample
*/
// @Route("/opms/form/biomarker-sample/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Biomarker Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BiomarkerSampleCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BiomarkerSampleCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BiomarkerSampleCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BiomarkerSampleCollectionResponse)", StatusCode=500)
// @DataContract
export class BiomarkerSampleGetCollectionByCodes implements IReturn<BiomarkerSampleCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<BiomarkerSampleGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiomarkerSampleGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BiomarkerSampleCollectionResponse(); }
}

/**
* Form - Biomarker Sample
*/
// @Route("/opms/form/biomarker-sample/save", "POST")
// @Api(Description="Form - Biomarker Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=500)
// @DataContract
export class BiomarkerSamplePostSave implements IReturn<BiomarkerSampleSingleResponse>
{
    /**
    * The biomarker sample to save.
    */
    // @DataMember(Order=1)
    public form: BiomarkerSample;

    public constructor(init?: Partial<BiomarkerSamplePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiomarkerSamplePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BiomarkerSampleSingleResponse(); }
}

/**
* Form - Biomarker Sample
*/
// @Route("/opms/form/biomarker-sample/save/ids", "POST")
// @Api(Description="Form - Biomarker Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=500)
// @DataContract
export class BiomarkerSamplePostSaveWithIds implements IReturn<BiomarkerSampleSingleResponse>
{
    /**
    * The biomarker sample to save.
    */
    // @DataMember(Order=1)
    public form: BiomarkerSample;

    /**
    * The ID of the Institution associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the biomarker sample to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<BiomarkerSamplePostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiomarkerSamplePostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BiomarkerSampleSingleResponse(); }
}

/**
* Form - Biomarker Sample
*/
// @Route("/opms/form/biomarker-sample/save/codes", "POST")
// @Api(Description="Form - Biomarker Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BiomarkerSampleSingleResponse)", StatusCode=500)
// @DataContract
export class BiomarkerSamplePostSaveWithCodes implements IReturn<BiomarkerSampleSingleResponse>
{
    /**
    * The biomarker sample to save.
    */
    // @DataMember(Order=1)
    public form: BiomarkerSample;

    /**
    * The Code of the Institution associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the biomarker sample to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<BiomarkerSamplePostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiomarkerSamplePostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BiomarkerSampleSingleResponse(); }
}

/**
* Form - Biomarker Sample
*/
// @Route("/opms/form/biomarker-sample/delete", "DELETE")
// @Api(Description="Form - Biomarker Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=500)
// @DataContract
export class BiomarkerSampleDelete implements IReturn<BiomarkerSampleDeleteResponse>
{
    /**
    * The biomarker sample to delete.
    */
    // @DataMember(Order=1)
    public form: BiomarkerSample;

    public constructor(init?: Partial<BiomarkerSampleDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiomarkerSampleDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BiomarkerSampleDeleteResponse(); }
}

/**
* Form - Biomarker Sample
*/
// @Route("/opms/form/biomarker-sample/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Biomarker Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=500)
// @DataContract
export class BiomarkerSampleDeleteWithId implements IReturn<BiomarkerSampleDeleteResponse>
{
    /**
    * The ID of the biomarker sample to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BiomarkerSampleDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiomarkerSampleDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BiomarkerSampleDeleteResponse(); }
}

/**
* Form - Biomarker Sample
*/
// @Route("/opms/form/biomarker-sample/delete/ids", "DELETE")
// @Api(Description="Form - Biomarker Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=500)
// @DataContract
export class BiomarkerSampleDeleteWithIds implements IReturn<BiomarkerSampleDeleteResponse>
{
    /**
    * The Patient ID of the biomarker sample to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the biomarker sample to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<BiomarkerSampleDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiomarkerSampleDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BiomarkerSampleDeleteResponse(); }
}

/**
* Form - Biomarker Sample
*/
// @Route("/opms/form/biomarker-sample/delete/codes", "DELETE")
// @Api(Description="Form - Biomarker Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BiomarkerSampleDeleteResponse)", StatusCode=500)
// @DataContract
export class BiomarkerSampleDeleteByCodes implements IReturn<BiomarkerSampleDeleteResponse>
{
    /**
    * The Patient Study Number of the biomarker sample to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the biomarker sample to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<BiomarkerSampleDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiomarkerSampleDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BiomarkerSampleDeleteResponse(); }
}

/**
* Form - Biomarker Sample
*/
// @Route("/opms/form/biomarker-sample/validate", "POST")
// @Api(Description="Form - Biomarker Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BiomarkerSampleValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BiomarkerSampleValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BiomarkerSampleValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BiomarkerSampleValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BiomarkerSampleValidationResponse)", StatusCode=500)
// @DataContract
export class BiomarkerSamplePostValidate implements IReturn<BiomarkerSampleValidationResponse>
{
    /**
    * The biomarker sample to validate.
    */
    // @DataMember(Order=1)
    public form: BiomarkerSample;

    public constructor(init?: Partial<BiomarkerSamplePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiomarkerSamplePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BiomarkerSampleValidationResponse(); }
}

/**
* Form - Biomarker Sample
*/
// @Route("/opms/form/biomarker-sample/validate/ids", "POST")
// @Api(Description="Form - Biomarker Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BiomarkerSampleValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BiomarkerSampleValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BiomarkerSampleValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BiomarkerSampleValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BiomarkerSampleValidationResponse)", StatusCode=500)
// @DataContract
export class BiomarkerSamplePostValidateWithIds implements IReturn<BiomarkerSampleValidationResponse>
{
    /**
    * The biomarker sample to validate.
    */
    // @DataMember(Order=1)
    public form: BiomarkerSample;

    /**
    * The ID of the Institution associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<BiomarkerSamplePostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiomarkerSamplePostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BiomarkerSampleValidationResponse(); }
}

/**
* Form - Biomarker Sample
*/
// @Route("/opms/form/biomarker-sample/validate/codes", "POST")
// @Api(Description="Form - Biomarker Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BiomarkerSampleValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BiomarkerSampleValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BiomarkerSampleValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BiomarkerSampleValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BiomarkerSampleValidationResponse)", StatusCode=500)
// @DataContract
export class BiomarkerSamplePostValidateWithCodes implements IReturn<BiomarkerSampleValidationResponse>
{
    /**
    * The biomarker sample to validate.
    */
    // @DataMember(Order=1)
    public form: BiomarkerSample;

    /**
    * The Code of the Institution associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the biomarker sample to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<BiomarkerSamplePostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BiomarkerSamplePostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BiomarkerSampleValidationResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/single/id/{Id}", "GET")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=500)
// @DataContract
export class BloodsGetSingleById implements IReturn<BloodsSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the bloods to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BloodsGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BloodsSingleResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/single/ids", "GET")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=500)
// @DataContract
export class BloodsGetSingleByIds implements IReturn<BloodsSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the bloods to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bloods to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the bloods to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new bloods if no bloods is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<BloodsGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BloodsSingleResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/single/codes", "GET")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=500)
// @DataContract
export class BloodsGetSingleByCodes implements IReturn<BloodsSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the bloods to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bloods to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the bloods to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new bloods if no bloods is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<BloodsGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BloodsSingleResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/collection", "GET")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=500)
// @DataContract
export class BloodsGetCollection implements IReturn<BloodsCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<BloodsGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BloodsCollectionResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=500)
// @DataContract
export class BloodsGetCollectionByIds implements IReturn<BloodsCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the bloods to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<BloodsGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BloodsCollectionResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=500)
// @DataContract
export class BloodsGetCollectionByCodes implements IReturn<BloodsCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the bloods to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<BloodsGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BloodsCollectionResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/save", "POST")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=500)
// @DataContract
export class BloodsPostSave implements IReturn<BloodsSingleResponse>
{
    /**
    * The bloods to save.
    */
    // @DataMember(Order=1)
    public form: Bloods;

    public constructor(init?: Partial<BloodsPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BloodsSingleResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/save/ids", "POST")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=500)
// @DataContract
export class BloodsPostSaveWithIds implements IReturn<BloodsSingleResponse>
{
    /**
    * The bloods to save.
    */
    // @DataMember(Order=1)
    public form: Bloods;

    /**
    * The ID of the Institution associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the bloods to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<BloodsPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BloodsSingleResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/save/codes", "POST")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=500)
// @DataContract
export class BloodsPostSaveWithCodes implements IReturn<BloodsSingleResponse>
{
    /**
    * The bloods to save.
    */
    // @DataMember(Order=1)
    public form: Bloods;

    /**
    * The Code of the Institution associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the bloods to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the bloods to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<BloodsPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BloodsSingleResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/delete", "DELETE")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=500)
// @DataContract
export class BloodsDelete implements IReturn<BloodsDeleteResponse>
{
    /**
    * The bloods to delete.
    */
    // @DataMember(Order=1)
    public form: Bloods;

    public constructor(init?: Partial<BloodsDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BloodsDeleteResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=500)
// @DataContract
export class BloodsDeleteWithId implements IReturn<BloodsDeleteResponse>
{
    /**
    * The ID of the bloods to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BloodsDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BloodsDeleteResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/delete/ids", "DELETE")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=500)
// @DataContract
export class BloodsDeleteWithIds implements IReturn<BloodsDeleteResponse>
{
    /**
    * The Patient ID of the bloods to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bloods to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<BloodsDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BloodsDeleteResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/delete/codes", "DELETE")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=500)
// @DataContract
export class BloodsDeleteByCodes implements IReturn<BloodsDeleteResponse>
{
    /**
    * The Patient Study Number of the bloods to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bloods to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<BloodsDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BloodsDeleteResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/validate", "POST")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=500)
// @DataContract
export class BloodsPostValidate implements IReturn<BloodsValidationResponse>
{
    /**
    * The bloods to validate.
    */
    // @DataMember(Order=1)
    public form: Bloods;

    public constructor(init?: Partial<BloodsPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BloodsValidationResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/validate/ids", "POST")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=500)
// @DataContract
export class BloodsPostValidateWithIds implements IReturn<BloodsValidationResponse>
{
    /**
    * The bloods to validate.
    */
    // @DataMember(Order=1)
    public form: Bloods;

    /**
    * The ID of the Institution associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<BloodsPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BloodsValidationResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/validate/codes", "POST")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=500)
// @DataContract
export class BloodsPostValidateWithCodes implements IReturn<BloodsValidationResponse>
{
    /**
    * The bloods to validate.
    */
    // @DataMember(Order=1)
    public form: Bloods;

    /**
    * The Code of the Institution associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the bloods to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<BloodsPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BloodsValidationResponse(); }
}

/**
* Form - Bm Non Target Lesion
*/
// @Route("/opms/form/bm-non-target-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - Bm Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BmNonTargetLesionGetSingleById implements IReturn<BmNonTargetLesionSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the bm non target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BmNonTargetLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmNonTargetLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BmNonTargetLesionSingleResponse(); }
}

/**
* Form - Bm Non Target Lesion
*/
// @Route("/opms/form/bm-non-target-lesion/single/ids", "GET")
// @Api(Description="Form - Bm Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BmNonTargetLesionGetSingleByIds implements IReturn<BmNonTargetLesionSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the bm non target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bm non target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the bm non target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new bm non target lesion if no bm non target lesion is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<BmNonTargetLesionGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmNonTargetLesionGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BmNonTargetLesionSingleResponse(); }
}

/**
* Form - Bm Non Target Lesion
*/
// @Route("/opms/form/bm-non-target-lesion/single/codes", "GET")
// @Api(Description="Form - Bm Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BmNonTargetLesionGetSingleByCodes implements IReturn<BmNonTargetLesionSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the bm non target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bm non target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the bm non target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new bm non target lesion if no bm non target lesion is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<BmNonTargetLesionGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmNonTargetLesionGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BmNonTargetLesionSingleResponse(); }
}

/**
* Form - Bm Non Target Lesion
*/
// @Route("/opms/form/bm-non-target-lesion/collection", "GET")
// @Api(Description="Form - Bm Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class BmNonTargetLesionGetCollection implements IReturn<BmNonTargetLesionCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<BmNonTargetLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmNonTargetLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BmNonTargetLesionCollectionResponse(); }
}

/**
* Form - Bm Non Target Lesion
*/
// @Route("/opms/form/bm-non-target-lesion/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Bm Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class BmNonTargetLesionGetCollectionByIds implements IReturn<BmNonTargetLesionCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the bm non target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<BmNonTargetLesionGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmNonTargetLesionGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BmNonTargetLesionCollectionResponse(); }
}

/**
* Form - Bm Non Target Lesion
*/
// @Route("/opms/form/bm-non-target-lesion/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Bm Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class BmNonTargetLesionGetCollectionByCodes implements IReturn<BmNonTargetLesionCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<BmNonTargetLesionGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmNonTargetLesionGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BmNonTargetLesionCollectionResponse(); }
}

/**
* Form - Bm Non Target Lesion
*/
// @Route("/opms/form/bm-non-target-lesion/save", "POST")
// @Api(Description="Form - Bm Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BmNonTargetLesionPostSave implements IReturn<BmNonTargetLesionSingleResponse>
{
    /**
    * The bm non target lesion to save.
    */
    // @DataMember(Order=1)
    public form: BmNonTargetLesion;

    public constructor(init?: Partial<BmNonTargetLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmNonTargetLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BmNonTargetLesionSingleResponse(); }
}

/**
* Form - Bm Non Target Lesion
*/
// @Route("/opms/form/bm-non-target-lesion/save/ids", "POST")
// @Api(Description="Form - Bm Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BmNonTargetLesionPostSaveWithIds implements IReturn<BmNonTargetLesionSingleResponse>
{
    /**
    * The bm non target lesion to save.
    */
    // @DataMember(Order=1)
    public form: BmNonTargetLesion;

    /**
    * The ID of the Institution associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the bm non target lesion to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<BmNonTargetLesionPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmNonTargetLesionPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BmNonTargetLesionSingleResponse(); }
}

/**
* Form - Bm Non Target Lesion
*/
// @Route("/opms/form/bm-non-target-lesion/save/codes", "POST")
// @Api(Description="Form - Bm Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BmNonTargetLesionPostSaveWithCodes implements IReturn<BmNonTargetLesionSingleResponse>
{
    /**
    * The bm non target lesion to save.
    */
    // @DataMember(Order=1)
    public form: BmNonTargetLesion;

    /**
    * The Code of the Institution associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the bm non target lesion to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<BmNonTargetLesionPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmNonTargetLesionPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BmNonTargetLesionSingleResponse(); }
}

/**
* Form - Bm Non Target Lesion
*/
// @Route("/opms/form/bm-non-target-lesion/delete", "DELETE")
// @Api(Description="Form - Bm Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class BmNonTargetLesionDelete implements IReturn<BmNonTargetLesionDeleteResponse>
{
    /**
    * The bm non target lesion to delete.
    */
    // @DataMember(Order=1)
    public form: BmNonTargetLesion;

    public constructor(init?: Partial<BmNonTargetLesionDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmNonTargetLesionDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BmNonTargetLesionDeleteResponse(); }
}

/**
* Form - Bm Non Target Lesion
*/
// @Route("/opms/form/bm-non-target-lesion/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Bm Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class BmNonTargetLesionDeleteWithId implements IReturn<BmNonTargetLesionDeleteResponse>
{
    /**
    * The ID of the bm non target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BmNonTargetLesionDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmNonTargetLesionDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BmNonTargetLesionDeleteResponse(); }
}

/**
* Form - Bm Non Target Lesion
*/
// @Route("/opms/form/bm-non-target-lesion/delete/ids", "DELETE")
// @Api(Description="Form - Bm Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class BmNonTargetLesionDeleteWithIds implements IReturn<BmNonTargetLesionDeleteResponse>
{
    /**
    * The Patient ID of the bm non target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bm non target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<BmNonTargetLesionDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmNonTargetLesionDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BmNonTargetLesionDeleteResponse(); }
}

/**
* Form - Bm Non Target Lesion
*/
// @Route("/opms/form/bm-non-target-lesion/delete/codes", "DELETE")
// @Api(Description="Form - Bm Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class BmNonTargetLesionDeleteByCodes implements IReturn<BmNonTargetLesionDeleteResponse>
{
    /**
    * The Patient Study Number of the bm non target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bm non target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<BmNonTargetLesionDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmNonTargetLesionDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BmNonTargetLesionDeleteResponse(); }
}

/**
* Form - Bm Non Target Lesion
*/
// @Route("/opms/form/bm-non-target-lesion/validate", "POST")
// @Api(Description="Form - Bm Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionValidationResponse)", StatusCode=500)
// @DataContract
export class BmNonTargetLesionPostValidate implements IReturn<BmNonTargetLesionValidationResponse>
{
    /**
    * The bm non target lesion to validate.
    */
    // @DataMember(Order=1)
    public form: BmNonTargetLesion;

    public constructor(init?: Partial<BmNonTargetLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmNonTargetLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BmNonTargetLesionValidationResponse(); }
}

/**
* Form - Bm Non Target Lesion
*/
// @Route("/opms/form/bm-non-target-lesion/validate/ids", "POST")
// @Api(Description="Form - Bm Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionValidationResponse)", StatusCode=500)
// @DataContract
export class BmNonTargetLesionPostValidateWithIds implements IReturn<BmNonTargetLesionValidationResponse>
{
    /**
    * The bm non target lesion to validate.
    */
    // @DataMember(Order=1)
    public form: BmNonTargetLesion;

    /**
    * The ID of the Institution associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<BmNonTargetLesionPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmNonTargetLesionPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BmNonTargetLesionValidationResponse(); }
}

/**
* Form - Bm Non Target Lesion
*/
// @Route("/opms/form/bm-non-target-lesion/validate/codes", "POST")
// @Api(Description="Form - Bm Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmNonTargetLesionValidationResponse)", StatusCode=500)
// @DataContract
export class BmNonTargetLesionPostValidateWithCodes implements IReturn<BmNonTargetLesionValidationResponse>
{
    /**
    * The bm non target lesion to validate.
    */
    // @DataMember(Order=1)
    public form: BmNonTargetLesion;

    /**
    * The Code of the Institution associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bm non target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<BmNonTargetLesionPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmNonTargetLesionPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BmNonTargetLesionValidationResponse(); }
}

/**
* Form - Bm Target Lesion
*/
// @Route("/opms/form/bm-target-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - Bm Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BmTargetLesionGetSingleById implements IReturn<BmTargetLesionSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the bm target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BmTargetLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmTargetLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BmTargetLesionSingleResponse(); }
}

/**
* Form - Bm Target Lesion
*/
// @Route("/opms/form/bm-target-lesion/single/ids", "GET")
// @Api(Description="Form - Bm Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BmTargetLesionGetSingleByIds implements IReturn<BmTargetLesionSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the bm target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bm target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the bm target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new bm target lesion if no bm target lesion is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<BmTargetLesionGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmTargetLesionGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BmTargetLesionSingleResponse(); }
}

/**
* Form - Bm Target Lesion
*/
// @Route("/opms/form/bm-target-lesion/single/codes", "GET")
// @Api(Description="Form - Bm Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BmTargetLesionGetSingleByCodes implements IReturn<BmTargetLesionSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the bm target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bm target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the bm target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new bm target lesion if no bm target lesion is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<BmTargetLesionGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmTargetLesionGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BmTargetLesionSingleResponse(); }
}

/**
* Form - Bm Target Lesion
*/
// @Route("/opms/form/bm-target-lesion/collection", "GET")
// @Api(Description="Form - Bm Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmTargetLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmTargetLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmTargetLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmTargetLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class BmTargetLesionGetCollection implements IReturn<BmTargetLesionCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<BmTargetLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmTargetLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BmTargetLesionCollectionResponse(); }
}

/**
* Form - Bm Target Lesion
*/
// @Route("/opms/form/bm-target-lesion/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Bm Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmTargetLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmTargetLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmTargetLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmTargetLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class BmTargetLesionGetCollectionByIds implements IReturn<BmTargetLesionCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the bm target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<BmTargetLesionGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmTargetLesionGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BmTargetLesionCollectionResponse(); }
}

/**
* Form - Bm Target Lesion
*/
// @Route("/opms/form/bm-target-lesion/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Bm Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmTargetLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmTargetLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmTargetLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmTargetLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class BmTargetLesionGetCollectionByCodes implements IReturn<BmTargetLesionCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<BmTargetLesionGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmTargetLesionGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BmTargetLesionCollectionResponse(); }
}

/**
* Form - Bm Target Lesion
*/
// @Route("/opms/form/bm-target-lesion/save", "POST")
// @Api(Description="Form - Bm Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BmTargetLesionPostSave implements IReturn<BmTargetLesionSingleResponse>
{
    /**
    * The bm target lesion to save.
    */
    // @DataMember(Order=1)
    public form: BmTargetLesion;

    public constructor(init?: Partial<BmTargetLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmTargetLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BmTargetLesionSingleResponse(); }
}

/**
* Form - Bm Target Lesion
*/
// @Route("/opms/form/bm-target-lesion/save/ids", "POST")
// @Api(Description="Form - Bm Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BmTargetLesionPostSaveWithIds implements IReturn<BmTargetLesionSingleResponse>
{
    /**
    * The bm target lesion to save.
    */
    // @DataMember(Order=1)
    public form: BmTargetLesion;

    /**
    * The ID of the Institution associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the bm target lesion to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<BmTargetLesionPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmTargetLesionPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BmTargetLesionSingleResponse(); }
}

/**
* Form - Bm Target Lesion
*/
// @Route("/opms/form/bm-target-lesion/save/codes", "POST")
// @Api(Description="Form - Bm Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BmTargetLesionPostSaveWithCodes implements IReturn<BmTargetLesionSingleResponse>
{
    /**
    * The bm target lesion to save.
    */
    // @DataMember(Order=1)
    public form: BmTargetLesion;

    /**
    * The Code of the Institution associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the bm target lesion to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<BmTargetLesionPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmTargetLesionPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BmTargetLesionSingleResponse(); }
}

/**
* Form - Bm Target Lesion
*/
// @Route("/opms/form/bm-target-lesion/delete", "DELETE")
// @Api(Description="Form - Bm Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class BmTargetLesionDelete implements IReturn<BmTargetLesionDeleteResponse>
{
    /**
    * The bm target lesion to delete.
    */
    // @DataMember(Order=1)
    public form: BmTargetLesion;

    public constructor(init?: Partial<BmTargetLesionDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmTargetLesionDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BmTargetLesionDeleteResponse(); }
}

/**
* Form - Bm Target Lesion
*/
// @Route("/opms/form/bm-target-lesion/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Bm Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class BmTargetLesionDeleteWithId implements IReturn<BmTargetLesionDeleteResponse>
{
    /**
    * The ID of the bm target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BmTargetLesionDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmTargetLesionDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BmTargetLesionDeleteResponse(); }
}

/**
* Form - Bm Target Lesion
*/
// @Route("/opms/form/bm-target-lesion/delete/ids", "DELETE")
// @Api(Description="Form - Bm Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class BmTargetLesionDeleteWithIds implements IReturn<BmTargetLesionDeleteResponse>
{
    /**
    * The Patient ID of the bm target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bm target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<BmTargetLesionDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmTargetLesionDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BmTargetLesionDeleteResponse(); }
}

/**
* Form - Bm Target Lesion
*/
// @Route("/opms/form/bm-target-lesion/delete/codes", "DELETE")
// @Api(Description="Form - Bm Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class BmTargetLesionDeleteByCodes implements IReturn<BmTargetLesionDeleteResponse>
{
    /**
    * The Patient Study Number of the bm target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bm target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<BmTargetLesionDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmTargetLesionDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BmTargetLesionDeleteResponse(); }
}

/**
* Form - Bm Target Lesion
*/
// @Route("/opms/form/bm-target-lesion/validate", "POST")
// @Api(Description="Form - Bm Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmTargetLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmTargetLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmTargetLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmTargetLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmTargetLesionValidationResponse)", StatusCode=500)
// @DataContract
export class BmTargetLesionPostValidate implements IReturn<BmTargetLesionValidationResponse>
{
    /**
    * The bm target lesion to validate.
    */
    // @DataMember(Order=1)
    public form: BmTargetLesion;

    public constructor(init?: Partial<BmTargetLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmTargetLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BmTargetLesionValidationResponse(); }
}

/**
* Form - Bm Target Lesion
*/
// @Route("/opms/form/bm-target-lesion/validate/ids", "POST")
// @Api(Description="Form - Bm Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmTargetLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmTargetLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmTargetLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmTargetLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmTargetLesionValidationResponse)", StatusCode=500)
// @DataContract
export class BmTargetLesionPostValidateWithIds implements IReturn<BmTargetLesionValidationResponse>
{
    /**
    * The bm target lesion to validate.
    */
    // @DataMember(Order=1)
    public form: BmTargetLesion;

    /**
    * The ID of the Institution associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<BmTargetLesionPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmTargetLesionPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BmTargetLesionValidationResponse(); }
}

/**
* Form - Bm Target Lesion
*/
// @Route("/opms/form/bm-target-lesion/validate/codes", "POST")
// @Api(Description="Form - Bm Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BmTargetLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BmTargetLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BmTargetLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BmTargetLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BmTargetLesionValidationResponse)", StatusCode=500)
// @DataContract
export class BmTargetLesionPostValidateWithCodes implements IReturn<BmTargetLesionValidationResponse>
{
    /**
    * The bm target lesion to validate.
    */
    // @DataMember(Order=1)
    public form: BmTargetLesion;

    /**
    * The Code of the Institution associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bm target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<BmTargetLesionPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BmTargetLesionPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BmTargetLesionValidationResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/single/id/{Id}", "GET")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsGetSingleById implements IReturn<CancerDetailsSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CancerDetailsGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CancerDetailsSingleResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/single/ids", "GET")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsGetSingleByIds implements IReturn<CancerDetailsSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the cancer details to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new cancer details if no cancer details is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<CancerDetailsGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CancerDetailsSingleResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/single/codes", "GET")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsGetSingleByCodes implements IReturn<CancerDetailsSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the cancer details to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new cancer details if no cancer details is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<CancerDetailsGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CancerDetailsSingleResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/collection", "GET")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsGetCollection implements IReturn<CancerDetailsCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<CancerDetailsGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CancerDetailsCollectionResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsGetCollectionByIds implements IReturn<CancerDetailsCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<CancerDetailsGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CancerDetailsCollectionResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsGetCollectionByCodes implements IReturn<CancerDetailsCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<CancerDetailsGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CancerDetailsCollectionResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/save", "POST")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsPostSave implements IReturn<CancerDetailsSingleResponse>
{
    /**
    * The cancer details to save.
    */
    // @DataMember(Order=1)
    public form: CancerDetails;

    public constructor(init?: Partial<CancerDetailsPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CancerDetailsSingleResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/save/ids", "POST")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsPostSaveWithIds implements IReturn<CancerDetailsSingleResponse>
{
    /**
    * The cancer details to save.
    */
    // @DataMember(Order=1)
    public form: CancerDetails;

    /**
    * The ID of the Institution associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the cancer details to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<CancerDetailsPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CancerDetailsSingleResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/save/codes", "POST")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsPostSaveWithCodes implements IReturn<CancerDetailsSingleResponse>
{
    /**
    * The cancer details to save.
    */
    // @DataMember(Order=1)
    public form: CancerDetails;

    /**
    * The Code of the Institution associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the cancer details to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<CancerDetailsPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CancerDetailsSingleResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/delete", "DELETE")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsDelete implements IReturn<CancerDetailsDeleteResponse>
{
    /**
    * The cancer details to delete.
    */
    // @DataMember(Order=1)
    public form: CancerDetails;

    public constructor(init?: Partial<CancerDetailsDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CancerDetailsDeleteResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsDeleteWithId implements IReturn<CancerDetailsDeleteResponse>
{
    /**
    * The ID of the cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CancerDetailsDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CancerDetailsDeleteResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/delete/ids", "DELETE")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsDeleteWithIds implements IReturn<CancerDetailsDeleteResponse>
{
    /**
    * The Patient ID of the cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<CancerDetailsDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CancerDetailsDeleteResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/delete/codes", "DELETE")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsDeleteByCodes implements IReturn<CancerDetailsDeleteResponse>
{
    /**
    * The Patient Study Number of the cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<CancerDetailsDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CancerDetailsDeleteResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/validate", "POST")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsPostValidate implements IReturn<CancerDetailsValidationResponse>
{
    /**
    * The cancer details to validate.
    */
    // @DataMember(Order=1)
    public form: CancerDetails;

    public constructor(init?: Partial<CancerDetailsPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CancerDetailsValidationResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/validate/ids", "POST")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsPostValidateWithIds implements IReturn<CancerDetailsValidationResponse>
{
    /**
    * The cancer details to validate.
    */
    // @DataMember(Order=1)
    public form: CancerDetails;

    /**
    * The ID of the Institution associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<CancerDetailsPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CancerDetailsValidationResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/validate/codes", "POST")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsPostValidateWithCodes implements IReturn<CancerDetailsValidationResponse>
{
    /**
    * The cancer details to validate.
    */
    // @DataMember(Order=1)
    public form: CancerDetails;

    /**
    * The Code of the Institution associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<CancerDetailsPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CancerDetailsValidationResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/single/id/{Id}", "GET")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentGetSingleById implements IReturn<ClinicalAssessmentSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ClinicalAssessmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentSingleResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/single/ids", "GET")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentGetSingleByIds implements IReturn<ClinicalAssessmentSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the clinical assessment to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new clinical assessment if no clinical assessment is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ClinicalAssessmentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentSingleResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/single/codes", "GET")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentGetSingleByCodes implements IReturn<ClinicalAssessmentSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the clinical assessment to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new clinical assessment if no clinical assessment is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ClinicalAssessmentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentSingleResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/collection", "GET")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentGetCollection implements IReturn<ClinicalAssessmentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ClinicalAssessmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentCollectionResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentGetCollectionByIds implements IReturn<ClinicalAssessmentCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ClinicalAssessmentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentCollectionResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentGetCollectionByCodes implements IReturn<ClinicalAssessmentCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ClinicalAssessmentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentCollectionResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/save", "POST")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentPostSave implements IReturn<ClinicalAssessmentSingleResponse>
{
    /**
    * The clinical assessment to save.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    public constructor(init?: Partial<ClinicalAssessmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentSingleResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/save/ids", "POST")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentPostSaveWithIds implements IReturn<ClinicalAssessmentSingleResponse>
{
    /**
    * The clinical assessment to save.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    /**
    * The ID of the Institution associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ClinicalAssessmentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentSingleResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/save/codes", "POST")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentPostSaveWithCodes implements IReturn<ClinicalAssessmentSingleResponse>
{
    /**
    * The clinical assessment to save.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    /**
    * The Code of the Institution associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ClinicalAssessmentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentSingleResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/delete", "DELETE")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentDelete implements IReturn<ClinicalAssessmentDeleteResponse>
{
    /**
    * The clinical assessment to delete.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    public constructor(init?: Partial<ClinicalAssessmentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ClinicalAssessmentDeleteResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentDeleteWithId implements IReturn<ClinicalAssessmentDeleteResponse>
{
    /**
    * The ID of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ClinicalAssessmentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ClinicalAssessmentDeleteResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/delete/ids", "DELETE")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentDeleteWithIds implements IReturn<ClinicalAssessmentDeleteResponse>
{
    /**
    * The Patient ID of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ClinicalAssessmentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ClinicalAssessmentDeleteResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/delete/codes", "DELETE")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentDeleteByCodes implements IReturn<ClinicalAssessmentDeleteResponse>
{
    /**
    * The Patient Study Number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ClinicalAssessmentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ClinicalAssessmentDeleteResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/validate", "POST")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentPostValidate implements IReturn<ClinicalAssessmentValidationResponse>
{
    /**
    * The clinical assessment to validate.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    public constructor(init?: Partial<ClinicalAssessmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentValidationResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/validate/ids", "POST")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentPostValidateWithIds implements IReturn<ClinicalAssessmentValidationResponse>
{
    /**
    * The clinical assessment to validate.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    /**
    * The ID of the Institution associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ClinicalAssessmentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentValidationResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/validate/codes", "POST")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentPostValidateWithCodes implements IReturn<ClinicalAssessmentValidationResponse>
{
    /**
    * The clinical assessment to validate.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    /**
    * The Code of the Institution associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ClinicalAssessmentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentValidationResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/single/id/{Id}", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleById implements IReturn<ConcomitantMedicationSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/single/ids", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleByIds implements IReturn<ConcomitantMedicationSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the concomitant medication to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new concomitant medication if no concomitant medication is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/single/codes", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleByCodes implements IReturn<ConcomitantMedicationSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the concomitant medication to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new concomitant medication if no concomitant medication is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/collection", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetCollection implements IReturn<ConcomitantMedicationCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ConcomitantMedicationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationCollectionResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetCollectionByIds implements IReturn<ConcomitantMedicationCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationCollectionResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetCollectionByCodes implements IReturn<ConcomitantMedicationCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationCollectionResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/save", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostSave implements IReturn<ConcomitantMedicationSingleResponse>
{
    /**
    * The concomitant medication to save.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/save/ids", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostSaveWithIds implements IReturn<ConcomitantMedicationSingleResponse>
{
    /**
    * The concomitant medication to save.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    /**
    * The ID of the Institution associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/save/codes", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostSaveWithCodes implements IReturn<ConcomitantMedicationSingleResponse>
{
    /**
    * The concomitant medication to save.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    /**
    * The Code of the Institution associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/delete", "DELETE")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationDelete implements IReturn<ConcomitantMedicationDeleteResponse>
{
    /**
    * The concomitant medication to delete.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationDeleteResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationDeleteWithId implements IReturn<ConcomitantMedicationDeleteResponse>
{
    /**
    * The ID of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationDeleteResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/delete/ids", "DELETE")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationDeleteWithIds implements IReturn<ConcomitantMedicationDeleteResponse>
{
    /**
    * The Patient ID of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationDeleteResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/delete/codes", "DELETE")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationDeleteByCodes implements IReturn<ConcomitantMedicationDeleteResponse>
{
    /**
    * The Patient Study Number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationDeleteResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/validate", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostValidate implements IReturn<ConcomitantMedicationValidationResponse>
{
    /**
    * The concomitant medication to validate.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationValidationResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/validate/ids", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostValidateWithIds implements IReturn<ConcomitantMedicationValidationResponse>
{
    /**
    * The concomitant medication to validate.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    /**
    * The ID of the Institution associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationValidationResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/validate/codes", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostValidateWithCodes implements IReturn<ConcomitantMedicationValidationResponse>
{
    /**
    * The concomitant medication to validate.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    /**
    * The Code of the Institution associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationValidationResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/single/id/{Id}", "GET")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormGetSingleById implements IReturn<ConcomitantMedicationFormSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationFormSingleResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/single/ids", "GET")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormGetSingleByIds implements IReturn<ConcomitantMedicationFormSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the concomitant medication form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new concomitant medication form if no concomitant medication form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationFormGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationFormSingleResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/single/codes", "GET")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormGetSingleByCodes implements IReturn<ConcomitantMedicationFormSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the concomitant medication form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new concomitant medication form if no concomitant medication form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationFormGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationFormSingleResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/collection", "GET")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormGetCollection implements IReturn<ConcomitantMedicationFormCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ConcomitantMedicationFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationFormCollectionResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormGetCollectionByIds implements IReturn<ConcomitantMedicationFormCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationFormCollectionResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormGetCollectionByCodes implements IReturn<ConcomitantMedicationFormCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationFormCollectionResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/save", "POST")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormPostSave implements IReturn<ConcomitantMedicationFormSingleResponse>
{
    /**
    * The concomitant medication form to save.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    public constructor(init?: Partial<ConcomitantMedicationFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationFormSingleResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/save/ids", "POST")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormPostSaveWithIds implements IReturn<ConcomitantMedicationFormSingleResponse>
{
    /**
    * The concomitant medication form to save.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    /**
    * The ID of the Institution associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationFormPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationFormSingleResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/save/codes", "POST")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormPostSaveWithCodes implements IReturn<ConcomitantMedicationFormSingleResponse>
{
    /**
    * The concomitant medication form to save.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    /**
    * The Code of the Institution associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationFormPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationFormSingleResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/delete", "DELETE")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormDelete implements IReturn<ConcomitantMedicationFormDeleteResponse>
{
    /**
    * The concomitant medication form to delete.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    public constructor(init?: Partial<ConcomitantMedicationFormDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationFormDeleteResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormDeleteWithId implements IReturn<ConcomitantMedicationFormDeleteResponse>
{
    /**
    * The ID of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationFormDeleteResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/delete/ids", "DELETE")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormDeleteWithIds implements IReturn<ConcomitantMedicationFormDeleteResponse>
{
    /**
    * The Patient ID of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationFormDeleteResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/delete/codes", "DELETE")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormDeleteByCodes implements IReturn<ConcomitantMedicationFormDeleteResponse>
{
    /**
    * The Patient Study Number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationFormDeleteResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/validate", "POST")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormPostValidate implements IReturn<ConcomitantMedicationFormValidationResponse>
{
    /**
    * The concomitant medication form to validate.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    public constructor(init?: Partial<ConcomitantMedicationFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationFormValidationResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/validate/ids", "POST")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormPostValidateWithIds implements IReturn<ConcomitantMedicationFormValidationResponse>
{
    /**
    * The concomitant medication form to validate.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    /**
    * The ID of the Institution associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationFormValidationResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/validate/codes", "POST")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormPostValidateWithCodes implements IReturn<ConcomitantMedicationFormValidationResponse>
{
    /**
    * The concomitant medication form to validate.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    /**
    * The Code of the Institution associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationFormValidationResponse(); }
}

/**
* Form - Ct Non Target Lesion
*/
// @Route("/opms/form/ct-non-target-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - Ct Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class CtNonTargetLesionGetSingleById implements IReturn<CtNonTargetLesionSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the ct non target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CtNonTargetLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtNonTargetLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CtNonTargetLesionSingleResponse(); }
}

/**
* Form - Ct Non Target Lesion
*/
// @Route("/opms/form/ct-non-target-lesion/single/ids", "GET")
// @Api(Description="Form - Ct Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class CtNonTargetLesionGetSingleByIds implements IReturn<CtNonTargetLesionSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the ct non target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the ct non target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the ct non target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new ct non target lesion if no ct non target lesion is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<CtNonTargetLesionGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtNonTargetLesionGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CtNonTargetLesionSingleResponse(); }
}

/**
* Form - Ct Non Target Lesion
*/
// @Route("/opms/form/ct-non-target-lesion/single/codes", "GET")
// @Api(Description="Form - Ct Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class CtNonTargetLesionGetSingleByCodes implements IReturn<CtNonTargetLesionSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the ct non target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the ct non target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the ct non target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new ct non target lesion if no ct non target lesion is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<CtNonTargetLesionGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtNonTargetLesionGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CtNonTargetLesionSingleResponse(); }
}

/**
* Form - Ct Non Target Lesion
*/
// @Route("/opms/form/ct-non-target-lesion/collection", "GET")
// @Api(Description="Form - Ct Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class CtNonTargetLesionGetCollection implements IReturn<CtNonTargetLesionCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<CtNonTargetLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtNonTargetLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CtNonTargetLesionCollectionResponse(); }
}

/**
* Form - Ct Non Target Lesion
*/
// @Route("/opms/form/ct-non-target-lesion/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Ct Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class CtNonTargetLesionGetCollectionByIds implements IReturn<CtNonTargetLesionCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the ct non target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<CtNonTargetLesionGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtNonTargetLesionGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CtNonTargetLesionCollectionResponse(); }
}

/**
* Form - Ct Non Target Lesion
*/
// @Route("/opms/form/ct-non-target-lesion/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Ct Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class CtNonTargetLesionGetCollectionByCodes implements IReturn<CtNonTargetLesionCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<CtNonTargetLesionGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtNonTargetLesionGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CtNonTargetLesionCollectionResponse(); }
}

/**
* Form - Ct Non Target Lesion
*/
// @Route("/opms/form/ct-non-target-lesion/save", "POST")
// @Api(Description="Form - Ct Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class CtNonTargetLesionPostSave implements IReturn<CtNonTargetLesionSingleResponse>
{
    /**
    * The ct non target lesion to save.
    */
    // @DataMember(Order=1)
    public form: CtNonTargetLesion;

    public constructor(init?: Partial<CtNonTargetLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtNonTargetLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CtNonTargetLesionSingleResponse(); }
}

/**
* Form - Ct Non Target Lesion
*/
// @Route("/opms/form/ct-non-target-lesion/save/ids", "POST")
// @Api(Description="Form - Ct Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class CtNonTargetLesionPostSaveWithIds implements IReturn<CtNonTargetLesionSingleResponse>
{
    /**
    * The ct non target lesion to save.
    */
    // @DataMember(Order=1)
    public form: CtNonTargetLesion;

    /**
    * The ID of the Institution associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the ct non target lesion to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<CtNonTargetLesionPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtNonTargetLesionPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CtNonTargetLesionSingleResponse(); }
}

/**
* Form - Ct Non Target Lesion
*/
// @Route("/opms/form/ct-non-target-lesion/save/codes", "POST")
// @Api(Description="Form - Ct Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class CtNonTargetLesionPostSaveWithCodes implements IReturn<CtNonTargetLesionSingleResponse>
{
    /**
    * The ct non target lesion to save.
    */
    // @DataMember(Order=1)
    public form: CtNonTargetLesion;

    /**
    * The Code of the Institution associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the ct non target lesion to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<CtNonTargetLesionPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtNonTargetLesionPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CtNonTargetLesionSingleResponse(); }
}

/**
* Form - Ct Non Target Lesion
*/
// @Route("/opms/form/ct-non-target-lesion/delete", "DELETE")
// @Api(Description="Form - Ct Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class CtNonTargetLesionDelete implements IReturn<CtNonTargetLesionDeleteResponse>
{
    /**
    * The ct non target lesion to delete.
    */
    // @DataMember(Order=1)
    public form: CtNonTargetLesion;

    public constructor(init?: Partial<CtNonTargetLesionDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtNonTargetLesionDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CtNonTargetLesionDeleteResponse(); }
}

/**
* Form - Ct Non Target Lesion
*/
// @Route("/opms/form/ct-non-target-lesion/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Ct Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class CtNonTargetLesionDeleteWithId implements IReturn<CtNonTargetLesionDeleteResponse>
{
    /**
    * The ID of the ct non target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CtNonTargetLesionDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtNonTargetLesionDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CtNonTargetLesionDeleteResponse(); }
}

/**
* Form - Ct Non Target Lesion
*/
// @Route("/opms/form/ct-non-target-lesion/delete/ids", "DELETE")
// @Api(Description="Form - Ct Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class CtNonTargetLesionDeleteWithIds implements IReturn<CtNonTargetLesionDeleteResponse>
{
    /**
    * The Patient ID of the ct non target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the ct non target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<CtNonTargetLesionDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtNonTargetLesionDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CtNonTargetLesionDeleteResponse(); }
}

/**
* Form - Ct Non Target Lesion
*/
// @Route("/opms/form/ct-non-target-lesion/delete/codes", "DELETE")
// @Api(Description="Form - Ct Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class CtNonTargetLesionDeleteByCodes implements IReturn<CtNonTargetLesionDeleteResponse>
{
    /**
    * The Patient Study Number of the ct non target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the ct non target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<CtNonTargetLesionDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtNonTargetLesionDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CtNonTargetLesionDeleteResponse(); }
}

/**
* Form - Ct Non Target Lesion
*/
// @Route("/opms/form/ct-non-target-lesion/validate", "POST")
// @Api(Description="Form - Ct Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionValidationResponse)", StatusCode=500)
// @DataContract
export class CtNonTargetLesionPostValidate implements IReturn<CtNonTargetLesionValidationResponse>
{
    /**
    * The ct non target lesion to validate.
    */
    // @DataMember(Order=1)
    public form: CtNonTargetLesion;

    public constructor(init?: Partial<CtNonTargetLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtNonTargetLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CtNonTargetLesionValidationResponse(); }
}

/**
* Form - Ct Non Target Lesion
*/
// @Route("/opms/form/ct-non-target-lesion/validate/ids", "POST")
// @Api(Description="Form - Ct Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionValidationResponse)", StatusCode=500)
// @DataContract
export class CtNonTargetLesionPostValidateWithIds implements IReturn<CtNonTargetLesionValidationResponse>
{
    /**
    * The ct non target lesion to validate.
    */
    // @DataMember(Order=1)
    public form: CtNonTargetLesion;

    /**
    * The ID of the Institution associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<CtNonTargetLesionPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtNonTargetLesionPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CtNonTargetLesionValidationResponse(); }
}

/**
* Form - Ct Non Target Lesion
*/
// @Route("/opms/form/ct-non-target-lesion/validate/codes", "POST")
// @Api(Description="Form - Ct Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtNonTargetLesionValidationResponse)", StatusCode=500)
// @DataContract
export class CtNonTargetLesionPostValidateWithCodes implements IReturn<CtNonTargetLesionValidationResponse>
{
    /**
    * The ct non target lesion to validate.
    */
    // @DataMember(Order=1)
    public form: CtNonTargetLesion;

    /**
    * The Code of the Institution associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the ct non target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<CtNonTargetLesionPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtNonTargetLesionPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CtNonTargetLesionValidationResponse(); }
}

/**
* Form - Ct Target Lesion
*/
// @Route("/opms/form/ct-target-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - Ct Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class CtTargetLesionGetSingleById implements IReturn<CtTargetLesionSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the ct target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CtTargetLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtTargetLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CtTargetLesionSingleResponse(); }
}

/**
* Form - Ct Target Lesion
*/
// @Route("/opms/form/ct-target-lesion/single/ids", "GET")
// @Api(Description="Form - Ct Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class CtTargetLesionGetSingleByIds implements IReturn<CtTargetLesionSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the ct target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the ct target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the ct target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new ct target lesion if no ct target lesion is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<CtTargetLesionGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtTargetLesionGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CtTargetLesionSingleResponse(); }
}

/**
* Form - Ct Target Lesion
*/
// @Route("/opms/form/ct-target-lesion/single/codes", "GET")
// @Api(Description="Form - Ct Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class CtTargetLesionGetSingleByCodes implements IReturn<CtTargetLesionSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the ct target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the ct target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the ct target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new ct target lesion if no ct target lesion is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<CtTargetLesionGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtTargetLesionGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CtTargetLesionSingleResponse(); }
}

/**
* Form - Ct Target Lesion
*/
// @Route("/opms/form/ct-target-lesion/collection", "GET")
// @Api(Description="Form - Ct Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtTargetLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtTargetLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtTargetLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtTargetLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class CtTargetLesionGetCollection implements IReturn<CtTargetLesionCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<CtTargetLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtTargetLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CtTargetLesionCollectionResponse(); }
}

/**
* Form - Ct Target Lesion
*/
// @Route("/opms/form/ct-target-lesion/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Ct Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtTargetLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtTargetLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtTargetLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtTargetLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class CtTargetLesionGetCollectionByIds implements IReturn<CtTargetLesionCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the ct target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<CtTargetLesionGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtTargetLesionGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CtTargetLesionCollectionResponse(); }
}

/**
* Form - Ct Target Lesion
*/
// @Route("/opms/form/ct-target-lesion/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Ct Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtTargetLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtTargetLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtTargetLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtTargetLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class CtTargetLesionGetCollectionByCodes implements IReturn<CtTargetLesionCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<CtTargetLesionGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtTargetLesionGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CtTargetLesionCollectionResponse(); }
}

/**
* Form - Ct Target Lesion
*/
// @Route("/opms/form/ct-target-lesion/save", "POST")
// @Api(Description="Form - Ct Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class CtTargetLesionPostSave implements IReturn<CtTargetLesionSingleResponse>
{
    /**
    * The ct target lesion to save.
    */
    // @DataMember(Order=1)
    public form: CtTargetLesion;

    public constructor(init?: Partial<CtTargetLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtTargetLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CtTargetLesionSingleResponse(); }
}

/**
* Form - Ct Target Lesion
*/
// @Route("/opms/form/ct-target-lesion/save/ids", "POST")
// @Api(Description="Form - Ct Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class CtTargetLesionPostSaveWithIds implements IReturn<CtTargetLesionSingleResponse>
{
    /**
    * The ct target lesion to save.
    */
    // @DataMember(Order=1)
    public form: CtTargetLesion;

    /**
    * The ID of the Institution associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the ct target lesion to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<CtTargetLesionPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtTargetLesionPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CtTargetLesionSingleResponse(); }
}

/**
* Form - Ct Target Lesion
*/
// @Route("/opms/form/ct-target-lesion/save/codes", "POST")
// @Api(Description="Form - Ct Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class CtTargetLesionPostSaveWithCodes implements IReturn<CtTargetLesionSingleResponse>
{
    /**
    * The ct target lesion to save.
    */
    // @DataMember(Order=1)
    public form: CtTargetLesion;

    /**
    * The Code of the Institution associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the ct target lesion to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<CtTargetLesionPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtTargetLesionPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CtTargetLesionSingleResponse(); }
}

/**
* Form - Ct Target Lesion
*/
// @Route("/opms/form/ct-target-lesion/delete", "DELETE")
// @Api(Description="Form - Ct Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class CtTargetLesionDelete implements IReturn<CtTargetLesionDeleteResponse>
{
    /**
    * The ct target lesion to delete.
    */
    // @DataMember(Order=1)
    public form: CtTargetLesion;

    public constructor(init?: Partial<CtTargetLesionDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtTargetLesionDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CtTargetLesionDeleteResponse(); }
}

/**
* Form - Ct Target Lesion
*/
// @Route("/opms/form/ct-target-lesion/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Ct Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class CtTargetLesionDeleteWithId implements IReturn<CtTargetLesionDeleteResponse>
{
    /**
    * The ID of the ct target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CtTargetLesionDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtTargetLesionDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CtTargetLesionDeleteResponse(); }
}

/**
* Form - Ct Target Lesion
*/
// @Route("/opms/form/ct-target-lesion/delete/ids", "DELETE")
// @Api(Description="Form - Ct Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class CtTargetLesionDeleteWithIds implements IReturn<CtTargetLesionDeleteResponse>
{
    /**
    * The Patient ID of the ct target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the ct target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<CtTargetLesionDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtTargetLesionDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CtTargetLesionDeleteResponse(); }
}

/**
* Form - Ct Target Lesion
*/
// @Route("/opms/form/ct-target-lesion/delete/codes", "DELETE")
// @Api(Description="Form - Ct Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class CtTargetLesionDeleteByCodes implements IReturn<CtTargetLesionDeleteResponse>
{
    /**
    * The Patient Study Number of the ct target lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the ct target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<CtTargetLesionDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtTargetLesionDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CtTargetLesionDeleteResponse(); }
}

/**
* Form - Ct Target Lesion
*/
// @Route("/opms/form/ct-target-lesion/validate", "POST")
// @Api(Description="Form - Ct Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtTargetLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtTargetLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtTargetLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtTargetLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtTargetLesionValidationResponse)", StatusCode=500)
// @DataContract
export class CtTargetLesionPostValidate implements IReturn<CtTargetLesionValidationResponse>
{
    /**
    * The ct target lesion to validate.
    */
    // @DataMember(Order=1)
    public form: CtTargetLesion;

    public constructor(init?: Partial<CtTargetLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtTargetLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CtTargetLesionValidationResponse(); }
}

/**
* Form - Ct Target Lesion
*/
// @Route("/opms/form/ct-target-lesion/validate/ids", "POST")
// @Api(Description="Form - Ct Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtTargetLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtTargetLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtTargetLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtTargetLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtTargetLesionValidationResponse)", StatusCode=500)
// @DataContract
export class CtTargetLesionPostValidateWithIds implements IReturn<CtTargetLesionValidationResponse>
{
    /**
    * The ct target lesion to validate.
    */
    // @DataMember(Order=1)
    public form: CtTargetLesion;

    /**
    * The ID of the Institution associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<CtTargetLesionPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtTargetLesionPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CtTargetLesionValidationResponse(); }
}

/**
* Form - Ct Target Lesion
*/
// @Route("/opms/form/ct-target-lesion/validate/codes", "POST")
// @Api(Description="Form - Ct Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CtTargetLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CtTargetLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CtTargetLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CtTargetLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CtTargetLesionValidationResponse)", StatusCode=500)
// @DataContract
export class CtTargetLesionPostValidateWithCodes implements IReturn<CtTargetLesionValidationResponse>
{
    /**
    * The ct target lesion to validate.
    */
    // @DataMember(Order=1)
    public form: CtTargetLesion;

    /**
    * The Code of the Institution associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the ct target lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<CtTargetLesionPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CtTargetLesionPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CtTargetLesionValidationResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/single/id/{Id}", "GET")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsGetSingleById implements IReturn<DemographicDetailsSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the demographic details to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DemographicDetailsGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DemographicDetailsSingleResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/single/ids", "GET")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsGetSingleByIds implements IReturn<DemographicDetailsSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the demographic details to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the demographic details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the demographic details to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new demographic details if no demographic details is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DemographicDetailsGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DemographicDetailsSingleResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/single/codes", "GET")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsGetSingleByCodes implements IReturn<DemographicDetailsSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the demographic details to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the demographic details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the demographic details to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new demographic details if no demographic details is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DemographicDetailsGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DemographicDetailsSingleResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/collection", "GET")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsGetCollection implements IReturn<DemographicDetailsCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<DemographicDetailsGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DemographicDetailsCollectionResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsGetCollectionByIds implements IReturn<DemographicDetailsCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the demographic details to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DemographicDetailsGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DemographicDetailsCollectionResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsGetCollectionByCodes implements IReturn<DemographicDetailsCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the demographic details to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DemographicDetailsGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DemographicDetailsCollectionResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/save", "POST")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsPostSave implements IReturn<DemographicDetailsSingleResponse>
{
    /**
    * The demographic details to save.
    */
    // @DataMember(Order=1)
    public form: DemographicDetails;

    public constructor(init?: Partial<DemographicDetailsPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DemographicDetailsSingleResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/save/ids", "POST")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsPostSaveWithIds implements IReturn<DemographicDetailsSingleResponse>
{
    /**
    * The demographic details to save.
    */
    // @DataMember(Order=1)
    public form: DemographicDetails;

    /**
    * The ID of the Institution associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the demographic details to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DemographicDetailsPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DemographicDetailsSingleResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/save/codes", "POST")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsPostSaveWithCodes implements IReturn<DemographicDetailsSingleResponse>
{
    /**
    * The demographic details to save.
    */
    // @DataMember(Order=1)
    public form: DemographicDetails;

    /**
    * The Code of the Institution associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the demographic details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the demographic details to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DemographicDetailsPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DemographicDetailsSingleResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/delete", "DELETE")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsDelete implements IReturn<DemographicDetailsDeleteResponse>
{
    /**
    * The demographic details to delete.
    */
    // @DataMember(Order=1)
    public form: DemographicDetails;

    public constructor(init?: Partial<DemographicDetailsDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DemographicDetailsDeleteResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsDeleteWithId implements IReturn<DemographicDetailsDeleteResponse>
{
    /**
    * The ID of the demographic details to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DemographicDetailsDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DemographicDetailsDeleteResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/delete/ids", "DELETE")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsDeleteWithIds implements IReturn<DemographicDetailsDeleteResponse>
{
    /**
    * The Patient ID of the demographic details to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the demographic details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DemographicDetailsDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DemographicDetailsDeleteResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/delete/codes", "DELETE")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsDeleteByCodes implements IReturn<DemographicDetailsDeleteResponse>
{
    /**
    * The Patient Study Number of the demographic details to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the demographic details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DemographicDetailsDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DemographicDetailsDeleteResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/validate", "POST")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsPostValidate implements IReturn<DemographicDetailsValidationResponse>
{
    /**
    * The demographic details to validate.
    */
    // @DataMember(Order=1)
    public form: DemographicDetails;

    public constructor(init?: Partial<DemographicDetailsPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DemographicDetailsValidationResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/validate/ids", "POST")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsPostValidateWithIds implements IReturn<DemographicDetailsValidationResponse>
{
    /**
    * The demographic details to validate.
    */
    // @DataMember(Order=1)
    public form: DemographicDetails;

    /**
    * The ID of the Institution associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DemographicDetailsPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DemographicDetailsValidationResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/validate/codes", "POST")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsPostValidateWithCodes implements IReturn<DemographicDetailsValidationResponse>
{
    /**
    * The demographic details to validate.
    */
    // @DataMember(Order=1)
    public form: DemographicDetails;

    /**
    * The Code of the Institution associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the demographic details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DemographicDetailsPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DemographicDetailsValidationResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/single/id/{Id}", "GET")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentGetSingleById implements IReturn<DiseaseAssessmentSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DiseaseAssessmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiseaseAssessmentSingleResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/single/ids", "GET")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentGetSingleByIds implements IReturn<DiseaseAssessmentSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the disease assessment to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new disease assessment if no disease assessment is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DiseaseAssessmentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiseaseAssessmentSingleResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/single/codes", "GET")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentGetSingleByCodes implements IReturn<DiseaseAssessmentSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the disease assessment to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new disease assessment if no disease assessment is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DiseaseAssessmentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiseaseAssessmentSingleResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/collection", "GET")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentGetCollection implements IReturn<DiseaseAssessmentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<DiseaseAssessmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiseaseAssessmentCollectionResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentGetCollectionByIds implements IReturn<DiseaseAssessmentCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiseaseAssessmentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiseaseAssessmentCollectionResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentGetCollectionByCodes implements IReturn<DiseaseAssessmentCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiseaseAssessmentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiseaseAssessmentCollectionResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/save", "POST")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentPostSave implements IReturn<DiseaseAssessmentSingleResponse>
{
    /**
    * The disease assessment to save.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    public constructor(init?: Partial<DiseaseAssessmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiseaseAssessmentSingleResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/save/ids", "POST")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentPostSaveWithIds implements IReturn<DiseaseAssessmentSingleResponse>
{
    /**
    * The disease assessment to save.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    /**
    * The ID of the Institution associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the disease assessment to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DiseaseAssessmentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiseaseAssessmentSingleResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/save/codes", "POST")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentPostSaveWithCodes implements IReturn<DiseaseAssessmentSingleResponse>
{
    /**
    * The disease assessment to save.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    /**
    * The Code of the Institution associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the disease assessment to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DiseaseAssessmentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiseaseAssessmentSingleResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/delete", "DELETE")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentDelete implements IReturn<DiseaseAssessmentDeleteResponse>
{
    /**
    * The disease assessment to delete.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    public constructor(init?: Partial<DiseaseAssessmentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiseaseAssessmentDeleteResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentDeleteWithId implements IReturn<DiseaseAssessmentDeleteResponse>
{
    /**
    * The ID of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DiseaseAssessmentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiseaseAssessmentDeleteResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/delete/ids", "DELETE")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentDeleteWithIds implements IReturn<DiseaseAssessmentDeleteResponse>
{
    /**
    * The Patient ID of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DiseaseAssessmentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiseaseAssessmentDeleteResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/delete/codes", "DELETE")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentDeleteByCodes implements IReturn<DiseaseAssessmentDeleteResponse>
{
    /**
    * The Patient Study Number of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DiseaseAssessmentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiseaseAssessmentDeleteResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/validate", "POST")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentPostValidate implements IReturn<DiseaseAssessmentValidationResponse>
{
    /**
    * The disease assessment to validate.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    public constructor(init?: Partial<DiseaseAssessmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiseaseAssessmentValidationResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/validate/ids", "POST")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentPostValidateWithIds implements IReturn<DiseaseAssessmentValidationResponse>
{
    /**
    * The disease assessment to validate.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    /**
    * The ID of the Institution associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiseaseAssessmentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiseaseAssessmentValidationResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/validate/codes", "POST")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentPostValidateWithCodes implements IReturn<DiseaseAssessmentValidationResponse>
{
    /**
    * The disease assessment to validate.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    /**
    * The Code of the Institution associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiseaseAssessmentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiseaseAssessmentValidationResponse(); }
}

/**
* Form - Ecg
*/
// @Route("/opms/form/ecg/single/id/{Id}", "GET")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=500)
// @DataContract
export class EcgGetSingleById implements IReturn<EcgSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the ecg to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EcgGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EcgSingleResponse(); }
}

/**
* Form - Ecg
*/
// @Route("/opms/form/ecg/single/ids", "GET")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=500)
// @DataContract
export class EcgGetSingleByIds implements IReturn<EcgSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the ecg to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the ecg to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the ecg to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the ecg to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the ecg to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new ecg if no ecg is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<EcgGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EcgSingleResponse(); }
}

/**
* Form - Ecg
*/
// @Route("/opms/form/ecg/single/codes", "GET")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=500)
// @DataContract
export class EcgGetSingleByCodes implements IReturn<EcgSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the ecg to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the ecg to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the ecg to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the ecg to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the ecg to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new ecg if no ecg is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<EcgGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EcgSingleResponse(); }
}

/**
* Form - Ecg
*/
// @Route("/opms/form/ecg/collection", "GET")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EcgCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EcgCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EcgCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EcgCollectionResponse)", StatusCode=500)
// @DataContract
export class EcgGetCollection implements IReturn<EcgCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<EcgGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EcgCollectionResponse(); }
}

/**
* Form - Ecg
*/
// @Route("/opms/form/ecg/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EcgCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EcgCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EcgCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EcgCollectionResponse)", StatusCode=500)
// @DataContract
export class EcgGetCollectionByIds implements IReturn<EcgCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the ecg to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the ecg to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the ecg to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<EcgGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EcgCollectionResponse(); }
}

/**
* Form - Ecg
*/
// @Route("/opms/form/ecg/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EcgCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EcgCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EcgCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EcgCollectionResponse)", StatusCode=500)
// @DataContract
export class EcgGetCollectionByCodes implements IReturn<EcgCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the ecg to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the ecg to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the ecg to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<EcgGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EcgCollectionResponse(); }
}

/**
* Form - Ecg
*/
// @Route("/opms/form/ecg/save", "POST")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=500)
// @DataContract
export class EcgPostSave implements IReturn<EcgSingleResponse>
{
    /**
    * The ecg to save.
    */
    // @DataMember(Order=1)
    public form: Ecg;

    public constructor(init?: Partial<EcgPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EcgSingleResponse(); }
}

/**
* Form - Ecg
*/
// @Route("/opms/form/ecg/save/ids", "POST")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=500)
// @DataContract
export class EcgPostSaveWithIds implements IReturn<EcgSingleResponse>
{
    /**
    * The ecg to save.
    */
    // @DataMember(Order=1)
    public form: Ecg;

    /**
    * The ID of the Institution associated with the ecg to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the ecg to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the ecg to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the ecg to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the ecg to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<EcgPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EcgSingleResponse(); }
}

/**
* Form - Ecg
*/
// @Route("/opms/form/ecg/save/codes", "POST")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EcgSingleResponse)", StatusCode=500)
// @DataContract
export class EcgPostSaveWithCodes implements IReturn<EcgSingleResponse>
{
    /**
    * The ecg to save.
    */
    // @DataMember(Order=1)
    public form: Ecg;

    /**
    * The Code of the Institution associated with the ecg to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the ecg to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the ecg to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the ecg to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the ecg to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<EcgPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EcgSingleResponse(); }
}

/**
* Form - Ecg
*/
// @Route("/opms/form/ecg/delete", "DELETE")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=500)
// @DataContract
export class EcgDelete implements IReturn<EcgDeleteResponse>
{
    /**
    * The ecg to delete.
    */
    // @DataMember(Order=1)
    public form: Ecg;

    public constructor(init?: Partial<EcgDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EcgDeleteResponse(); }
}

/**
* Form - Ecg
*/
// @Route("/opms/form/ecg/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=500)
// @DataContract
export class EcgDeleteWithId implements IReturn<EcgDeleteResponse>
{
    /**
    * The ID of the ecg to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EcgDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EcgDeleteResponse(); }
}

/**
* Form - Ecg
*/
// @Route("/opms/form/ecg/delete/ids", "DELETE")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=500)
// @DataContract
export class EcgDeleteWithIds implements IReturn<EcgDeleteResponse>
{
    /**
    * The Patient ID of the ecg to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the ecg to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the ecg to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the ecg to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<EcgDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EcgDeleteResponse(); }
}

/**
* Form - Ecg
*/
// @Route("/opms/form/ecg/delete/codes", "DELETE")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EcgDeleteResponse)", StatusCode=500)
// @DataContract
export class EcgDeleteByCodes implements IReturn<EcgDeleteResponse>
{
    /**
    * The Patient Study Number of the ecg to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the ecg to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the ecg to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the ecg to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<EcgDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EcgDeleteResponse(); }
}

/**
* Form - Ecg
*/
// @Route("/opms/form/ecg/validate", "POST")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EcgValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EcgValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EcgValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EcgValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EcgValidationResponse)", StatusCode=500)
// @DataContract
export class EcgPostValidate implements IReturn<EcgValidationResponse>
{
    /**
    * The ecg to validate.
    */
    // @DataMember(Order=1)
    public form: Ecg;

    public constructor(init?: Partial<EcgPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EcgValidationResponse(); }
}

/**
* Form - Ecg
*/
// @Route("/opms/form/ecg/validate/ids", "POST")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EcgValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EcgValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EcgValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EcgValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EcgValidationResponse)", StatusCode=500)
// @DataContract
export class EcgPostValidateWithIds implements IReturn<EcgValidationResponse>
{
    /**
    * The ecg to validate.
    */
    // @DataMember(Order=1)
    public form: Ecg;

    /**
    * The ID of the Institution associated with the ecg to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the ecg to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the ecg to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the ecg to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<EcgPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EcgValidationResponse(); }
}

/**
* Form - Ecg
*/
// @Route("/opms/form/ecg/validate/codes", "POST")
// @Api(Description="Form - Ecg")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EcgValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EcgValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EcgValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EcgValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EcgValidationResponse)", StatusCode=500)
// @DataContract
export class EcgPostValidateWithCodes implements IReturn<EcgValidationResponse>
{
    /**
    * The ecg to validate.
    */
    // @DataMember(Order=1)
    public form: Ecg;

    /**
    * The Code of the Institution associated with the ecg to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the ecg to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the ecg to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the ecg to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<EcgPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EcgPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EcgValidationResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/single/id/{Id}", "GET")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationGetSingleById implements IReturn<EligibilityConfirmationSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EligibilityConfirmationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityConfirmationSingleResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/single/ids", "GET")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationGetSingleByIds implements IReturn<EligibilityConfirmationSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new eligibility confirmation if no eligibility confirmation is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<EligibilityConfirmationGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityConfirmationSingleResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/single/codes", "GET")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationGetSingleByCodes implements IReturn<EligibilityConfirmationSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new eligibility confirmation if no eligibility confirmation is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<EligibilityConfirmationGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityConfirmationSingleResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/collection", "GET")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationGetCollection implements IReturn<EligibilityConfirmationCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<EligibilityConfirmationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityConfirmationCollectionResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationGetCollectionByIds implements IReturn<EligibilityConfirmationCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityConfirmationGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityConfirmationCollectionResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationGetCollectionByCodes implements IReturn<EligibilityConfirmationCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityConfirmationGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityConfirmationCollectionResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/save", "POST")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationPostSave implements IReturn<EligibilityConfirmationSingleResponse>
{
    /**
    * The eligibility confirmation to save.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    public constructor(init?: Partial<EligibilityConfirmationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityConfirmationSingleResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/save/ids", "POST")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationPostSaveWithIds implements IReturn<EligibilityConfirmationSingleResponse>
{
    /**
    * The eligibility confirmation to save.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    /**
    * The ID of the Institution associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<EligibilityConfirmationPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityConfirmationSingleResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/save/codes", "POST")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationPostSaveWithCodes implements IReturn<EligibilityConfirmationSingleResponse>
{
    /**
    * The eligibility confirmation to save.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    /**
    * The Code of the Institution associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<EligibilityConfirmationPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityConfirmationSingleResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/delete", "DELETE")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationDelete implements IReturn<EligibilityConfirmationDeleteResponse>
{
    /**
    * The eligibility confirmation to delete.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    public constructor(init?: Partial<EligibilityConfirmationDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityConfirmationDeleteResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationDeleteWithId implements IReturn<EligibilityConfirmationDeleteResponse>
{
    /**
    * The ID of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EligibilityConfirmationDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityConfirmationDeleteResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/delete/ids", "DELETE")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationDeleteWithIds implements IReturn<EligibilityConfirmationDeleteResponse>
{
    /**
    * The Patient ID of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<EligibilityConfirmationDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityConfirmationDeleteResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/delete/codes", "DELETE")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationDeleteByCodes implements IReturn<EligibilityConfirmationDeleteResponse>
{
    /**
    * The Patient Study Number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<EligibilityConfirmationDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityConfirmationDeleteResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/validate", "POST")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationPostValidate implements IReturn<EligibilityConfirmationValidationResponse>
{
    /**
    * The eligibility confirmation to validate.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    public constructor(init?: Partial<EligibilityConfirmationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityConfirmationValidationResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/validate/ids", "POST")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationPostValidateWithIds implements IReturn<EligibilityConfirmationValidationResponse>
{
    /**
    * The eligibility confirmation to validate.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    /**
    * The ID of the Institution associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityConfirmationPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityConfirmationValidationResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/validate/codes", "POST")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationPostValidateWithCodes implements IReturn<EligibilityConfirmationValidationResponse>
{
    /**
    * The eligibility confirmation to validate.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    /**
    * The Code of the Institution associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityConfirmationPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityConfirmationValidationResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/single/id/{Id}", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralGetSingleById implements IReturn<GeneralSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<GeneralGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/single/ids", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralGetSingleByIds implements IReturn<GeneralSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the general to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the general to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new general if no general is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<GeneralGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/single/codes", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralGetSingleByCodes implements IReturn<GeneralSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the general to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the general to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new general if no general is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<GeneralGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/collection", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralGetCollection implements IReturn<GeneralCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<GeneralGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralCollectionResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralGetCollectionByIds implements IReturn<GeneralCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralCollectionResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralGetCollectionByCodes implements IReturn<GeneralCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralCollectionResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/save", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralPostSave implements IReturn<GeneralSingleResponse>
{
    /**
    * The general to save.
    */
    // @DataMember(Order=1)
    public form: General;

    public constructor(init?: Partial<GeneralPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/save/ids", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralPostSaveWithIds implements IReturn<GeneralSingleResponse>
{
    /**
    * The general to save.
    */
    // @DataMember(Order=1)
    public form: General;

    /**
    * The ID of the Institution associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the general to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<GeneralPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/save/codes", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralPostSaveWithCodes implements IReturn<GeneralSingleResponse>
{
    /**
    * The general to save.
    */
    // @DataMember(Order=1)
    public form: General;

    /**
    * The Code of the Institution associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the general to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the general to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<GeneralPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/delete", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDelete implements IReturn<GeneralDeleteResponse>
{
    /**
    * The general to delete.
    */
    // @DataMember(Order=1)
    public form: General;

    public constructor(init?: Partial<GeneralDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/delete/id/{Id}", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDeleteWithId implements IReturn<GeneralDeleteResponse>
{
    /**
    * The ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<GeneralDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/delete/ids", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDeleteWithIds implements IReturn<GeneralDeleteResponse>
{
    /**
    * The Patient ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the general to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<GeneralDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/delete/codes", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDeleteByCodes implements IReturn<GeneralDeleteResponse>
{
    /**
    * The Patient Study Number of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the general to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<GeneralDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/validate", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralPostValidate implements IReturn<GeneralValidationResponse>
{
    /**
    * The general to validate.
    */
    // @DataMember(Order=1)
    public form: General;

    public constructor(init?: Partial<GeneralPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralValidationResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/validate/ids", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralPostValidateWithIds implements IReturn<GeneralValidationResponse>
{
    /**
    * The general to validate.
    */
    // @DataMember(Order=1)
    public form: General;

    /**
    * The ID of the Institution associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralValidationResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/validate/codes", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralPostValidateWithCodes implements IReturn<GeneralValidationResponse>
{
    /**
    * The general to validate.
    */
    // @DataMember(Order=1)
    public form: General;

    /**
    * The Code of the Institution associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the general to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralValidationResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/single/id/{Id}", "GET")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetSingleById implements IReturn<MedicalHistorySingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/single/ids", "GET")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetSingleByIds implements IReturn<MedicalHistorySingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the medical history to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new medical history if no medical history is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<MedicalHistoryGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/single/codes", "GET")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetSingleByCodes implements IReturn<MedicalHistorySingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the medical history to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new medical history if no medical history is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<MedicalHistoryGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/collection", "GET")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetCollection implements IReturn<MedicalHistoryCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<MedicalHistoryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryCollectionResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetCollectionByIds implements IReturn<MedicalHistoryCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryCollectionResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetCollectionByCodes implements IReturn<MedicalHistoryCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryCollectionResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/save", "POST")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostSave implements IReturn<MedicalHistorySingleResponse>
{
    /**
    * The medical history to save.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/save/ids", "POST")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostSaveWithIds implements IReturn<MedicalHistorySingleResponse>
{
    /**
    * The medical history to save.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    /**
    * The ID of the Institution associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the medical history to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<MedicalHistoryPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/save/codes", "POST")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostSaveWithCodes implements IReturn<MedicalHistorySingleResponse>
{
    /**
    * The medical history to save.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    /**
    * The Code of the Institution associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the medical history to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<MedicalHistoryPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/delete", "DELETE")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryDelete implements IReturn<MedicalHistoryDeleteResponse>
{
    /**
    * The medical history to delete.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryDeleteResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryDeleteWithId implements IReturn<MedicalHistoryDeleteResponse>
{
    /**
    * The ID of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryDeleteResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/delete/ids", "DELETE")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryDeleteWithIds implements IReturn<MedicalHistoryDeleteResponse>
{
    /**
    * The Patient ID of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryDeleteResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/delete/codes", "DELETE")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryDeleteByCodes implements IReturn<MedicalHistoryDeleteResponse>
{
    /**
    * The Patient Study Number of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryDeleteResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/validate", "POST")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostValidate implements IReturn<MedicalHistoryValidationResponse>
{
    /**
    * The medical history to validate.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryValidationResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/validate/ids", "POST")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostValidateWithIds implements IReturn<MedicalHistoryValidationResponse>
{
    /**
    * The medical history to validate.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    /**
    * The ID of the Institution associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryValidationResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/validate/codes", "POST")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostValidateWithCodes implements IReturn<MedicalHistoryValidationResponse>
{
    /**
    * The medical history to validate.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    /**
    * The Code of the Institution associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryValidationResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/single/id/{Id}", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleById implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/single/ids", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleByIds implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the source document to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new source document if no source document is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SourceDocumentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/single/codes", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleByCodes implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the source document to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new source document if no source document is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SourceDocumentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/collection", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollection implements IReturn<SourceDocumentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<SourceDocumentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollectionByIds implements IReturn<SourceDocumentCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollectionByCodes implements IReturn<SourceDocumentCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/save", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSave implements IReturn<SourceDocumentSingleResponse>
{
    /**
    * The source document to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/save/ids", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSaveWithIds implements IReturn<SourceDocumentSingleResponse>
{
    /**
    * The source document to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    /**
    * The ID of the Institution associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SourceDocumentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/save/codes", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSaveWithCodes implements IReturn<SourceDocumentSingleResponse>
{
    /**
    * The source document to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    /**
    * The Code of the Institution associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the source document to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SourceDocumentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/delete", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDelete implements IReturn<SourceDocumentDeleteResponse>
{
    /**
    * The source document to delete.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDeleteWithId implements IReturn<SourceDocumentDeleteResponse>
{
    /**
    * The ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/delete/ids", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDeleteWithIds implements IReturn<SourceDocumentDeleteResponse>
{
    /**
    * The Patient ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SourceDocumentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/delete/codes", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDeleteByCodes implements IReturn<SourceDocumentDeleteResponse>
{
    /**
    * The Patient Study Number of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SourceDocumentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/validate", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidate implements IReturn<SourceDocumentValidationResponse>
{
    /**
    * The source document to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/validate/ids", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidateWithIds implements IReturn<SourceDocumentValidationResponse>
{
    /**
    * The source document to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    /**
    * The ID of the Institution associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/validate/codes", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidateWithCodes implements IReturn<SourceDocumentValidationResponse>
{
    /**
    * The source document to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    /**
    * The Code of the Institution associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the source document to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/single/id/{Id}", "GET")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormGetSingleById implements IReturn<SourceDocumentFormSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the source document form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentFormSingleResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/single/ids", "GET")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormGetSingleByIds implements IReturn<SourceDocumentFormSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the source document form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the source document form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new source document form if no source document form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SourceDocumentFormGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentFormSingleResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/single/codes", "GET")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormGetSingleByCodes implements IReturn<SourceDocumentFormSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the source document form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the source document form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new source document form if no source document form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SourceDocumentFormGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentFormSingleResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/collection", "GET")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormGetCollection implements IReturn<SourceDocumentFormCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<SourceDocumentFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentFormCollectionResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormGetCollectionByIds implements IReturn<SourceDocumentFormCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the source document form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentFormGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentFormCollectionResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormGetCollectionByCodes implements IReturn<SourceDocumentFormCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the source document form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentFormGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentFormCollectionResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/save", "POST")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormPostSave implements IReturn<SourceDocumentFormSingleResponse>
{
    /**
    * The source document form to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocumentForm;

    public constructor(init?: Partial<SourceDocumentFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentFormSingleResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/save/ids", "POST")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormPostSaveWithIds implements IReturn<SourceDocumentFormSingleResponse>
{
    /**
    * The source document form to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocumentForm;

    /**
    * The ID of the Institution associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the source document form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SourceDocumentFormPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentFormSingleResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/save/codes", "POST")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormPostSaveWithCodes implements IReturn<SourceDocumentFormSingleResponse>
{
    /**
    * The source document form to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocumentForm;

    /**
    * The Code of the Institution associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the source document form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the source document form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SourceDocumentFormPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentFormSingleResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/delete", "DELETE")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormDelete implements IReturn<SourceDocumentFormDeleteResponse>
{
    /**
    * The source document form to delete.
    */
    // @DataMember(Order=1)
    public form: SourceDocumentForm;

    public constructor(init?: Partial<SourceDocumentFormDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentFormDeleteResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormDeleteWithId implements IReturn<SourceDocumentFormDeleteResponse>
{
    /**
    * The ID of the source document form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentFormDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentFormDeleteResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/delete/ids", "DELETE")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormDeleteWithIds implements IReturn<SourceDocumentFormDeleteResponse>
{
    /**
    * The Patient ID of the source document form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SourceDocumentFormDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentFormDeleteResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/delete/codes", "DELETE")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormDeleteByCodes implements IReturn<SourceDocumentFormDeleteResponse>
{
    /**
    * The Patient Study Number of the source document form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SourceDocumentFormDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentFormDeleteResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/validate", "POST")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormPostValidate implements IReturn<SourceDocumentFormValidationResponse>
{
    /**
    * The source document form to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocumentForm;

    public constructor(init?: Partial<SourceDocumentFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentFormValidationResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/validate/ids", "POST")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormPostValidateWithIds implements IReturn<SourceDocumentFormValidationResponse>
{
    /**
    * The source document form to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocumentForm;

    /**
    * The ID of the Institution associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentFormPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentFormValidationResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/validate/codes", "POST")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormPostValidateWithCodes implements IReturn<SourceDocumentFormValidationResponse>
{
    /**
    * The source document form to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocumentForm;

    /**
    * The Code of the Institution associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the source document form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentFormPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentFormValidationResponse(); }
}

/**
* Form - Urinalysis
*/
// @Route("/opms/form/urinalysis/single/id/{Id}", "GET")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=500)
// @DataContract
export class UrinalysisGetSingleById implements IReturn<UrinalysisSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the urinalysis to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<UrinalysisGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UrinalysisGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UrinalysisSingleResponse(); }
}

/**
* Form - Urinalysis
*/
// @Route("/opms/form/urinalysis/single/ids", "GET")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=500)
// @DataContract
export class UrinalysisGetSingleByIds implements IReturn<UrinalysisSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the urinalysis to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the urinalysis to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the urinalysis to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new urinalysis if no urinalysis is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<UrinalysisGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UrinalysisGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UrinalysisSingleResponse(); }
}

/**
* Form - Urinalysis
*/
// @Route("/opms/form/urinalysis/single/codes", "GET")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=500)
// @DataContract
export class UrinalysisGetSingleByCodes implements IReturn<UrinalysisSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the urinalysis to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the urinalysis to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the urinalysis to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new urinalysis if no urinalysis is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<UrinalysisGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UrinalysisGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UrinalysisSingleResponse(); }
}

/**
* Form - Urinalysis
*/
// @Route("/opms/form/urinalysis/collection", "GET")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UrinalysisCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UrinalysisCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UrinalysisCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UrinalysisCollectionResponse)", StatusCode=500)
// @DataContract
export class UrinalysisGetCollection implements IReturn<UrinalysisCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<UrinalysisGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UrinalysisGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UrinalysisCollectionResponse(); }
}

/**
* Form - Urinalysis
*/
// @Route("/opms/form/urinalysis/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UrinalysisCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UrinalysisCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UrinalysisCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UrinalysisCollectionResponse)", StatusCode=500)
// @DataContract
export class UrinalysisGetCollectionByIds implements IReturn<UrinalysisCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the urinalysis to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<UrinalysisGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UrinalysisGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UrinalysisCollectionResponse(); }
}

/**
* Form - Urinalysis
*/
// @Route("/opms/form/urinalysis/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UrinalysisCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UrinalysisCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UrinalysisCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UrinalysisCollectionResponse)", StatusCode=500)
// @DataContract
export class UrinalysisGetCollectionByCodes implements IReturn<UrinalysisCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<UrinalysisGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UrinalysisGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UrinalysisCollectionResponse(); }
}

/**
* Form - Urinalysis
*/
// @Route("/opms/form/urinalysis/save", "POST")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=500)
// @DataContract
export class UrinalysisPostSave implements IReturn<UrinalysisSingleResponse>
{
    /**
    * The urinalysis to save.
    */
    // @DataMember(Order=1)
    public form: Urinalysis;

    public constructor(init?: Partial<UrinalysisPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UrinalysisPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UrinalysisSingleResponse(); }
}

/**
* Form - Urinalysis
*/
// @Route("/opms/form/urinalysis/save/ids", "POST")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=500)
// @DataContract
export class UrinalysisPostSaveWithIds implements IReturn<UrinalysisSingleResponse>
{
    /**
    * The urinalysis to save.
    */
    // @DataMember(Order=1)
    public form: Urinalysis;

    /**
    * The ID of the Institution associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the urinalysis to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<UrinalysisPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UrinalysisPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UrinalysisSingleResponse(); }
}

/**
* Form - Urinalysis
*/
// @Route("/opms/form/urinalysis/save/codes", "POST")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UrinalysisSingleResponse)", StatusCode=500)
// @DataContract
export class UrinalysisPostSaveWithCodes implements IReturn<UrinalysisSingleResponse>
{
    /**
    * The urinalysis to save.
    */
    // @DataMember(Order=1)
    public form: Urinalysis;

    /**
    * The Code of the Institution associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the urinalysis to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<UrinalysisPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UrinalysisPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UrinalysisSingleResponse(); }
}

/**
* Form - Urinalysis
*/
// @Route("/opms/form/urinalysis/delete", "DELETE")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=500)
// @DataContract
export class UrinalysisDelete implements IReturn<UrinalysisDeleteResponse>
{
    /**
    * The urinalysis to delete.
    */
    // @DataMember(Order=1)
    public form: Urinalysis;

    public constructor(init?: Partial<UrinalysisDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UrinalysisDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UrinalysisDeleteResponse(); }
}

/**
* Form - Urinalysis
*/
// @Route("/opms/form/urinalysis/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=500)
// @DataContract
export class UrinalysisDeleteWithId implements IReturn<UrinalysisDeleteResponse>
{
    /**
    * The ID of the urinalysis to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<UrinalysisDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UrinalysisDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UrinalysisDeleteResponse(); }
}

/**
* Form - Urinalysis
*/
// @Route("/opms/form/urinalysis/delete/ids", "DELETE")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=500)
// @DataContract
export class UrinalysisDeleteWithIds implements IReturn<UrinalysisDeleteResponse>
{
    /**
    * The Patient ID of the urinalysis to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the urinalysis to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<UrinalysisDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UrinalysisDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UrinalysisDeleteResponse(); }
}

/**
* Form - Urinalysis
*/
// @Route("/opms/form/urinalysis/delete/codes", "DELETE")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UrinalysisDeleteResponse)", StatusCode=500)
// @DataContract
export class UrinalysisDeleteByCodes implements IReturn<UrinalysisDeleteResponse>
{
    /**
    * The Patient Study Number of the urinalysis to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the urinalysis to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<UrinalysisDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UrinalysisDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UrinalysisDeleteResponse(); }
}

/**
* Form - Urinalysis
*/
// @Route("/opms/form/urinalysis/validate", "POST")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UrinalysisValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UrinalysisValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UrinalysisValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UrinalysisValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UrinalysisValidationResponse)", StatusCode=500)
// @DataContract
export class UrinalysisPostValidate implements IReturn<UrinalysisValidationResponse>
{
    /**
    * The urinalysis to validate.
    */
    // @DataMember(Order=1)
    public form: Urinalysis;

    public constructor(init?: Partial<UrinalysisPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UrinalysisPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UrinalysisValidationResponse(); }
}

/**
* Form - Urinalysis
*/
// @Route("/opms/form/urinalysis/validate/ids", "POST")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UrinalysisValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UrinalysisValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UrinalysisValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UrinalysisValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UrinalysisValidationResponse)", StatusCode=500)
// @DataContract
export class UrinalysisPostValidateWithIds implements IReturn<UrinalysisValidationResponse>
{
    /**
    * The urinalysis to validate.
    */
    // @DataMember(Order=1)
    public form: Urinalysis;

    /**
    * The ID of the Institution associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<UrinalysisPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UrinalysisPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UrinalysisValidationResponse(); }
}

/**
* Form - Urinalysis
*/
// @Route("/opms/form/urinalysis/validate/codes", "POST")
// @Api(Description="Form - Urinalysis")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UrinalysisValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UrinalysisValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UrinalysisValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UrinalysisValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UrinalysisValidationResponse)", StatusCode=500)
// @DataContract
export class UrinalysisPostValidateWithCodes implements IReturn<UrinalysisValidationResponse>
{
    /**
    * The urinalysis to validate.
    */
    // @DataMember(Order=1)
    public form: Urinalysis;

    /**
    * The Code of the Institution associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the urinalysis to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<UrinalysisPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UrinalysisPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UrinalysisValidationResponse(); }
}

// @Route("/opms/file/view/{Id}", "GET")
export class GetFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file/download/{Id}", "GET")
export class DownloadFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<DownloadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file/download-all/{PatientId}/{FormDefinitionId}/{FormId}", "GET")
export class DownloadAllFiles implements IReturn<Blob>, IGet
{
    public patientId?: number;
    public formDefinitionId?: number;
    public formId?: number;

    public constructor(init?: Partial<DownloadAllFiles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadAllFiles'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

export class GetInstitutionPersonnelNames implements IReturn<InstitutionPersonnelNamesResponse>, IGet
{
    public institutionCode: string;
    public roles: number[];

    public constructor(init?: Partial<GetInstitutionPersonnelNames>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInstitutionPersonnelNames'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionPersonnelNamesResponse(); }
}

export class GetUserNames implements IReturn<GetUserNamesResponse>, IGet
{
    public instId?: number;
    public roles: number[];

    public constructor(init?: Partial<GetUserNames>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUserNames'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetUserNamesResponse(); }
}

export class PostHasOpmsPermissionsByIds implements IReturn<HasOpmsPermissionCollectionResponse>
{
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public institutionId?: number;
    public patientId?: number;
    public permissions: Permission[];

    public constructor(init?: Partial<PostHasOpmsPermissionsByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasOpmsPermissionsByIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HasOpmsPermissionCollectionResponse(); }
}

export class PostHasOpmsPermissionsByCodes implements IReturn<HasOpmsPermissionCollectionResponse>
{
    public masterGroupCode: string;
    public coordinatingGroupCode: string;
    public institutionCode: string;
    public patientStudyNumber: string;
    public permissions: Permission[];

    public constructor(init?: Partial<PostHasOpmsPermissionsByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasOpmsPermissionsByCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HasOpmsPermissionCollectionResponse(); }
}

