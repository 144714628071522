/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password,
    KeyboardDatePicker,
    Checkbox,
    Numeric,
    FormGrid,
    useFormState,
    IFormGridCell,
    Input,
    CtcaeSelector,
    Masked,
    getParentPropertyPath
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-trogapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles } from '@material-ui/core';

import { NumberFormatValues } from 'react-number-format';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import { get } from 'lodash-es';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IDiseaseAssessmentProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 0, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */
const additionalAanatomicalAreasYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiseaseAssessment>) => formState?.values.additionalAnatomyClinicallyRequired === Dtos.LookupYesNoType.Yes;
const anatomicalAreasOtherChecked = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiseaseAssessment>) => formState?.values.other === true;
const anyTargetLesionsPresentYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiseaseAssessment>) => formState?.values.anyTargetLesionsPresent === Dtos.LookupYesNoType.Yes;
const anyNonTargetLesionsPresentYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiseaseAssessment>) => formState?.values.anyNonTargetLesionsPresent === Dtos.LookupYesNoType.Yes;
const brainMetastasisYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiseaseAssessment>) => formState?.values.brainMetastasis === Dtos.LookupYesNoType.Yes;
const lesionTypeTarget = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiseaseAssessment>) => formState?.values.lesionType === Dtos.LookupLesionType.Target || formState?.values.lesionType === Dtos.LookupLesionType.Both;
const lesionTypeNonTarget = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiseaseAssessment>) => formState?.values.lesionType === Dtos.LookupLesionType.NonTarget || formState?.values.lesionType === Dtos.LookupLesionType.Both;
const additionalImagingPerformedYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiseaseAssessment>) => formState?.values.additionalImagingPerformed === Dtos.LookupYesNoType.Yes;

const imagingTypeOther = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.DiseaseAssessment>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.AdditionalImaging = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.imagingType === Dtos.LookupAdditionalImagingType.Other;
};

const resultsClinicallySignificant = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.DiseaseAssessment>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.AdditionalImaging = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.imagingResults === Dtos.LookupResultType.AbnormalClinicallySignificant;
};

export const UK_DATE = {
    mask: [/[0-3U]/, /[0-9K]/, '/', /[0-1U]/, /[0-9K]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    showMask: true
};

export const FOUR_DIGIT_POSITIVE_INTEGER = {
    decimalScale: 0,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 10000) {
            return true;
        }

        return false;
    }
}

const ctTargetLesionsColumns: Array<IFormGridCell<Dtos.CtTargetLesion>> = [
    {
        name: 'targetLesionNumber',
        minWidth: 220,
        maxWidth: 220,
        width: 200,
        content: (
            <Input
                component={Text}
                disabled={true}
            />
        )
    },
    {
        name: 'locationName',
        minWidth: 350,
        maxWidth: 350,
        width: 350,
        content: (
            <Input
                component={Text}
            />
        )
    },
    {
        name: 'longestDiameter',
        minWidth: 250,
        maxWidth: 250,
        width: 150,
        content: (
            <Input
                component={Numeric}
                numberFormat={FOUR_DIGIT_POSITIVE_INTEGER}
            />
        )
    }
];

const ctNonTargetLesionsColumns: Array<IFormGridCell<Dtos.CtNonTargetLesion>> = [
    {
        name: 'nonTargetLesionNumber',
        minWidth: 250,
        maxWidth: 250,
        width: 200,
        content: (
            <Input
                component={Text}
                disabled={true}
            />
        )
    },
    {
        name: 'location',
        minWidth: 350,
        maxWidth: 350,
        width: 350,
        content: (
            <Input
                component={Text}
            />
        )
    },
    {
        name: 'evaluation',
        minWidth: 250,
        maxWidth: 250,
        width: 150,
        content: (
            <Input
                component={Select}
            />
        )
    }
];

const bmTargetLesionsColumns: Array<IFormGridCell<Dtos.BmTargetLesion>> = [
    {
        name: 'targetLesionNumber',
        minWidth: 220,
        maxWidth: 220,
        width: 200,
        content: (
            <Input
                component={Text}
                disabled={true}
            />
        )
    },
    {
        name: 'laterality',
        minWidth: 220,
        maxWidth: 220,
        width: 350,
        content: (
            <Input
                component={Select}
            />
        )
    },
    {
        name: 'location',
        minWidth: 350,
        maxWidth: 350,
        width: 350,
        content: (
            <Input
                component={Select}
            />
        )
    },
    {
        name: 'longestDiameter',
        minWidth: 250,
        maxWidth: 250,
        width: 150,
        content: (
            <Input
                component={Numeric}
                numberFormat={FOUR_DIGIT_POSITIVE_INTEGER}
            />
        )
    }
];

const bmNonTargetLesionsColumns: Array<IFormGridCell<Dtos.BmNonTargetLesion>> = [
    {
        name: 'nonTargetLesionNumber',
        minWidth: 220,
        maxWidth: 220,
        width: 200,
        content: (
            <Input
                component={Text}
                disabled={true}
            />
        )
    },
    {
        name: 'laterality',
        minWidth: 250,
        maxWidth: 250,
        width: 350,
        content: (
            <Input
                component={Select}
            />
        )
    },
    {
        name: 'location',
        minWidth: 350,
        maxWidth: 350,
        width: 350,
        content: (
            <Input
                component={Select}
            />
        )
    },
    {
        name: 'evaluation',
        minWidth: 250,
        maxWidth: 250,
        width: 150,
        content: (
            <Input
                component={Select}
            />
        )
    }
];

const additionalImagingColumns: Array<IFormGridCell<Dtos.AdditionalImaging>> = [
    {
        name: 'imagingType',
        minWidth: 220,
        maxWidth: 220,
        width: 220,
        content: (
            <Input
                component={Select}
            />
        )
    },
    {
        name: 'other',
        minWidth: 220,
        maxWidth: 220,
        width: 220,
        content: (
            <CrfCondition
                type={Dtos.DiseaseAssessment}
                condition={imagingTypeOther}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Text}
                />
            </CrfCondition>
        )
    },
    {
        name: 'imagingDate',
        minWidth: 220,
        maxWidth: 220,
        width: 200,
        content: (
            <Input
                component={KeyboardDatePicker}
            />
        )
    },
    {
        name: 'imagingResults',
        minWidth: 220,
        maxWidth: 220,
        width: 150,
        content: (

                <Input
                    component={Select}
                />
            
        )
    },
    {
        name: 'resultsClinicallySignificant',
        minWidth: 220,
        maxWidth: 220,
        width: 150,
        content: (
            <CrfCondition
                type={Dtos.DiseaseAssessment}
                condition={resultsClinicallySignificant}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Text}
                />
            </CrfCondition>
        )
    }
];

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const CtTargetLesionTbl: React.FunctionComponent = () => {
    const classes = useStyles();

    const { values: formValues } = useFormState<Dtos.DiseaseAssessment, any>({ values: true });

    const ctTargetLesionsSumOfDiameters = React.useMemo(() => {
        let sum = 0;

        formValues?.ctTargetLesions?.forEach(tl => {
            sum += tl?.longestDiameter ?? 0;
        });

        return sum;
    }, [formValues]);


    return (
        <>
            <CrfCondition
                type={Dtos.DiseaseAssessment}
                condition={anyTargetLesionsPresentYes}
                mode="Enable"
                subscription={{ values: true }}
            >
                <FormGrid
                    type={Dtos.CtTargetLesion}
                    name="ctTargetLesions"
                    columns={ctTargetLesionsColumns}
                    minRow={formValues.anyTargetLesionsPresent === Dtos.LookupYesNoType.Yes ? 1 : undefined}
                    onAdd={(values) => {
                        const gridSize = values?.length ?? 0;

                        let value = new Dtos.CtTargetLesion({ targetLesionNumber: 'T' + (gridSize + 1) });

                        if (values) {
                            return [...values, value];
                        }

                        return [value];

                    }}
                    onDelete={(values, index) => {
                        if (values == undefined) {
                            return;
                        }

                        if (index >= values?.length) {
                            return;
                        }

                        values = values?.filter((v, i) => index !== i);

                        values.forEach((value, index) => {
                            return value.targetLesionNumber = 'T' + (index + 1);
                        });

                        return values;
                    }}
                    allowAdd={!(formValues?.ctTargetLesions?.length >= 5)}
                />
                <Field
                    name="ctSumOfDiameters"
                    component={Numeric}
                    inputProps={{
                        value: ctTargetLesionsSumOfDiameters
                    }}
                    disabled
                />
            </CrfCondition>
        </>
    );
}

const CtNonTargetLesionTbl: React.FunctionComponent = () => {
    const classes = useStyles();

    const { values: formValues } = useFormState<Dtos.DiseaseAssessment, any>({ values: true });

    return (
        <>
            <CrfCondition
                type={Dtos.DiseaseAssessment}
                condition={anyNonTargetLesionsPresentYes}
                mode="Enable"
                subscription={{ values: true }}
            >
                <FormGrid
                    type={Dtos.CtNonTargetLesion}
                    name="ctNonTargetLesions"
                    columns={ctNonTargetLesionsColumns}
                    minRow={formValues.anyNonTargetLesionsPresent === Dtos.LookupYesNoType.Yes ? 1 : undefined}
                    onAdd={(values) => {
                        const gridSize = values?.length ?? 0;

                        let value = new Dtos.CtNonTargetLesion({ nonTargetLesionNumber: 'NT' + (gridSize + 1), evaluation: Dtos.LookupEvaluationType.Present });

                        if (values) {
                            return [...values, value];
                        }

                        return [value];

                    }}
                    onDelete={(values, index) => {
                        if (values == undefined) {
                            return;
                        }

                        if (index >= values?.length) {
                            return;
                        }

                        values = values?.filter((v, i) => index !== i);

                        values.forEach((value, index) => {
                            return value.nonTargetLesionNumber = 'NT' + (index + 1);
                        });

                        return values;
                    }}
                />
            </CrfCondition>
        </>
    );
}

const BmTargetLesionTbl: React.FunctionComponent = () => {
    const classes = useStyles();

    const { values: formValues } = useFormState<Dtos.DiseaseAssessment, any>({ values: true });

    const bmTargetLesionsSumOfDiameters = React.useMemo(() => {
        let sum = 0;

        formValues?.bmTargetLesions?.forEach(tl => {
            sum += tl?.longestDiameter ?? 0;
        });

        return sum;
    }, [formValues]);

    return (
        <>
            <CrfCondition
                type={Dtos.DiseaseAssessment}
                condition={lesionTypeTarget}
                mode="Enable"
                subscription={{ values: true }}
            >
                <FormGrid
                    type={Dtos.BmTargetLesion}
                    name="bmTargetLesions"
                    columns={bmTargetLesionsColumns}
                    minRow={formValues.lesionType === Dtos.LookupLesionType.Target || formValues.lesionType === Dtos.LookupLesionType.Both ? 1 : undefined}
                    onAdd={(values) => {
                        const gridSize = values?.length ?? 0;

                        let value = new Dtos.BmTargetLesion({ targetLesionNumber: 'BM_T' + (gridSize + 1) });

                        if (values) {
                            return [...values, value];
                        }

                        return [value];

                    }}
                    onDelete={(values, index) => {
                        if (values == undefined) {
                            return;
                        }

                        if (index >= values?.length) {
                            return;
                        }

                        values = values?.filter((v, i) => index !== i);

                        values.forEach((value, index) => {
                            return value.targetLesionNumber = 'BM_T' + (index + 1);
                        });

                        return values;
                    }}
                    allowAdd={!(formValues?.bmTargetLesions?.length >= 5)}
                />
                <Field
                    name="bmSumOfDiameters"
                    component={Numeric}
                    inputProps={{
                        value: bmTargetLesionsSumOfDiameters
                    }}
                    disabled
                />
            </CrfCondition>
        </>
    );
}

const BmNonTargetLesionTbl: React.FunctionComponent = () => {
    const classes = useStyles();

    const { values: formValues } = useFormState<Dtos.DiseaseAssessment, any>({ values: true });

    return (
        <>
            <CrfCondition
                type={Dtos.DiseaseAssessment}
                condition={lesionTypeNonTarget}
                mode="Enable"
                subscription={{ values: true }}
            >
                <FormGrid
                    type={Dtos.BmNonTargetLesion}
                    name="bmNonTargetLesions"
                    columns={bmNonTargetLesionsColumns}
                    minRow={formValues.lesionType === Dtos.LookupLesionType.NonTarget || formValues.lesionType === Dtos.LookupLesionType.Both ? 1 : undefined}
                    onAdd={(values) => {
                        const gridSize = values?.length ?? 0;

                        let value = new Dtos.BmNonTargetLesion({ nonTargetLesionNumber: 'BM_NT' + (gridSize + 1), evaluation: Dtos.LookupEvaluationType.Present });

                        if (values) {
                            return [...values, value];
                        }

                        return [value];

                    }}
                    onDelete={(values, index) => {
                        if (values == undefined) {
                            return;
                        }

                        if (index >= values?.length) {
                            return;
                        }

                        values = values?.filter((v, i) => index !== i);

                        values.forEach((value, index) => {
                            return value.nonTargetLesionNumber = 'BM_NT' + (index + 1);
                        });

                        return values;
                    }}
                />
            </CrfCondition>
        </>
    );
}

const AdditionalImagingTbl: React.FunctionComponent = () => {
    const classes = useStyles();

    const { values: formValues } = useFormState<Dtos.DiseaseAssessment, any>({ values: true });

    return (
        <>
            <CrfCondition
                type={Dtos.DiseaseAssessment}
                condition={additionalImagingPerformedYes}
                mode="Enable"
                subscription={{ values: true }}
            >
                <FormGrid
                    type={Dtos.AdditionalImaging}
                    name="additionalImaging"
                    columns={additionalImagingColumns}
                    minRow={formValues.additionalImagingPerformed === Dtos.LookupYesNoType.Yes ? 1 : undefined}
                />
            </CrfCondition>
        </>
    );
}


const DiseaseAssessment: React.FunctionComponent<IDiseaseAssessmentProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.DiseaseAssessment}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <Field
                    name="assessmentDate"
                    component={KeyboardDatePicker}
                />
                <Field
                    name="additionalAnatomyClinicallyRequired"
                    component={ToggleButtonGroup}
                />
                <FieldGroup>
                    <CrfCondition
                        type={Dtos.DiseaseAssessment}
                        condition={additionalAanatomicalAreasYes}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="head"
                            component={Checkbox}
                            label="If yes, please check all anatomical areas that apply
                                <div style='position:absolute;'><br/>
                                    <strong><i>Note:</i></strong><br/>
                                    - Additional anatomy should be imaged based on signs and symptoms of individual participants<br/>
                                    - Pelvis to be included if primary or metastatic disease in the pelvic region
                                </div>"
                        />
                        <Field
                            name="neck"
                            component={Checkbox}
                            label=""
                        />
                        <Field
                            name="legs"
                            component={Checkbox}
                            label=""
                        />
                        <Field
                            name="other"
                            component={Checkbox}
                            label=""
                        />
                    </CrfCondition>
                    <CrfCondition
                        type={Dtos.DiseaseAssessment}
                        condition={anatomicalAreasOtherChecked}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="anatomicalAreaOther"
                            component={Text}
                        />
                    </CrfCondition>
                </FieldGroup>
                <Field
                    name="wasContrastUsed"
                    component={ToggleButtonGroup}
                />
                <FieldGroup>
                    <Field
                        name="anyTargetLesionsPresent"
                        component={ToggleButtonGroup}
                        label="
                        Are any target lesions present, as per RECIST 1.1 criteria? 
                        <ul>
                            <li>Target lesions; Lesions that can be accurately measured in at least one dimension >= 10 mm and are representative of all involved organs.</li>
                            <li>A maximum of 5 lesions in total (and a maximum of 2 lesions per organ) may be recorded</li>
                        </ul>"
                    />
                    <CrfCondition
                        type={Dtos.DiseaseAssessment}
                        condition={anyTargetLesionsPresentYes}
                        mode="Show"
                        subscription={{ values: true }}
                    >
                        <CtTargetLesionTbl />
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="anyNonTargetLesionsPresent"
                        component={ToggleButtonGroup}
                        label="
                        Are any non-target lesions present, as per RECIST 1.1 criteria? 
                        <ul>
                            <li>Non-target Lesions; All non-measurable lesions (or sites of disease) plus any measurable lesions over and above those listed as target lesions</li>
                        </ul>"
                    />
                    <CrfCondition
                        type={Dtos.DiseaseAssessment}
                        condition={anyNonTargetLesionsPresentYes}
                        mode="Show"
                        subscription={{ values: true }}
                    >
                        <CtNonTargetLesionTbl />
                    </CrfCondition>
                </FieldGroup>
                <Field
                    name="dateofBrainImaging"
                    component={KeyboardDatePicker}
                />
                <Field
                    name="typeOfImaging"
                    component={Select}
                />
                <FieldGroup>
                    <Field
                        name="brainMetastasis"
                        component={ToggleButtonGroup}
                    />
                    <CrfCondition
                        type={Dtos.DiseaseAssessment}
                        condition={brainMetastasisYes}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="lesionType"
                            component={Select}
                        />
                    </CrfCondition>
                    <CrfCondition
                        type={Dtos.DiseaseAssessment}
                        condition={lesionTypeTarget}
                        mode="Show"
                        subscription={{ values: true }}
                    >
                        <FieldGroup>
                            <BmTargetLesionTbl />
                        </FieldGroup>
                    </CrfCondition>
                    <CrfCondition
                        type={Dtos.DiseaseAssessment}
                        condition={lesionTypeNonTarget}
                        mode="Show"
                        subscription={{ values: true }}
                    >
                        <BmNonTargetLesionTbl />
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="additionalImagingPerformed"
                        component={ToggleButtonGroup}
                    />
                    <CrfCondition
                        type={Dtos.DiseaseAssessment}
                        condition={additionalImagingPerformedYes}
                        mode="Show"
                        subscription={{ values: true }}
                    >
                        <AdditionalImagingTbl />
                    </CrfCondition>
                </FieldGroup>
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default DiseaseAssessment;
