/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password,
    KeyboardDatePicker,
    Checkbox,
    Numeric
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-trogapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles } from '@material-ui/core';

import { NumberFormatValues } from 'react-number-format';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import { THREE_DIGIT_POSITIVE_INTEGER } from '../../utils/numberFormat';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IGeneralProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 3, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */
const startedSystemicTherapyYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => formState?.values.startedSystemicTherapy == 1;

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const General: React.FunctionComponent<IGeneralProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    const { form } = React.useContext(FormContext)

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.General}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <Field
                    name="siteName"
                    component={Text}
                    label="Site name"
                    inputProps={{
                        value: institution?.name
                    }}
                    disabled
                />
                <Field
                    name="patientInitials"
                    component={Text}
                />
                <Field
                    name="dateOfBirth"
                    component={KeyboardDatePicker}
                />
                <Field
                    name="ageAtConsent"
                    component={Numeric}
                    numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
                />
                <Field
                    name="sex"
                    component={Select}
                />
                <Field
                    name="medicalOncologistName"
                    component={Text}
                />
                <Field
                    name="radiationOncologistName"
                    component={Text}
                />
                <FieldGroup>
                    <Field
                        name="dateOfConsent"
                        component={KeyboardDatePicker}
                    />
                    <Field
                        name="biomarkerCollectionConsent"
                        component={ToggleButtonGroup}
                    />
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="startedSystemicTherapy"
                        component={ToggleButtonGroup}
                    />
                    <CrfCondition
                        type={Dtos.General}
                        mode="Enable"
                        condition={startedSystemicTherapyYes}
                        subscription={{ values: true }}
                    >
                        <Field
                            name="dateOfSystemicTherapy"
                            component={KeyboardDatePicker}
                        />
                    </CrfCondition>
                </FieldGroup>
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default General;
