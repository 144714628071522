/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password,
    KeyboardDatePicker,
    Checkbox,
    Numeric
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-trogapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles } from '@material-ui/core';

import { NumberFormatValues } from 'react-number-format';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';



/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface ICancerDetailsProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 3, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */
const multipleLungNodulesEnable = ({ lookups, formState }: ICrfConditionParameters<Dtos.CancerDetails>) => formState?.values.sclcStage == Dtos.LookupSclcStageType.IIIA || formState?.values.sclcStage == Dtos.LookupSclcStageType.IIIB || formState?.values.sclcStage == Dtos.LookupSclcStageType.IIIC;
const brainMetastasesYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.CancerDetails>) => formState?.values.brainMetastases == Dtos.LookupYesNoType.Yes;
const patientNotStable = ({ lookups, formState }: ICrfConditionParameters<Dtos.CancerDetails>) => formState?.values.brainMetastasesRequiredRt == Dtos.LookupYesNoType.No;
const suitableToReceivePlatinumBasedChemoYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.CancerDetails>) => formState?.values.suitableToReceivePlatinumBasedChemo == Dtos.LookupYesNoType.Yes;
const thoracicDiseaseSuitableForRtYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.CancerDetails>) => formState?.values.thoracicDiseaseSuitableForRt == Dtos.LookupYesNoType.Yes;

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const CancerDetails: React.FunctionComponent<ICancerDetailsProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    const { form } = React.useContext(FormContext)

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.CancerDetails}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <Field
                    name="confirmedEsSclc"
                    component={ToggleButtonGroup}
                />
                <FieldGroup>
                    <Field
                        name="sclcStage"
                        component={Select}
                    />
                    <Field
                        name="sclcT"
                        component={Select}
                    />
                    <Field
                        name="sclcN"
                        component={Select}
                    />
                    <Field
                        name="sclcM"
                        component={Select}
                    />
                </FieldGroup>
                <CrfCondition
                    type={Dtos.CancerDetails}
                    mode="Enable"
                    condition={multipleLungNodulesEnable}
                    subscription={{ values: true }}
                >
                    <Field
                        name="multipleLungNodules"
                        component={ToggleButtonGroup}
                        />
                </CrfCondition>
                <Field
                    name="brainMetastases"
                    component={ToggleButtonGroup}
                />
                <FieldGroup>
                    <CrfCondition
                        type={Dtos.CancerDetails}
                        mode="Enable"
                        condition={brainMetastasesYes}
                        subscription={{ values: true }}
                    >
                        <Field
                            name="brainMetastasesRequiredRt"
                            component={ToggleButtonGroup}
                        />
                    </CrfCondition>
                    <CrfCondition
                        type={Dtos.CancerDetails}
                        mode="Enable"
                        condition={patientNotStable}
                        subscription={{ values: true }}
                    >
                        <Field
                            name="brainMetastasesAsymptomatic"
                            component={ToggleButtonGroup}
                        />
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="suitableToReceivePlatinumBasedChemo"
                        component={ToggleButtonGroup}
                    />
                    <CrfCondition
                        type={Dtos.CancerDetails}
                        mode="Enable"
                        condition={suitableToReceivePlatinumBasedChemoYes}
                        subscription={{ values: true }}
                    >
                        <Field
                            name="plannedPlatinumBasedChemo"
                            component={Select}
                        />
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="thoracicDiseaseSuitableForRt"
                        component={ToggleButtonGroup}
                    />
                    <CrfCondition
                        type={Dtos.CancerDetails}
                        mode="Enable"
                        condition={thoracicDiseaseSuitableForRtYes}
                        subscription={{ values: true }}
                    >
                        <Field
                            name="plannedRadiotherapySchedule"
                            component={Select}
                        />
                    </CrfCondition>
                </FieldGroup>
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default CancerDetails;
